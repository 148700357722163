import { connect } from 'react-redux'

import { Connector } from 'pluggy-js'

import { getCurrentCustomization } from '../../../../../../modules/config/selectors'
import { getSelectedConnector } from '../../../../../../modules/connector/selectors'
import { RootState } from '../../../../../../modules/reducer'
import AuthorizedDevicesLimitReached from './AuthorizedDevicesLimitReached'
import { MapStateProps } from './AuthorizedDevicesLimitReached.types'

const mapState = (state: RootState): MapStateProps => ({
  selectedConnector: getSelectedConnector(state) as Connector,
  withImage: getCurrentCustomization(state).displayInstitutionLogos || true,
})

export default connect(mapState)(AuthorizedDevicesLimitReached)
