import { useCallback, useEffect, useRef } from 'react'

import { captureMessage } from '@sentry/react'
import type { Item } from 'pluggy-js'

import { CONNECTION_SLOW_THRESHOLD_MS } from '../ConnectStatus'
import { CONNECTION_STEP } from '../utils'

const TOO_LONG_TOTAL_ELAPSED_TIME_REPORT_THRESHOLD = 2.5 * 60 * 1000 // 2:30 minutes
const TOO_LONG_STEP_ELAPSED_TIME_REPORT_THRESHOLD = 75 * 1000 // 75 seconds

/**
 * Helper to build a context data object to be included in Sentry report
 */
function buildConnectStatusStateContext(
  item: Item,
  currentStep: CONNECTION_STEP,
  stepElapsedTime: number,
  totalElapsedTime: number,
) {
  return {
    item,
    step: currentStep,
    stepName: CONNECTION_STEP[currentStep],
    stepElapsedTime,
    totalElapsedTime,
    STEP_SLOW_THRESHOLD_MS: CONNECTION_SLOW_THRESHOLD_MS,
  }
}

/**
 * Send Sentry report each time we detect that a step is taking too long, to potentially
 * find out if we are doing some incorrect item state handling, or if pluggy-api is
 * responding with incorrect Item data.
 *
 * Also, send another Sentry report if elapsed time was too much and user just closes
 * without finishing polling.
 *
 * @param currentStep
 * @param item
 * @param stepElapsedTime
 */
export function useStepTooLongSentryReport({
  currentStep,
  item,
  stepElapsedTime,
}: {
  item: Item
  currentStep: CONNECTION_STEP
  stepElapsedTime: number
}): void {
  const startTimeRef = useRef(Date.now())
  const totalElapsedTime = Date.now() - startTimeRef.current

  // timer to send a "step too long" Sentry report
  const stepTooLongReportTimeoutRef = useRef<number>()

  // exponent for exponential backoff, used to increase the next timer delay
  const stepTooLongReportExponentRef = useRef<number>(0)

  const clearStepTooLongReportTimeout = useCallback(
    (resetExponent: boolean) => {
      clearTimeout(stepTooLongReportTimeoutRef.current)
      stepTooLongReportTimeoutRef.current = undefined
      if (resetExponent) {
        stepTooLongReportExponentRef.current = 0
      }
    },
    [],
  )

  // track component will unmount
  const componentWillUnmountRef = useRef(false)
  useEffect(() => {
    return () => {
      componentWillUnmountRef.current = true
    }
  }, [])

  // cleanup timeout on unmount
  useEffect(() => {
    return () => {
      const isUnmounting = componentWillUnmountRef.current
      const isPollingFinished = item.status !== 'UPDATING'
      if (!isUnmounting || isPollingFinished) {
        // is not component unmount, or has completed item polling -> no report
        return
      }
      // component is unmounting, check and send Sentry reports
      const contexts = {
        'ConnectStatus state': buildConnectStatusStateContext(
          item,
          currentStep,
          stepElapsedTime,
          totalElapsedTime,
        ),
      }

      if (totalElapsedTime > TOO_LONG_TOTAL_ELAPSED_TIME_REPORT_THRESHOLD) {
        // user is aborting after too long total wait, send report to Sentry
        captureMessage(
          'User aborted Item polling after waiting >2:30 minutes',
          {
            contexts,
          },
        )
      } else if (
        stepElapsedTime > TOO_LONG_STEP_ELAPSED_TIME_REPORT_THRESHOLD
      ) {
        // user is aborting after current step is taking too long, send report to Sentry
        captureMessage(
          'User aborted Item polling after step taking >75 seconds',
          {
            contexts,
          },
        )
      }
      clearStepTooLongReportTimeout(true)
    }
  }, [
    item,
    totalElapsedTime,
    clearStepTooLongReportTimeout,
    stepElapsedTime,
    currentStep,
  ])

  // clear timeout after a new step started
  useEffect(() => {
    if (!stepTooLongReportTimeoutRef.current) {
      // no active timeout to clear
      return
    }
    // clear previously active timeout
    clearStepTooLongReportTimeout(true)
  }, [currentStep, clearStepTooLongReportTimeout])
}
