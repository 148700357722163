import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  FETCH_TERMS_OF_USE_FAILURE,
  FETCH_TERMS_OF_USE_REQUEST,
  FETCH_TERMS_OF_USE_SUCCESS,
  FetchTermsOfUseFailureAction,
  FetchTermsOfUseRequestAction,
  FetchTermsOfUseSuccessAction,
} from './actions'

export type TermsOfUseState = {
  data: {
    termsAndConditions: string
    privacyPolicy: string
  } | null
  loading: LoadingState
  error: string | null
}

const INITIAL_STATE: TermsOfUseState = {
  data: null,
  loading: [],
  error: null,
}

type TermsOfUseReducerAction =
  | FetchTermsOfUseRequestAction
  | FetchTermsOfUseSuccessAction
  | FetchTermsOfUseFailureAction

export function termsOfUseReducer(
  state: TermsOfUseState = INITIAL_STATE,
  action: TermsOfUseReducerAction,
): TermsOfUseState {
  switch (action.type) {
    case FETCH_TERMS_OF_USE_REQUEST:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
      }
    case FETCH_TERMS_OF_USE_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
      }
    case FETCH_TERMS_OF_USE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: loadingReducer(state.loading, action),
        error: null,
      }
    default:
      return state
  }
}
