import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { Alert, Button, Form, Input, ConnectorRow, Divider } from '@pluggyai/ui'
import Fuse from 'fuse.js'
import type { Connector } from 'pluggy-js'

import { TrackEventName } from '../../../../../modules/analytics/events'
import { track } from '../../../../../modules/analytics/utils'
import { useOnClickOutside } from '../../../../../utils/hooks/useOnClickOutside'
import { Props } from './MissingBankForm.types'
import { buildMissingBankOptions, isMobile, normalizeText } from './utils'

import './MissingBankForm.css'

const MissingBankForm = ({
  onSubmit,
  error,
  isLoading,
  connectors,
  onNext,
  displayInstitutionLogos,
  availableConnectors,
}: Props) => {
  const { t } = useTranslation()

  const [missingBank, setMissingBank] = useState<string>('')
  const [foundConnector, setFoundConnector] = useState<Connector>()
  const [isUserSearching, setIsUserSearching] = useState<boolean>(false)

  const missingBankOptions = useMemo(
    () => buildMissingBankOptions(connectors),
    [connectors],
  )

  // use fuse to search for institutions
  const fuse = new Fuse(missingBankOptions, {
    isCaseSensitive: false,
    threshold: 0.3,
    keys: ['name'],
  })
  const searchResults = fuse.search(missingBank)

  useEffect(() => {
    const maybeExistingConnector = availableConnectors.find(
      (connector_: Connector) =>
        missingBank &&
        normalizeText(connector_.name) === normalizeText(missingBank),
    )

    setFoundConnector(maybeExistingConnector)
  }, [missingBank, availableConnectors])

  const handleConnectorSelect = useCallback(() => {
    if (!foundConnector) {
      return
    }
    const {
      name: connectorName,
      id: connectorId,
      type: connectorType,
    } = foundConnector

    track(TrackEventName.LIST_ITEM_CLICKED, {
      location: 'missingBankPage',
      connectorName,
      connectorId,
      connectorType,
    })
    onNext(foundConnector)
  }, [foundConnector, onNext])

  const handleSearchChange = useCallback(({ value }: { value: string }) => {
    setMissingBank(value)
  }, [])

  const handleSubmit = useCallback(() => {
    if (!missingBank) {
      return
    }
    if (isLoading) {
      // already submitted, waiting for response
      return
    }
    if (isMobile() && isUserSearching) {
      // we are on mobile and the person clicks enter while searching,
      // just close the list and not submit
      setIsUserSearching(false)
      return
    }

    setIsUserSearching(false)

    track(TrackEventName.FORM_SUBMITTED, {
      location: 'missingBankPage',
    })
    onSubmit(missingBank)
  }, [missingBank, isLoading, isUserSearching, onSubmit])

  const handleOptionSelect = useCallback((value: string) => {
    // set missingBank to selected value and close options
    setMissingBank(value)
    setIsUserSearching(false)
  }, [])

  const searchResultsVisible =
    isUserSearching &&
    missingBank !== '' &&
    searchResults.length > 0 &&
    !foundConnector

  const handleClickOutside = useCallback(() => {
    setIsUserSearching(false)
  }, [])

  const inputContainerRef = useRef<HTMLDivElement | null>(null)

  useOnClickOutside(inputContainerRef, handleClickOutside)

  const handleSearchItemKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>, search: string) => {
      if (event.key !== 'Enter') {
        return
      }
      setMissingBank(search)
      setIsUserSearching(false)
    },
    [],
  )

  const handleInputFocus = useCallback(() => {
    setIsUserSearching(true)
  }, [])

  const hasOpenFinanceConnectors = connectors.some(
    (connector) => connector.isOpenFinance,
  )

  return (
    <div className={'MissingBankForm'}>
      <div className={'message'}>
        {error && <Alert type={'error'} message={error} />}
        <p className={'details'}>{t('missingBank.form.message')}</p>
      </div>

      <Form onSubmit={handleSubmit}>
        <div className={'search-container'} ref={inputContainerRef}>
          <Input
            type={'text'}
            className={'missing-bank-input'}
            label={t('missingBank.form.bank-label')}
            value={missingBank}
            onChange={handleSearchChange}
            disabled={isLoading}
            onFocus={handleInputFocus}
          />
          {searchResultsVisible && (
            <div className={'search-results'}>
              {searchResults.map(({ refIndex, item }) => (
                <div
                  className={'search-item'}
                  key={refIndex}
                  onClick={() => handleOptionSelect(item)}
                  onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) =>
                    handleSearchItemKeyDown(event, item)
                  }
                  role={'option'}
                  aria-selected={missingBank === item}
                  tabIndex={0}
                >
                  {item}
                </div>
              ))}
            </div>
          )}
        </div>

        {hasOpenFinanceConnectors && (
          <div className={'open-finance-institutions'}>
            <Trans
              i18nKey={'missingBank.form.open-finance'}
              components={{
                a: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    href="https://openfinancebrasil.org.br/quem-participa/"
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
              }}
            />
          </div>
        )}

        {foundConnector && (
          <div className={'found-connector'}>
            <p className={'header'}>{t('missingBank.form.available')}</p>
            <Divider />
            <ConnectorRow
              connector={foundConnector}
              onClick={handleConnectorSelect}
              withImage={displayInstitutionLogos}
            />
          </div>
        )}

        <Button
          primary
          type={'submit'}
          disabled={!missingBank}
          loading={isLoading}
        >
          {t('missingBank.form.action')}
        </Button>
      </Form>
    </div>
  )
}

export default React.memo(MissingBankForm)
