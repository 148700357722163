import { useEffect } from 'react'

import { Connector, Item } from 'pluggy-js'

import { hasBrowserLogin } from '../../../../../modules/connector/utils'
import {
  isRunningAsCordovaWebView,
  isRunningAsFlutterWebView,
} from '../../../../../utils/appWrapper'
import { sendOpenLinkMessageToCordovaParent } from '../../../../../utils/cordova'
import { sendMessageToFlutterWebview } from '../../../../../utils/flutter'
import { isMobile } from '../../MissingBankPage/MissingBankForm/utils'

let windowObjectReference: Window | null = null
let previousUrl: string | undefined

const CONNECTOR_WINDOW_NAME_REFERENCE = 'connector-oauth-login'
const ITEM_WINDOW_NAME_REFERENCE = 'item-oauth-login'

type BaseMessage = { source: string }
type ErrorMessage = BaseMessage & { error: string }
type ItemCreatedMessage = BaseMessage & { itemId: string }

type OauthWindowMessageEvent = { data: ErrorMessage | ItemCreatedMessage }

function isErrorMessage(message: unknown): message is ErrorMessage {
  return typeof (message as { error?: string | undefined }).error === 'string'
}

function receiveMessage(
  event: OauthWindowMessageEvent,
  onSuccess: (payload: string) => void,
  onError: (error: string) => void,
): void {
  const { data } = event
  const { source } = data

  // Check first that trust the sender of this message? (might be
  // different from what we originally opened, for example).
  if (
    source !== CONNECTOR_WINDOW_NAME_REFERENCE &&
    source !== ITEM_WINDOW_NAME_REFERENCE
  ) {
    // TODO we should create a whitelist of domains/origin URLs
    // source is invalid, ignore message. Note: we don't validate the origin URL because it can be cross-origin, for example 'ngrok' URL -> 'localhost'
    return
  }
  // we trust the sender and the source is our popup -> extract payload (or error) from message
  if (isErrorMessage(data)) {
    onError(data.error)
    return
  }
  onSuccess(data.itemId)
}

async function openSignInBrowserWindow(
  url: string,
): Promise<{ itemId: string }> {
  // promise to start listening for the 'success' or 'error' result of the oauth last step
  const oauthResultListenPromise = new Promise<{ itemId: string }>(
    (resolve, reject) => {
      let hasStarted = false
      // setup event listener to self-remove after handling a success (or error) message result
      const eventListener = (event: OauthWindowMessageEvent) => {
        const onSuccess = (payload: string): void => {
          clearResultListeners()
          resolve({ itemId: payload })
        }
        const onError = (error: string): void => {
          clearResultListeners()
          reject(new Error(error))
        }
        receiveMessage(event, onSuccess, onError)
      }

      // add the listener for receiving a message from the popup
      window.addEventListener('message', eventListener, false)

      /*
      TODO: note that there is a data duplication risk by polling the window closed state,
        if the user completes the login but it's closed before the response, then the itemId
        success will never arrive, and so the polling won't start. The user will perceive it as
        a login error, but in reality the connection will be created. This is better than not detecting
        any window closed and showing an infinite spinner, but still not good. Unfortunately we'll have this
        limitation until we develop a way to get notified of the oauth-callback created items result, for example
        by creating first and polling the item, and then proceed with the oauth flow until finished or failed.
      */
      let windowClosedPoller: undefined | NodeJS.Timeout = undefined
      windowClosedPoller = setInterval(() => {
        if (!hasStarted && windowObjectReference) {
          hasStarted = true
        }
        if (
          hasStarted &&
          (!windowObjectReference || windowObjectReference.closed)
        ) {
          // detected window closed, before receiving message.
          clearInterval(windowClosedPoller)
        }
      }, 200)

      const clearResultListeners: () => void | undefined = () => {
        if (windowClosedPoller !== undefined) {
          clearInterval(windowClosedPoller)
        }

        window.removeEventListener('message', eventListener)
      }
    },
  )

  // window features to be opened, to behave like a popup
  const strWindowFeatures =
    'toolbar=no, menubar=no, width=600, height=700, top=100, left=100'

  if (windowObjectReference === null || windowObjectReference.closed) {
    /* if the pointer to the window object in memory does not exist
     or if such pointer exists but the window was closed */
    windowObjectReference = window.open(
      url,
      CONNECTOR_WINDOW_NAME_REFERENCE,
      strWindowFeatures,
    )
  } else if (previousUrl !== url) {
    /* if the resource to load is different,
     then we load it in the already opened secondary window and then
     we bring such window back on top/in front of its parent window. */
    windowObjectReference = window.open(
      url,
      CONNECTOR_WINDOW_NAME_REFERENCE,
      strWindowFeatures,
    )
    windowObjectReference?.focus()
  } else {
    /* else the window reference must exist and the window
     is not closed; therefore, we can bring it back on top of any other
     window with the focus() method. There would be no need to re-create
     the window or to reload the referenced resource. */
    windowObjectReference.focus()
  }
  // assign the previous URL
  previousUrl = url

  if (!windowObjectReference) {
    return Promise.reject(new Error('Failed to open window popup'))
  }

  return oauthResultListenPromise
}

/**
 * If running in a Webview, send messages to caller to open a new Webview for oauth flow.
 *
 * @param url - oauth URL to navigate to
 */
async function openSignInWebview(url: string): Promise<{ itemId: string }> {
  const runningAsCordovaWebView = isRunningAsCordovaWebView()
  const reactNativeWebView = window.ReactNativeWebView
  const runningAsFlutterWebView = isRunningAsFlutterWebView()

  if (
    !reactNativeWebView &&
    !runningAsCordovaWebView &&
    !runningAsFlutterWebView
  ) {
    throw new Error(
      "Can't open in webview, not inside react-native Webview, nor Cordova WebView nor Flutter WebView",
    )
  }

  const oauthResultPromise = new Promise<{ itemId: string }>(
    (resolve, reject) => {
      const windowOauthMessageListener = (event: OauthWindowMessageEvent) => {
        // setup event listener to self-remove after handling a success (or error) message result
        const onSuccess = (payload: string): void => {
          cleanup()
          resolve({ itemId: payload })
        }
        const onError = (error: string): void => {
          cleanup()
          reject(new Error(error))
        }
        receiveMessage(event, onSuccess, onError)
      }

      const documentOauthMessageListener = (event: Event) => {
        windowOauthMessageListener(event as MessageEvent)
      }

      // add listener for receiving a message from the webview parent
      // in iOS: only window.addEventListener works,
      // in Android: only document.addEventListener works (source: https://stackoverflow.com/a/61414631/6279385).
      // TODO improve this to detect OS and only run one or another
      document.addEventListener('message', documentOauthMessageListener, false)
      window.addEventListener('message', windowOauthMessageListener, false)

      const cleanup = (): void => {
        window.removeEventListener('message', windowOauthMessageListener)
        document.removeEventListener('message', documentOauthMessageListener)
      }
    },
  )

  // send message to parent, to open the oauth URL in a new webview
  const oauthOpenMessage = {
    type: 'OAUTH_OPEN',
    message: url,
  }

  if (runningAsCordovaWebView) {
    // send message to parent window
    window.parent.postMessage(JSON.stringify(oauthOpenMessage), '*')
  } else if (runningAsFlutterWebView) {
    sendMessageToFlutterWebview(oauthOpenMessage)
  } else {
    // send message to window.ReactNativeWebView
    reactNativeWebView?.postMessage(JSON.stringify(oauthOpenMessage))
  }

  return await oauthResultPromise
}

export async function openSignInWindow(
  url: string,
  connector?: Connector,
): Promise<{ itemId: string } | undefined> {
  const runningAsCordova = isRunningAsCordovaWebView()
  const runningAsFlutterWebView = isRunningAsFlutterWebView()
  if (
    window.ReactNativeWebView ||
    runningAsCordova ||
    runningAsFlutterWebView
  ) {
    // is in react-native, Cordova WebView, or Flutter WebView

    if (
      (runningAsCordova || runningAsFlutterWebView) &&
      connector &&
      !hasBrowserLogin(connector)
    ) {
      // some connectors oauth flow don't work inside a Webview, so open them in the external system browser
      // also, we can't keep track of the opened window, the user will need to close it manually.
      // in the case of Nubank, it has an 'intent://' link to continue with the login flow
      if (runningAsFlutterWebView) {
        // send message to Flutter webview
        sendMessageToFlutterWebview({
          type: 'LINK_OPEN',
          message: url,
        })
        return undefined
      }
      sendOpenLinkMessageToCordovaParent(url)
      return undefined
    }

    return await openSignInWebview(url)
  }
  // is in web browser, or another
  return await openSignInBrowserWindow(url)
}

/**
 * Check if the item has oauth data
 * @param item - Item object
 * @returns true if the item has oauth data, false otherwise
 */
function itemHasParameterOauthData(item: Item): boolean {
  return Boolean(
    item.parameter &&
      // TODO: add type oauth to item.parameter
      (item.parameter.type as string) === 'oauth' &&
      item.parameter.data,
  )
}

type UseAutoSubmitOpenFinanceProps = {
  selectedConnector: Connector
  item?: Item
  isPollingItemAnyAction: boolean
  isAnySubmitLoading: boolean
  handleConnectFormSubmit: () => void
}

export const useAutoSubmitOpenFinance = ({
  selectedConnector,
  item,
  isPollingItemAnyAction,
  isAnySubmitLoading,
  handleConnectFormSubmit,
}: UseAutoSubmitOpenFinanceProps) => {
  useEffect(() => {
    if (
      !selectedConnector.isOpenFinance ||
      !item ||
      isPollingItemAnyAction ||
      isAnySubmitLoading ||
      // We cannot auto open the oauth URL in mobile apps (mostly on iOS - web)
      isMobile()
    ) {
      return
    }

    if (item.status !== 'WAITING_USER_INPUT') {
      return
    }

    if (!itemHasParameterOauthData(item)) {
      return
    }

    // call handleConnectFormSubmit to open the oauth URL for OF connectors
    handleConnectFormSubmit()
  }, [
    handleConnectFormSubmit,
    isAnySubmitLoading,
    isPollingItemAnyAction,
    item,
    selectedConnector.isOpenFinance,
  ])
}
