import React from 'react'

type Props = {
  src: string
  companyName: string
}

const BackgroundIcons = ({ companyName, src }: Props) => (
  <img
    className={'header-background-icons'}
    alt={`${companyName} logo`}
    src={src}
  />
)

export default React.memo(BackgroundIcons)
