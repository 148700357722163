import React from 'react'
import { useTranslation } from 'react-i18next'

import { AxiosError, isAxiosError } from 'axios'

import { EXECUTION_ERROR } from '../../../../../modules/item/types'
import {
  isAilosCreditCardMaxDeviceLimitReachedError,
  isCaixaAuthorizedDevicesLimitReachedError,
} from '../../../../../modules/item/utils'
import {
  connectionErrorsMap,
  isExecutionError,
  mapExecutionStatusToStep,
} from '../utils'
import { AilosDeviceConnectionLimitReachedError } from './AilosDeviceConnectionLimitReachedError'
import { AuthorizedDevicesLimitReached } from './AuthorizedDevicesLimitReached'
import { ConnectTokenExpiredError } from './ConnectTokenExpiredError'
import { DeviceAuthorizationNeededStatus } from './DeviceAuthorizationNeededStatus'
import { Props } from './ErrorStatus.types'
import { GeneralErrorStatus } from './GeneralErrorStatus'
import { OpenFinanceAuthNotGaranted } from './OpenFinanceAuthNotGaranted'

import './ErrorStatus.css'

const ErrorStatus = ({
  item,
  itemError,
  connector,
  onGoToConnectorsList,
  onRetry,
  onClose,
  displayInstitutionLogos,
  hasPreselectedConnector,
}: Props) => {
  const { t } = useTranslation()

  let currentItemExecutionError: EXECUTION_ERROR | undefined
  if (item) {
    const currentStep = mapExecutionStatusToStep(item)
    if (isExecutionError(currentStep)) {
      currentItemExecutionError = currentStep
    }
  }

  const errorMessage = t(
    connectionErrorsMap[currentItemExecutionError || 'ERROR'].i18nKey,
    {
      connectorName: connector.name,
    },
  )

  const isDeviceAuthorizationError =
    (item?.executionStatus === 'USER_AUTHORIZATION_PENDING' ||
      item?.executionStatus === 'USER_AUTHORIZATION_NOT_GRANTED') &&
    !connector.isOpenFinance

  const isOpenFinanceUnauthorizedError =
    connector.isOpenFinance &&
    item?.executionStatus === 'USER_AUTHORIZATION_NOT_GRANTED'

  const itemAxiosError: AxiosError | undefined =
    itemError && isAxiosError(itemError) ? itemError : undefined

  const isConnectTokenUnauthorizedError =
    itemAxiosError?.response?.status !== undefined &&
    itemAxiosError.response.status === 403

  return (
    <div className={'ErrorStatus'}>
      {item && isAilosCreditCardMaxDeviceLimitReachedError(item) ? (
        <AilosDeviceConnectionLimitReachedError
          connector={item.connector}
          onClose={onClose}
          displayInstitutionLogo={displayInstitutionLogos}
          onRetry={onRetry}
        />
      ) : isOpenFinanceUnauthorizedError ? (
        <OpenFinanceAuthNotGaranted onCancel={onClose} onRetry={onRetry} />
      ) : isDeviceAuthorizationError ? (
        <DeviceAuthorizationNeededStatus
          onClose={onClose}
          item={item}
          connector={connector}
          onRetry={onRetry}
        />
      ) : isConnectTokenUnauthorizedError ? (
        <ConnectTokenExpiredError onClose={onClose} />
      ) : isCaixaAuthorizedDevicesLimitReachedError(item) ? (
        <AuthorizedDevicesLimitReached onRetry={onRetry} onClose={onClose} />
      ) : (
        <GeneralErrorStatus
          currentItemExecutionError={currentItemExecutionError}
          errorMessage={errorMessage}
          item={item}
          itemError={itemError}
          onGoToConnectorsList={onGoToConnectorsList}
          onRetry={onRetry}
          onClose={onClose}
          hasPreselectedConnector={hasPreselectedConnector}
        />
      )}
    </div>
  )
}

export default React.memo(ErrorStatus)
