import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from '@pluggyai/ui'

import { GoBackIcon } from '../../../ConnectHeader/GoBackIcon'
import { Title } from '../../../Title'
import FAQOption from '../../OpenFinanceFAQ/FAQOption/FAQOption'
import { CalendarIcon } from './CalendarIcon'
import { Props } from './OpenFinancePermissions.types'

import './OpenFinancePermissions.css'

const OpenFinancePermissions = ({ onClose }: Props) => {
  const { t } = useTranslation()
  const today = new Date()
  const oneYearFromNow = new Date(
    today.getFullYear() + 1,
    today.getMonth(),
    today.getDate(),
  )
  return (
    <div className={'OpenFinancePermissions'}>
      <div className="container">
        <div>
          <GoBackIcon onClick={onClose} />
          <Title>{t('open-finance-permissions.title')}</Title>
          <FAQOption
            question={t('open-finance-permissions.options.accounts.title')}
            answer={t('open-finance-permissions.options.accounts.description')}
          />
          <FAQOption
            question={t('open-finance-permissions.options.credit-cards.title')}
            answer={t(
              'open-finance-permissions.options.credit-cards.description',
            )}
          />
          <FAQOption
            question={t('open-finance-permissions.options.loans.title')}
            answer={t('open-finance-permissions.options.loans.description')}
          />
          <FAQOption
            question={t('open-finance-permissions.options.identity.title')}
            answer={t('open-finance-permissions.options.identity.description')}
          />
        </div>

        <div className={'open-finance-permissions-footer'}>
          <div className="open-finance-valid-date">
            <CalendarIcon className="calendar" />
            <div>
              <Trans
                i18nKey={'open-finance-permissions.date'}
                values={{
                  date: oneYearFromNow.toLocaleDateString('pt-BR', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  }),
                }}
              />
            </div>
          </div>
          <Button primary type="button" onClick={onClose}>
            {t('open-finance-permissions.action')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default React.memo(OpenFinancePermissions)
