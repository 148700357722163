import { connect } from 'react-redux'

import { getCurrentCustomization } from '../../../../../../modules/config/selectors'
import { RootState } from '../../../../../../modules/reducer'
import DeviceAuthorizationNeededStatus from './DeviceAuthorizationNeededStatus'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './DeviceAuthorizationNeededStatus.types'

const mapState = (state: RootState): MapStateProps => ({
  displayInstitutionLogo:
    getCurrentCustomization(state).displayInstitutionLogos || true,
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({
  // nothing here, yet
})

export default connect(mapState, mapDispatch)(DeviceAuthorizationNeededStatus)
