import { memo } from 'react'

import { Props } from './UserActionInstructions.types'
import { WaitUserAction } from './WaitUserAction'

import './UserActionInstructions.css'

const UserActionInstructions = ({ item }: Props) => {
  const isWaitingUserActionExecutionStatus =
    item.executionStatus === 'WAITING_USER_ACTION'

  const isWaitingUserAction =
    item.userAction?.type === 'authorize-access' &&
    isWaitingUserActionExecutionStatus

  return (
    <div className={'UserActionInstructions'}>
      {isWaitingUserAction && <WaitUserAction item={item} />}
    </div>
  )
}

export default memo(UserActionInstructions)
