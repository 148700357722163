export enum TrackEventName {
  AUTH_ERROR = 'Initial Auth Error',
  BUTTON_CLICKED = 'Button Clicked',
  LIST_ITEM_CLICKED = 'List Item Clicked',
  LINK_CLICKED = 'Link Clicked',
  ICON_CLICKED = 'Icon Clicked',
  POPUP_TRIGGERED = 'Popup Triggered',
  FORM_SUBMITTED = 'Form Submitted',
  MISSING_BANK_SUBMITTED = 'Missing Bank Submitted',
  CONNECTORS_SEARCHED = 'Connectors Searched',
  // Errors from API
  FORM_ERROR_SETTED = 'Form Error Setted',
  // Errors in form
  FORM_ERROR_RECEIVED = 'Form Error Received',
  ITEM_CREATED = 'Item Created',
  ITEM_POLLING_FINISHED = 'Item Polling Finished',
  CONNECT_WIDGET_LOADED = 'Connect Widget Loaded',
  CONNECT_WIDGET_CLOSED = 'Connect Widget Closed',
  LOGIN_STEP_SUCCESS = 'Login Step Success',

  MINIGAME_EVENT = 'Minigame Event',
}
