import React from 'react'

import { Alert, Button } from '@pluggyai/ui'

import { Title } from '../../../../Title'

import './OpenFinanceAuthNotGaranted.css'

const OpenFinanceAuthNotGaranted = ({
  onRetry,
  onCancel,
}: {
  onRetry: () => void
  onCancel: () => void
}) => (
  <div className={'OpenFinanceAuthNotGaranted'}>
    <div className={'header'}>
      <Title>Autorização recusada</Title>
      <Alert
        type={'error'}
        message={'Conexão não autorizada, você ainda pode tentar novamente.'}
      />
    </div>
    <div className={'actions'}>
      <Button onClick={onRetry} primary>
        Tentar novamente
      </Button>
      <Button onClick={onCancel}>Cancelar</Button>
    </div>
  </div>
)

export default OpenFinanceAuthNotGaranted
