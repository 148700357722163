import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  CHECK_AUTH_FAILURE,
  CHECK_AUTH_REQUEST,
  CHECK_AUTH_SUCCESS,
  CheckAuthFailureAction,
  CheckAuthRequestAction,
  CheckAuthSuccessAction,
} from './actions'

export type AuthState = {
  data: {
    isAuthorized: boolean
  }
  loading: LoadingState
  error: string | null
}

const INITIAL_STATE: AuthState = {
  data: {
    isAuthorized: false,
  },
  loading: [],
  error: null,
}

type AuthReducerAction =
  | CheckAuthRequestAction
  | CheckAuthSuccessAction
  | CheckAuthFailureAction

export function authReducer(
  state: AuthState = INITIAL_STATE,
  action: AuthReducerAction,
): AuthState {
  switch (action.type) {
    case CHECK_AUTH_REQUEST:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
      }
    case CHECK_AUTH_SUCCESS:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          isAuthorized: true,
        },
      }
    case CHECK_AUTH_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
        data: {
          isAuthorized: false,
        },
      }
    default:
      return state
  }
}
