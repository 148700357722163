import * as Sentry from '@sentry/react'
import { EventHint } from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { isAxiosError } from 'axios'
import { AnyAction } from 'redux'

import { parseConnectToken } from '../modules/auth/utils'
import {
  CREATE_ITEM_REQUEST,
  CreateItemRequestAction,
  UPDATE_ITEM_REQUEST,
  UpdateItemRequestAction,
} from '../modules/item/actions'
import { CallbackEventType, getAppProps } from '../utils/appWrapper'
import { getBaseCustomization } from '../utils/customizations'

const {
  REACT_APP_SENTRY_DSN_KEY: sentryDsnKey = '',
  REACT_APP_VERCEL_ENV: environment = '', // preview | production | development
  REACT_APP_VERCEL_URL: vercelUrl = '', // current URL of the deployment
  REACT_APP_VERCEL_GIT_COMMIT_REF: gitBranchName = '', // current git branch name
  REACT_APP_VERCEL_GIT_COMMIT_SHA: vercelGitCommitHash = '', // current git commit sha
  REACT_APP_PLUGGY_CORE_API_URL: pluggyCoreApiUrl = '',
  REACT_APP_PLUGGY_AUTH_API_URL: pluggyAuthApiUrl = '',
} = process.env

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (
    action: CreateItemRequestAction | UpdateItemRequestAction | AnyAction,
  ) => {
    if (
      action.type === CREATE_ITEM_REQUEST ||
      action.type === UPDATE_ITEM_REQUEST
    ) {
      // Return a transformed action to remove sensitive information
      const {
        payload: { parameters },
      } = action
      if (parameters) {
        const parametersFiltered: Record<string, string> = {}
        for (const key of Object.keys(parameters)) {
          parametersFiltered[key] = '***'
        }
        return {
          ...action,
          payload: {
            parameters: parametersFiltered,
          },
        }
      }
    }

    return action
  },
})

/**
 * Add app props, clientId, clientUserId to Sentry context & tags.
 */
function addAppContextToSentry(): void {
  // Add non-sensitive env vars
  Sentry.setContext('Env', {
    vercelUrl,
    gitBranchName,
    vercelGitCommitHash,
    pluggyCoreApiUrl,
    pluggyAuthApiUrl,
  })

  // Add Connect app props to Sentry context
  const appProps = getAppProps()
  const appPropsFiltered = { ...appProps }
  appPropsFiltered.connectToken = '****'
  Sentry.setContext('App Props', appPropsFiltered)

  // Add baseCustomization, excluding too large/unneeded fields
  // Note: take special care of deep cloning nested fields, so we don't alter the original ones
  const baseCustomizationFiltered = { ...getBaseCustomization() }
  delete baseCustomizationFiltered.i18n
  const brand = baseCustomizationFiltered.brand
    ? { ...baseCustomizationFiltered.brand }
    : undefined
  if (brand) {
    const { logo, logoBackground, logoBackgroundCollapsed } = brand
    brand.logo = logo.slice(0, 255)
    brand.logoBackground = logoBackground?.slice(0, 255)
    brand.logoBackgroundCollapsed = logoBackgroundCollapsed?.slice(0, 255)
    baseCustomizationFiltered.brand = brand
  }

  Sentry.setContext('Base Customization', baseCustomizationFiltered)

  // Decode connectToken and add to Sentry user identification fields
  let clientId: string
  let clientUserId: string
  let issuedAt: Date | undefined = undefined
  let expiresIn: Date | undefined = undefined
  let options: Record<string, unknown> | undefined

  try {
    // get the claims object from the connectToken payload
    // to add them to Sentry context
    const connectTokenPayload = parseConnectToken()
    ;({ clientId = '', clientUserId = '', options } = connectTokenPayload)
    const { iat, exp } = connectTokenPayload
    issuedAt = new Date(iat * 1000)
    expiresIn = new Date(exp * 1000)
  } catch {
    // malformed or empty connectToken
    return
  }

  Sentry.setContext('Connect Token', {
    issuedAt,
    expiresIn,
    clientId,
    options,
  })
  Sentry.setTag('clientId', clientId)
  const sentryUser: Sentry.User = {
    clientId,
    id: clientUserId,
  }
  if (clientUserId) {
    sentryUser.id = clientUserId
    Sentry.setTag('clientUserId', clientUserId)
  }
  Sentry.setUser(sentryUser)
}

/**
 * Initialize Sentry instance with initial configuration.
 */
export function initializeSentry(): void {
  if (!sentryDsnKey) {
    console.warn('Sentry has not been enabled')
    return
  }

  Sentry.init({
    dsn: sentryDsnKey,
    integrations: (integrations) => {
      // integrations will be all default integrations
      const defaultIntegrationsFiltered = integrations.filter(function (
        integration,
      ) {
        const { name } = integration
        // disabling 'Dedupe' default integration due to issue unexpectedly dropping valid events
        // https://github.com/getsentry/sentry-javascript/issues/3939#issuecomment-909204914
        const exclusions: string[] = ['Dedupe']
        return !exclusions.includes(name)
      })

      const extraIntegrations = [new BrowserTracing()]

      return [...defaultIntegrationsFiltered, ...extraIntegrations]
    },

    environment: environment || 'localhost',
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01,
    normalizeDepth: 10, // Or however deep you want your state context to be.

    release:
      gitBranchName && vercelGitCommitHash
        ? `${gitBranchName}:${vercelGitCommitHash.slice(0, 10)}`
        : undefined,

    beforeSend: (event: Sentry.Event, hint?: EventHint) => {
      const { breadcrumbs } = event

      if (!breadcrumbs) {
        // no breadcrumbs, proceed
        return event
      }

      // breadcrumbs present, filter out unneeded ones
      function isReduxLog(breadcrumb: Sentry.Breadcrumb): boolean {
        const messageStarts = ['%c next state', '%c action', '%c prev state']
        return (
          breadcrumb.category === 'console' &&
          breadcrumb.level === 'log' &&
          messageStarts.some((messageStart) =>
            breadcrumb.message?.startsWith(messageStart),
          )
        )
      }

      // exclude redux logger breadcrumbs, to prevent Sentry 413 response
      const breadcrumbsFiltered = breadcrumbs.filter(
        (breadcrumb) => !isReduxLog(breadcrumb),
      )

      // remove duplicates by timestamp+category+level, to prevent 413 response
      const breadcrumbsFilteredUnique = Array.from(
        new Map(
          breadcrumbsFiltered.map((b) => [
            `${b.timestamp}_${b.category}_${b.level}`,
            b,
          ]),
        ).values(),
      ).sort((b1, b2) =>
        // restore ascending order
        b1.timestamp && b2.timestamp ? b1.timestamp - b2.timestamp : 0,
      )

      event.breadcrumbs = breadcrumbsFilteredUnique

      const exception = hint?.originalException

      // if error is an Axios error, add further granularity based on error.code and response.status
      // also add error & error.response as context data to the report
      if (isAxiosError(exception)) {
        const { response, code, config } = exception

        if (config?.data && typeof config.data.parameters === 'string') {
          // contains Item parameters (in JSON string), scrub them
          config.data = config.data.replace(/("parameters":){.+?}/, '$1"****"')
        }

        const axiosErrorEventFingerprint: string[] = [
          '{{ default }}', // keeps the default Sentry fingerprinting logic
          ...(event.fingerprint || []),
        ]
        if (code !== undefined) {
          axiosErrorEventFingerprint.push(code)
        }
        if (response) {
          axiosErrorEventFingerprint.push(String(response.status))
        }

        // add more granularity based on error code and response status
        // eslint-disable-next-line no-param-reassign
        event.fingerprint = axiosErrorEventFingerprint

        // add axios error object and error.response to context
        // eslint-disable-next-line no-param-reassign
        event.contexts = {
          ...event.contexts,
          axiosErrorContext: {
            error: exception,
            errorResponse: response,
          },
        }
      }

      return event
    },
  })

  addAppContextToSentry()
}

export type CallbackBreadcrumbData = {
  callbackEvent: CallbackEventType
  urlParam?: string
  data?: Record<string, unknown>
}

/**
 * Add a Connect callback call to Sentry reports as a breadcrumb.
 *
 * @param message
 * @param data
 */
export function addSentryCallbackBreadcrumb(
  message: string,
  data: CallbackBreadcrumbData,
): void {
  Sentry.addBreadcrumb({
    category: 'callback',
    message,
    data,
    timestamp: Date.now() / 1000,
  })
}
