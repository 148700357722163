import React, { memo, useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Alert, Button } from '@pluggyai/ui'

import { TrackEventName } from '../../../../../modules/analytics/events'
import { track } from '../../../../../modules/analytics/utils'
import { Props } from './ConnectWalletInput.types'
import { connectWallet } from './utils'

import './ConnectWalletInput.css'

const ConnectWalletInput = ({
  value,
  error,
  onError,
  onConnectStart,
  onConnectSuccess,
}: Props) => {
  const { t } = useTranslation()
  const [isConnecting, setIsConnecting] = useState(false)

  const submitButtonI18nKey = 'connectorForm.connectWalletInput.submit'
  const submitButtonText = t(submitButtonI18nKey)
  const submittingButtonText = t('connectorForm.connectWalletInput.submitting')

  const trackSubmit = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      location: 'ConnectWalletInput',
      i18nKey: submitButtonI18nKey,
      text: submitButtonText,
    })
  }, [submitButtonText])

  const connect = useCallback(async () => {
    if (isConnecting) {
      // already connecting
      return
    }
    trackSubmit()
    onConnectStart()
    setIsConnecting(true)

    let accountAddress: string
    try {
      accountAddress = await connectWallet()
    } catch (error_) {
      const { message } = error_

      const errorMessage = message.includes('connectorForm.')
        ? t(message)
        : t('connectorForm.connectWalletInput.error.unexpected-error', {
            message,
          })

      onError(errorMessage)
      return
    } finally {
      setIsConnecting(false)
    }

    // success
    onConnectSuccess(accountAddress)
  }, [isConnecting, trackSubmit, onConnectStart, onConnectSuccess, t, onError])

  // TODO: Improve this display UI
  //  - display more than 1 account if user authorizes it?
  //  - support un-selecting/de-authorizing/restarting auth flow?
  //  https://pluggy.atlassian.net/browse/RAM-138
  return (
    <div className={'ConnectWalletInput'}>
      <Button
        className={'connect-wallet'}
        type={'button'}
        secondary
        onClick={connect}
        loading={isConnecting}
      >
        {isConnecting ? submittingButtonText : submitButtonText}
      </Button>
      {value && (
        <p className={'current-account'}>
          <Trans
            i18nKey={'connectorForm.connectWalletInput.current-account'}
            values={{ address: value }}
          />
        </p>
      )}
      {error && (
        <Alert
          className={'connect-wallet-error'}
          type={'error'}
          message={error}
          size="medium"
        />
      )}
    </div>
  )
}

export default memo(ConnectWalletInput)
