import { isRunningAsCordovaWebView } from './appWrapper'

/**
 * Send a message to the parent component with the URL, whom will open it in the system browser.
 * @param {string} href
 */
export function sendOpenLinkMessageToCordovaParent(href: string): void {
  window.parent.postMessage(
    JSON.stringify({
      type: 'LINK_OPEN',
      message: href,
    }),
    '*',
  )
}

/**
 * Add support for external links in Cordova.
 * When running in Cordova, if clicking on a.href, instead of default navigation (open a fullscreen webview)
 * send a message to the parent component with the URL, whom will open it in the system browser.
 */
export function addCordovaExternalLinksSupport() {
  if (!isRunningAsCordovaWebView()) {
    // not in Cordova, ignore
    return
  }

  document.addEventListener('click', (event) => {
    const { target } = event

    if (!(target instanceof HTMLAnchorElement)) {
      // not an <a> element
      return
    }

    const { href } = target
    if (!href.startsWith('http')) {
      // no http(s) link, ignore
      return
    }

    event.preventDefault()
    sendOpenLinkMessageToCordovaParent(href)
  })
}
