import { memo } from 'react'

import { Props } from './Title.props'

import './Title.css'

const Title = ({ children, className }: Props) => (
  <h1 className={`Title ${className || ''}`}>{children}</h1>
)

export default memo(Title)
