import React, { useCallback } from 'react'

import {
  isPersonalSafraConnector,
  isSandbox2StepConnector,
  isCaixaConnector,
} from '../../../../../../modules/connector/utils'
import { CaixaDeviceUnauthorizedStatus } from './CaixaDeviceUnauthorizedStatus'
import { Props } from './DeviceAuthorizationNeededStatus.types'
import { DeviceAuthorizedStatus } from './DeviceAuthorizedStatus'
import { DeviceUnauthorizedStatus } from './DeviceUnauthorizedStatus'
import { PersonalSafraDeviceAuthorization } from './PersonalSafraDeviceAuthorization'

import './DeviceAuthorizationNeededStatus.css'

const DeviceAuthorizationNeededStatus = ({
  item,
  connector,
  displayInstitutionLogo,
  onClose,
  onRetry,
}: Props) => {
  const [isDeviceAuthorized, setIsDeviceAuthorized] = React.useState(false)

  const { deviceNickname, qrCodes } = item.error?.attributes || {}

  const { providerMessage } = item.error || {}

  const handleDeviceAuthorizedClick = useCallback(() => {
    setIsDeviceAuthorized(true)
  }, [])

  const isPersonalSafraDeviceAuthorizationFlow =
    isPersonalSafraConnector(connector) || isSandbox2StepConnector(connector)

  return (
    <div className={'DeviceAuthorizationNeededStatus'}>
      {isPersonalSafraDeviceAuthorizationFlow ? (
        <PersonalSafraDeviceAuthorization
          item={item}
          onClose={onClose}
          onRetry={onRetry}
        />
      ) : isDeviceAuthorized ? (
        <DeviceAuthorizedStatus onClose={onClose} connector={connector} />
      ) : isCaixaConnector(connector) ? (
        <CaixaDeviceUnauthorizedStatus
          deviceNickname={deviceNickname}
          qrCodes={qrCodes}
          onDeviceAuthorizedClick={handleDeviceAuthorizedClick}
        />
      ) : (
        <DeviceUnauthorizedStatus
          displayInstitutionLogo={displayInstitutionLogo}
          deviceNickname={deviceNickname}
          connector={connector}
          onClose={onClose}
          onDeviceAuthorizedClick={handleDeviceAuthorizedClick}
          providerMessage={providerMessage}
        />
      )}
    </div>
  )
}

export default React.memo(DeviceAuthorizationNeededStatus)
