import { AnyAction } from 'redux'

import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { CHECK_AUTH_REQUEST } from './actions'
import { AuthState } from './reducer'

export const getState = (state: RootState): AuthState => state.auth as AuthState
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType: (state: LoadingState, type: string) => boolean = (
  state,
  type,
) => state.some((action: AnyAction) => action.type === type)

export const isAuthorized = (state: RootState) => getData(state).isAuthorized
export const isAuthorizing = (state: RootState) =>
  isLoadingType(getLoading(state), CHECK_AUTH_REQUEST)
