import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import type { ConnectorCredential } from 'pluggy-js'
import { Popup } from 'semantic-ui-react'

import { TrackEventName } from '../../../../../modules/analytics/events'
import { track } from '../../../../../modules/analytics/utils'
import { ConnectionSecureIcon } from './ConnectionSecureIcon'
import { Props } from './ConnectionSecurePopup.types'

import './ConnectionSecurePopup.css'

const ConnectionSecurePopup = ({ connector }: Props) => {
  const { t } = useTranslation()
  const popupTriggerI18nKey = 'connectorForm.connection-secure-popup.trigger'
  const popupTriggerText = t(popupTriggerI18nKey)
  const handlePopupTriggerClick = useCallback(() => {
    const {
      name: connectorName,
      id: connectorId,
      credentials,
      health: connectorHealth,
    } = connector

    const connectorCredentials = credentials.map(
      (credential: ConnectorCredential) => credential.name,
    )
    track(TrackEventName.POPUP_TRIGGERED, {
      text: popupTriggerText,
      i18nKey: popupTriggerI18nKey,
      connectorName,
      connectorId,
      connectorCredentials,
      connectorHealth,
    })
  }, [popupTriggerText, connector])

  const handlePopupTriggerKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key !== 'Enter') {
        return
      }
      handlePopupTriggerClick()
    },
    [handlePopupTriggerClick],
  )

  return (
    <Popup
      on={'click'}
      className={'ConnectionSecurePopup-popup'}
      pinned
      content={t('connectorForm.connection-secure-popup.content')}
      position={'top center'}
      trigger={
        <div
          className={'ConnectionSecurePopup'}
          onClick={handlePopupTriggerClick}
          onKeyDown={handlePopupTriggerKeyDown}
          role={'button'}
          tabIndex={0}
        >
          <ConnectionSecureIcon />
          {popupTriggerText}
        </div>
      }
    />
  )
}

export default React.memo(ConnectionSecurePopup)
