/**
 * Helper to join specific regexes as valid groups, by using an OR "|" operator
 *
 * @param regexes {string[]} - regex groups to join
 */
function orRegexes(...regexes: string[]): string {
  const removeStartEnd = (regex: string): string => regex.replace(/[\^$]/g, '')
  const addStartEnd = (regex: string): string => `^${regex}$`
  const addParenthesis = (regex: string): string => `(${regex})`

  if (regexes.length === 0) {
    return addStartEnd('')
  }
  if (regexes.length === 1) {
    return addStartEnd(regexes[0])
  }

  const orRegexesJoined = regexes
    .map((regex) => addParenthesis(regex))
    .map((regex) => removeStartEnd(regex))
    .join('|')
  return addStartEnd(orRegexesJoined)
}

const BIRTH_DATE = '^\\d{2}/\\d{2}/\\d{4}$'
const CNPJ = '^\\d{2}.?\\d{3}.?\\d{3}/?\\d{4}-?\\d{2}$'
const CPF = '^\\d{3}\\.?\\d{3}\\.?\\d{3}-?\\d{2}$'
const CPF_OR_CNPJ = orRegexes(CPF, CNPJ)
const CPF_OR_OPERADOR = orRegexes(CPF, '^\\d{9}$')
const EMAIL = '^\\S+@\\S+\\.\\S+$'
const EMAIL_OR_CPF = orRegexes(EMAIL, CPF)
const SIGNATURE = '^[A-Z]{3}\\d{4}$'

/**
 * List of literal validations as defined in pluggy-connectors credentials.
 * With these, we can match them, and map them to UI input masks.
 */
export const literalValidations = {
  BIRTH_DATE,
  CNPJ,
  CPF,
  CPF_OR_CNPJ,
  CPF_OR_OPERADOR,
  EMAIL,
  EMAIL_OR_CPF,
  SIGNATURE,
} as const
