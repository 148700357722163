import {
  darken,
  getContrast,
  lighten,
  meetsContrastGuidelines,
  mix,
} from 'polished'

// The contrast ratio we use as the minimum expected to decide which color
// we can use for the button text, while also being readable on the same
// button given background color.
// Note: actual value calculated by testing manually.
const BUTTON_TEXT_CONTRAST_RATIO = 2.5

function isDark(color_: string) {
  let r = 0
  let g = 0
  let b = 0
  if (color_.match(/^rgb/)) {
    const [, red, green, blue] = color_.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/,
    ) || ['0', '0', '0', '0']

    r = Number(red)
    g = Number(green)
    b = Number(blue)
  } else if (color_.length < 5) {
    const color = +`0x${color_.slice(1).replace(/./g, '$&$&')}`

    r = color >> 16
    g = (color >> 8) & 255
    b = color & 255
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))
  return hsp <= 127.5
}

type W3ConformanceLevel = 'AA' | 'AALarge' | 'AAA' | 'AAALarge'

/**
 * Check if color is readable on a background, based on contrast ratio of each other.
 *
 * The expected contrast ratio can be specified as a value, or by using a standard W3 conformance level:
 * 'AA' - for small font size, contrast ratio expected >= 4.5
 * 'AALarge' - for large font size, contrast ratio expected >= 3
 * 'AAA' - for small font size, contrast ratio expected >= 7
 * 'AAALarge' - for large font size, contrast ratio expected >= 4.5
 *
 * Ratios from W3 Accessibilty Guide, source: http://www.w3.org/TR/2008/REC-WCAG20-20081211/#visual-audio-contrast)
 *
 * Note: 'large font size' is considered to be >=14pt for bold or >=18pt normal (source: https://www.w3.org/TR/2008/REC-WCAG20-20081211/#larger-scaledef)
 *
 * @param color - font color intended to be read, as hex RGB string
 * @param backgroundColor - background color where font will be placed, as hex RGB string
 * @param w3ConformanceLevelOrContrastRatio [W3ConformanceLevel | number] (default 'AA') - expected contrast ratio
 */
function isColorReadable(
  color: string,
  backgroundColor: string,
  w3ConformanceLevelOrContrastRatio: W3ConformanceLevel | number = 'AA',
): boolean {
  if (typeof w3ConformanceLevelOrContrastRatio === 'string') {
    const contrastScores = meetsContrastGuidelines(color, backgroundColor)
    return contrastScores[w3ConformanceLevelOrContrastRatio]
  }
  const contrast = getContrast(color, backgroundColor)
  return contrast > w3ConformanceLevelOrContrastRatio
}

/**
 * Return the first readable color found in a set of colors, to be read on a specified background color/s.
 *
 * @param colors - array of colors (in hex RGB strings) to be read
 * @param backgroundColors - background color(s), in hex RGB string (can specify more than one)
 * @param w3ConformanceLevelOrContrastRatio [W3ConformanceLevel] - minimum contrast ratio, or w3 conformance level to comply (default: 'AA')
 */
function pickFirstReadableColor(
  colors: string[],
  backgroundColors: string[],
  w3ConformanceLevelOrContrastRatio: W3ConformanceLevel | number = 'AA',
): string {
  const definedColors = colors.filter(Boolean)
  if (definedColors.length === 0) {
    throw new Error('Not specified any color to pick')
  }
  // lookup the first color that is readable on the provided background color/s (one or many)
  const firstReadableColor = definedColors.find((color) =>
    backgroundColors.every((backgroundColor) =>
      isColorReadable(
        color,
        backgroundColor,
        w3ConformanceLevelOrContrastRatio,
      ),
    ),
  )
  if (!firstReadableColor) {
    const contrastsByColors = definedColors.map((definedColor) =>
      backgroundColors.map((backgroundColor) => ({
        definedColor,
        backgroundColor,
        contrast: getContrast(definedColor, backgroundColor),
      })),
    )
    const lastColor = colors[colors.length - 1]
    console.warn(
      'No readable color found in colors: ',
      colors,
      ` for background '${JSON.stringify(backgroundColors)}'. `,
      `Falling back to the latest color: '${lastColor}' (using min contrast ratio or w3 conformance level: '${w3ConformanceLevelOrContrastRatio}'}`,
      contrastsByColors,
    )
    return lastColor
  }
  return firstReadableColor
}

/**
 * Helper to calculate primaryHover color based on a primary color.
 * If primary is dark, lighten it a bit (10%), otherwise darken it a bit (10%).
 *
 * @param color {string} - color hex RGB string
 */
export function calculatePrimaryHoverColor(color: string): string {
  return calculateSecondaryColor(color, 0.1)
}

/**
 * Calculate a lighter/darker secondary color, based on a primary color.
 * If dark, return a lightened color, otherwise a darkened color.
 *
 * @param color {string} - color hex RGB string
 * @param amount {number} - amount of light/dark to add (float, range: [0, 1]), default 0.1
 */
export function calculateSecondaryColor(color: string, amount = 0.1): string {
  return isDark(color) ? lighten(amount, color) : darken(amount, color)
}

/**
 * Helper to calculate secondaryHover color based on primary color + a base default color.
 * Mix 90% of the baes color with 10% of the primary color.
 *
 * @param primaryColor - color hex RGB string
 * @param baseHoverColor - color hex RGB string
 */
export function calculateSecondaryHoverColor(
  primaryColor: string,
  baseHoverColor: string,
): string {
  return mix(0.1, primaryColor, baseHoverColor)
}

/**
 * Helper to retrieve the final computed value of a CSS variable
 *
 * @param {string} propertyName - CSS property name, ie. '--primary'
 * @return {string} - value of the CSS property
 */
function getCssPropertyValue(propertyName: string): string {
  return getComputedStyle(document.body).getPropertyValue(propertyName).trim()
}

/**
 * Helper to calculate button text color, based on contrast ratio calculation on the specified button background color.
 *
 * For the button text, try to reuse background color first,
 * if contrast is not good enough then reuse 'text label' color.
 *
 * Note:
 *  For theme 'light' (default), we'll attempt to use WHITE first; otherwise BLACK.
 *  For theme 'dark', attempt to use BLACK first; otherwise WHITE.
 *
 * @param {string} buttonBackgroundColor - button background color, in hex RGB string (ie.: '#fff', '#ffffff').
 *                                         also can be in string formats: hex, rgb, rgba, hsl or hsla notation.
 * @return {string} - the first color considered readable to be used on the specified background
 */
export function calculateButtonTextColorForBackgroundColor(
  buttonBackgroundColor: string,
): string {
  const connectorButtonTextColorOptions = [
    getCssPropertyValue('--background'),
    getCssPropertyValue('--text-label'),
  ]

  return pickFirstReadableColor(
    connectorButtonTextColorOptions,
    [buttonBackgroundColor],
    BUTTON_TEXT_CONTRAST_RATIO,
  )
}
