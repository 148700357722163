import { captureMessage, Severity } from '@sentry/react'

import {
  PATTERN_ANY_CHAR,
  PATTERN_ANY_DIGIT,
  PATTERN_TO_OPTIONAL,
} from './constants'
import { NUMERIC_INPUT_MODE } from './inputMode'
import { InputMaskProps } from './types'

/**
 * Recognizes 'validation' regex strings like:
 *
 * "^.{5,}$"
 * "^.{3,5}-.$"
 * "^\d$"
 * "^\d{3}$"
 * "^\d{3}-\d$"
 * "^\d{3}-\d{1}$"
 * "^\d{3}-\d{1,3}$"
 * "^\d{3,5}-?\d{1}$"
 * "^\d{3,5}$"
 * "^\d{3,5}-\d$"
 * "^\d{3,5}-\d{1}$"
 * "^\d{3,5}-\d{1,3}$"
 * "^\d{3,3}-\d{1,3}$"
 * "^\d-\d{1,3}$"
 */
const BASIC_VALIDATION_MATCHER_REGEX =
  /\^(\.|\\d)({(\d+)?(,(\d+)?)?})?((-(\??))?\[?(\.|\\d|A-Za-z\\d)\]?(({(\d+)?(,(\d+)?)?})?))?\$/

/**
 * Reverse dictionary helper for group indexes with their meanings,
 * for the BASIC_VALIDATION_MATCHER_REGEX.
 * Note: these have been calculated manually.
 */
const reverseGroupIndex = {
  BASE_CHAR: 1, // first group is the base char, either '.' or '\d' (required)
  FIRST_MIN_LENGTH: 3, // matches min length repeat of first group
  FIRST_IS_OPEN_MATCH: 4, // matches opening ',' of first group, must check if not closed for open match
  FIRST_MAX_LENGTH: 5, // matches max length repeat of first group

  HYPHEN: 7, // matches "-"
  HYPHEN_IS_OPTIONAL_MATCH: 8, // matches "?" if hyphen was optional

  SECOND_BASE_CHAR: 9,
  SECOND_MIN_LENGTH: 12, // matches min length repeat of second group
  SECOND_IS_OPEN_MATCH: 13, // matches opening ',' of second group, must check if not closed for open match
  SECOND_MAX_LENGTH: 14, // matches max length repeat of second group
} as const

function buildBasicValidationSubgroupMask(
  matches: RegExpMatchArray,
  matchesIndexes: {
    baseChar: number
    minLength: number
    isOpenMatch: number
    maxLength: number
  },
): string | null {
  // initialize Sentry report contexts
  const sentryContexts = {
    validation: {
      basicValidationRegexMatcher: String(BASIC_VALIDATION_MATCHER_REGEX),
      matchesIndexes,
      matches,
    },
  }

  // 1. recognize base char
  let base: string
  const baseMatch = matches[matchesIndexes.baseChar] as string | undefined
  if (baseMatch === undefined) {
    // the specified 'baseChar' group index is not present -> no group to build a pattern for
    return null
  }

  if (baseMatch === '.' || baseMatch === 'A-Za-z\\d') {
    base = PATTERN_ANY_CHAR
  } else if (baseMatch === '\\d') {
    base = PATTERN_ANY_DIGIT
  } else {
    // unexpected base found
    // this should not happen if this was correctly recognized as a basic validation regex
    captureMessage(`unexpected base char found: ${baseMatch} `, {
      contexts: sentryContexts,
    })
    return null
  }

  const repeatMinMatch = matches[matchesIndexes.minLength]
  const isOpenRangeMatch = matches[matchesIndexes.isOpenMatch]
  const isOpenRange = isOpenRangeMatch
    ? isOpenRangeMatch.includes(',')
    : undefined
  const repeatMaxMatch = matches[matchesIndexes.maxLength]

  const isRangeClosed = !isOpenRange // repeatMaxMatch === undefined
  const minLength = !repeatMinMatch ? 1 : Number(repeatMinMatch)

  const minPattern = base.repeat(minLength)

  if (isRangeClosed) {
    // is closed range => exact match
    return minPattern
  }

  // is open range => enforce min exact length, then repeat base optionals until the end
  // 2. repeat base in min-max range
  const PSEUDO_INFINITE_RANGE = 50
  const maxLength = !repeatMaxMatch
    ? PSEUDO_INFINITE_RANGE
    : Number(repeatMaxMatch)

  if (Number.isNaN(maxLength)) {
    captureMessage(
      `Unexpectedly could not recognize maxLength in repeatMaxMatch str: ${repeatMaxMatch}, from group: #${matchesIndexes.maxLength}`,
      {
        contexts: sentryContexts,
      },
    )
    return null
  }

  const optionalExtraLength = maxLength - minLength
  const optionalMaxPattern =
    PATTERN_TO_OPTIONAL(base).repeat(optionalExtraLength)

  return `${minPattern}${optionalMaxPattern}`
}

function buildHyphenValidationSubgroupMask(
  matches: RegExpMatchArray,
): string | null {
  const hyphenGroupIndex = reverseGroupIndex.HYPHEN
  const hyphenMatch = matches[hyphenGroupIndex]
  const hyphen = hyphenMatch ? hyphenMatch.replace('?', '') : undefined
  if (!hyphen) {
    // no hyphen
    return null
  }
  // has hyphen, check if optional and return mask
  const hyphenIsOptionalMatchIndex = reverseGroupIndex.HYPHEN_IS_OPTIONAL_MATCH
  const hyphenOptionalMatch = matches[hyphenIsOptionalMatchIndex]
  if (hyphenOptionalMatch && hyphenOptionalMatch !== '?') {
    captureMessage(
      `Unexpectedly found something else than an optional operator in group #${hyphenIsOptionalMatchIndex}`,
      {
        contexts: {
          validation: {
            matches,
            hyphenGroup: hyphenGroupIndex,
            hyphenIsOptionalGroup: hyphenIsOptionalMatchIndex,
            reverseGroupIndex,
          },
        },
      },
    )
    return null
  }
  const isHyphenOptional = hyphenOptionalMatch === '?'

  return isHyphenOptional ? PATTERN_TO_OPTIONAL(hyphen) : hyphen
}

/**
 * Checks if 'validation' regex is recognized as a 'basic validation' pattern (ie. AGENCY, PASSWORD, etc.)
 * @param validationRegex - the credential.validation string regex
 */
export function isBasicValidationRegex(validationRegex: string): boolean {
  return new RegExp(BASIC_VALIDATION_MATCHER_REGEX).test(validationRegex)
}

/**
 * Build mask from a "basic validation" regex, which
 * represent cases such as AGENCY, PASSWORD, ACCOUNT, etc, that have a format like these:
 * "^.{5,}$"
 * "^\d{3}$"
 * "^\d{3}-\d{1,3}$"
 * "^\d{3,5}-?\d{1}$"
 * "^\d{3,5}-\d$"
 *
 * @param basicValidationRegex
 */
export function buildBasicValidationMask(
  basicValidationRegex: string,
): InputMaskProps | null {
  const matches = basicValidationRegex.match(BASIC_VALIDATION_MATCHER_REGEX)

  if (!matches) {
    // unexpectedly could not recognize validationRegex as "basic"
    captureMessage(
      `Unexpectedly could not recognize 'validation' regex: '${basicValidationRegex}' as "basic"`,
      {
        level: Severity.Error,
      },
    )
    return null
  }

  // _A_: recognize first group
  // 1. recognize base char
  // 2. repeat base in min-max range
  const firstPatternMatcherIndexes = {
    baseChar: reverseGroupIndex.BASE_CHAR,
    minLength: reverseGroupIndex.FIRST_MIN_LENGTH,
    isOpenMatch: reverseGroupIndex.FIRST_IS_OPEN_MATCH,
    maxLength: reverseGroupIndex.FIRST_MAX_LENGTH,
  }

  const firstPattern = buildBasicValidationSubgroupMask(
    matches,
    firstPatternMatcherIndexes,
  )
  if (!firstPattern) {
    // no first pattern could be created, no mask
    captureMessage('no first pattern could be created, returned mask is null')
    return null
  }
  const isFirstPatternNumeric =
    matches[firstPatternMatcherIndexes.baseChar] === '\\d'

  // _B_: recognize hyphen group, if any
  // 3. add hyphen (possibly optional), if any
  const hyphenPattern = buildHyphenValidationSubgroupMask(matches)

  // _C_: recognize second group
  // 4. recognize base char
  // 5. repeat base in min-max range
  const secondPatternMatcherIndexes = {
    baseChar: reverseGroupIndex.SECOND_BASE_CHAR,
    minLength: reverseGroupIndex.SECOND_MIN_LENGTH,
    isOpenMatch: reverseGroupIndex.SECOND_IS_OPEN_MATCH,
    maxLength: reverseGroupIndex.SECOND_MAX_LENGTH,
  }
  const secondPattern = buildBasicValidationSubgroupMask(
    matches,
    secondPatternMatcherIndexes,
  )

  if (hyphenPattern && !secondPattern) {
    // got first and hyphen patterns, but no secondPattern => no mask
    captureMessage(
      'got firstPattern and hyphenPattern, but no secondPattern: returned mask is null',
    )
    return null
  }
  const isSecondPatternNumeric =
    secondPattern && matches[secondPatternMatcherIndexes.baseChar] === '\\d'

  const mask = [firstPattern, hyphenPattern, secondPattern]
    .filter(Boolean)
    .join('')

  const isNumericMaskPattern =
    isFirstPatternNumeric && (secondPattern ? isSecondPatternNumeric : true)

  return {
    imaskProps: {
      mask,
      eager: true, // auto-fill separators, if any
    },
    inputModeProps: isNumericMaskPattern ? NUMERIC_INPUT_MODE : undefined,
  }
}
