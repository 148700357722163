import { memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ConnectorId } from '../../../../../../modules/connector/utils'
import { Props } from './QRActionInstructions.types'

import './QRActionInstructions.css'

const QRActionInstructions = ({ selectedConnector }: Props) => {
  const { t } = useTranslation()
  return (
    <div className={'QRActionInstructions'}>
      <h3 className={'title'}>
        {t('connectorForm.qr-flow-instructions.title')}
      </h3>
      <Trans
        i18nKey={
          selectedConnector?.id === ConnectorId.BR_BUSINESS_CORA
            ? 'connectorForm.qr-flow-instructions.message.cora'
            : 'connectorForm.qr-flow-instructions.message.inter'
        }
        components={{
          p: <p />,
        }}
      />
    </div>
  )
}

export default memo(QRActionInstructions)
