import React from 'react'

import { Grid, Icon, Loader } from 'semantic-ui-react'

import { Props } from './StepStatus.types'

import './StepStatus.css'

const CheckIcon = () => <Icon name="check" />

const StepStatus = ({ currentStepName, inProgress }: Props) => (
  <Grid.Row className={'StepStatus tip-row'}>
    <Grid.Column width={2} textAlign={'right'}>
      {inProgress ? (
        <Loader inline className="item-status-loader" active size="tiny" />
      ) : (
        <CheckIcon />
      )}
    </Grid.Column>
    <Grid.Column width={14}>{currentStepName}</Grid.Column>
  </Grid.Row>
)

export default React.memo(StepStatus)
