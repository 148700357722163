import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button, Grid } from '@pluggyai/ui'

import { TrackEventName } from '../../../../modules/analytics/events'
import { track } from '../../../../modules/analytics/utils'
import { Title } from '../../Title'
import { TermsConsentText } from './TermsConsentText'
import { Props } from './Welcome.types'

import './Welcome.css'

const Bullet = ({
  title,
  text,
}: {
  title: string | React.ReactElement
  text: string | React.ReactElement
}) => (
  <Grid.Row className="tip-row">
    <div className={'bullet-title'}>{title}</div>
    <div className={'bullet-text'}>{text}</div>
  </Grid.Row>
)

const Welcome = ({
  onNext,
  onGoToTermsPage,
  onGoToPrivacyPolicyPage,
  companyName,
  customWelcomeButtonText,
}: Props) => {
  const classNames = ['Welcome']

  const { t } = useTranslation()

  const continueButtonI18nKey = 'welcome.continue'
  const continueButtonText = customWelcomeButtonText || t(continueButtonI18nKey)

  const handleContinueButtonClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      location: 'welcomePage',
      text: continueButtonText,
      i18nKey: continueButtonI18nKey,
    })
    onNext()
  }, [continueButtonText, onNext])

  return (
    <div className={classNames.join(' ')}>
      {t('welcome.header') && (
        <Title>
          <Trans i18nKey={'welcome.header'} values={{ companyName }} />
        </Title>
      )}
      <div className={'bullets-section'}>
        <div>
          <Bullet
            title={<Trans i18nKey="welcome.bullets.its-secured.header" />}
            text={<Trans i18nKey="welcome.bullets.its-secured.content" />}
          />
          <Bullet
            title={<Trans i18nKey="welcome.bullets.its-private.header" />}
            text={
              <Trans
                i18nKey="welcome.bullets.its-private.content"
                values={{ companyName }}
              />
            }
          />
        </div>
      </div>

      <div className={'consent-section'}>
        <TermsConsentText
          onGoToPrivacyPolicyPage={onGoToPrivacyPolicyPage}
          onGoToTermsPage={onGoToTermsPage}
          continueButtonText={continueButtonText}
        />
        <Button primary onClick={handleContinueButtonClick}>
          {continueButtonText}
        </Button>
      </div>
    </div>
  )
}

export default React.memo(Welcome)
