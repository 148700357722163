import { addBreadcrumb, captureException, Severity } from '@sentry/react'
import type { Breadcrumb } from '@sentry/types'
import { AxiosError, AxiosResponse } from 'axios'
import axiosBetterStacktrace from 'axios-better-stacktrace'
import i18next from 'i18next'
import Pluggy from 'pluggy-js'
import * as retryAxios from 'retry-axios'

import { ConnectTokenPayload } from '../modules/auth/types'
import { getAppProps } from '../utils/appWrapper'

const { REACT_APP_PLUGGY_CORE_API_URL: pluggyCoreApiUrl = '' } = process.env
if (!pluggyCoreApiUrl) {
  throw new Error('Missing environment variable REACT_APP_PLUGGY_CORE_API_URL')
}

const { connectToken, sdkVersion } = getAppProps()

/**
 * Append specified language to server requests
 */
function setRequestLanguage(pluggy: Pluggy, language: string) {
  const axiosInstance = pluggy.getServiceInstance()

  // append selected language to all requests
  axiosInstance.interceptors.request.use(function (config) {
    const acceptLanguages: string[] = (
      config.headers['Accept-Language'] || ''
    ).split(',')

    config.headers['Accept-Language'] = [
      language,
      ...acceptLanguages,
      ...navigator.languages,
    ]
      .filter((language_) => Boolean(language_))
      .join(',')

    return config
  })
}

function setPluggyUserAgentHeader(pluggy: Pluggy): void {
  const axiosInstance = pluggy.getServiceInstance()

  // get base user-agent from pluggy-js
  const pluggyJsUserAgent = axiosInstance.defaults.headers['Pluggy-User-Agent']

  // include Pluggy's own parent application, or client's applicaton URL
  const { referrer } = document
  let parentApplication = referrer

  if (referrer.endsWith('demo.pluggy.ai/')) {
    parentApplication = 'PluggyDemo'
  } else if (referrer === 'https://dashboard.pluggy.ai/') {
    parentApplication = 'PluggyDashboard'
  } else if (referrer === 'https://pluggy.ai/') {
    parentApplication = 'PluggySite'
  }

  // create custom user agent including PluggyConnect SDK version and parent application referrer
  const pluggyConnectPluggyJsUserAgent = `${pluggyJsUserAgent} PluggyConnect (${parentApplication}) ${sdkVersion}`

  // override default 'Pluggy-User-Agent' header
  axiosInstance.defaults.headers.common['Pluggy-User-Agent'] =
    pluggyConnectPluggyJsUserAgent
}

// Regexp to match a UUID value
// source: https://stackoverflow.com/a/6640851/6279385
const UUID_REGEXP =
  /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/

/**
 * Helper to find and replace uuid values from an URL string to normalize them
 */
function replaceUuidValues(url: string): string {
  return url.replace(new RegExp(UUID_REGEXP, 'g'), ':uuid')
}

/**
 * Helper to build an URL transaction string from the method+url values
 */
function buildUrlTransaction(
  method: string | undefined,
  url: string | undefined,
): string {
  return `${method?.toUpperCase()} ${url && replaceUuidValues(url)}`
}

/**
 * Send Sentry report of a request that has failed and we are going to retry,
 * or of a request that has failed for the maximum allowed times already
 * and is considered not recoverable.
 *
 * @param error
 * @param retryAxiosConfig
 * @param maxRetries
 * @param currentRetry
 */
function reportPluggyApiRetryToSentry({
  error,
  maxRetries,
  currentRetry,
}: {
  error: AxiosError<unknown>
  maxRetries: number
  currentRetry: number
}): void {
  const isLastRetry = currentRetry === maxRetries

  const retryString = isLastRetry
    ? `last (#${currentRetry}) retry`
    : `first attempt (retries left: ${maxRetries})`
  const level = isLastRetry ? Severity.Fatal : Severity.Warning

  const {
    config: { method, url } = {},
    message,
    code,
    response: { data, status } = {},
  } = error

  let errorPayloadMessage: string | undefined
  if (data) {
    // extract error response JSON payload 'message' field, if exists
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;({ message: errorPayloadMessage } = data as {
      message?: string
    })
  }

  const urlTransaction = buildUrlTransaction(method, url)

  // specify fingerprint to get different Sentry issues for different errors
  const fingerprint = [
    message,
    code,
    status,
    errorPayloadMessage,
    currentRetry,
  ].map(String)

  error.message = `Failed ${retryString} of pluggy-api request: '${message}' (code: ${code})${
    errorPayloadMessage ? ` - '${errorPayloadMessage}'` : ''
  }`

  // send Sentry report
  captureException(error, {
    level,
    tags: {
      urlTransaction,
    },
    fingerprint,
  })
}

/**
 * Setup axios-better-stacktrace config on Pluggy Axios instance,
 * to get full stack traces for debuging & error reports.
 *
 * @param {PluggyExtension} pluggy
 */
function setupAxiosBetterStacktrace(pluggy: PluggyExtension) {
  const pluggyAxios = pluggy.getServiceInstance()
  axiosBetterStacktrace(pluggyAxios, { errorMsg: 'PluggyClient' })
}

/**
 * Setup retry-axios config on Pluggy axios instance, to retry the requests
 * we consider retriable, every few ms and for a limited amount of attempts.
 * Also report to Sentry on the first and last retry attempts.
 *
 * @param pluggy
 */
function setupRetryAxios(pluggy: Pluggy): void {
  const pluggyAxios = pluggy.getServiceInstance()

  // initial max retries config - can be overridden in some cases
  const maxRetries = 5

  // setup retry-axios config on pluggy axios instance
  pluggyAxios.defaults.raxConfig = {
    instance: pluggyAxios,
    retry: maxRetries,
    noResponseRetries: maxRetries,
    backoffType: 'exponential', // delay: 0.5s, 1s, 2s, 4s, ... 2^(maxRetries-1)
    // The response status codes to retry.  Supports a double
    // array with a list of ranges.  Defaults to:
    // [[100, 199], [429, 429], [500, 599]]
    statusCodesToRetry: [
      [100, 199],
      [429, 429],
      [501, 599], // exclude 500, as it might be a legitimate API unhandleable error
    ],
    // HTTP methods to automatically retry.  Defaults to:
    // ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT']
    httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT'],

    // callback called after a retry attempt (before the next one), the return
    // value determines if the retry has to occur or not.
    // Note: using this config method as a workaround for this retry-axios lib, because
    // there is no proper method available of detecting/handling all of the retry attempts.
    shouldRetry: (error: AxiosError): boolean => {
      const retryAxiosConfig = retryAxios.getConfig(error)
      if (!retryAxiosConfig) {
        // this should not happen, but we check it to narrow the type
        return false
      }

      // is about to retry, report to Sentry if necessary
      const { currentRetryAttempt } = retryAxiosConfig
      const shouldRetryReturnValue = retryAxios.shouldRetryRequest(error)

      if (currentRetryAttempt === undefined) {
        // no 'currentRetryAttempt' has been set, or still no retry has happened -> just return proceeding with the original value
        return shouldRetryReturnValue
      }

      const isFirstFailedAttempt = currentRetryAttempt === 0
      const isLastFailedRetry = currentRetryAttempt === maxRetries

      // build appropriate axios error message
      const { code, message, response, request } = error
      let axiosErrorMessage: string
      if (!response) {
        // no response from server (ie. Network Error)
        if (!request) {
          // also no error.request -> client malformed request error
          axiosErrorMessage = `Malformed axios request: ${message}`
        } else {
          // the error.request is present
          if (!window.navigator.onLine) {
            // client is offline
            axiosErrorMessage = 'Client is offline'
          } else {
            // client is online, but there was no response from server
            axiosErrorMessage = `No response from server - ${message} (code: ${code})`
          }
        }
      } else {
        // received a server response
        axiosErrorMessage = `${error.message} (code: ${error.code})`
      }

      // log breadcrumb of the state of the current axios attempt & current/remaining retries
      const timestampString = new Date().toISOString()
      let failedAxiosInfoMessage: string
      let isRetryingInfoMessage: string
      if (isFirstFailedAttempt) {
        // still not retried, just the original request failed once
        failedAxiosInfoMessage = `Failed axios request: '${axiosErrorMessage}'`
        isRetryingInfoMessage = shouldRetryReturnValue
          ? `Retrying up to ${maxRetries} times...`
          : 'Not retriable.'
      } else {
        // already occurred (and failed) an axios retry
        failedAxiosInfoMessage = `Failed axios retry #${currentRetryAttempt} (out of ${maxRetries}): '${axiosErrorMessage}'`
        isRetryingInfoMessage = shouldRetryReturnValue
          ? 'Retrying...'
          : 'Not retrying anymore.'
      }

      // add response error as Sentry breadcrumb
      const breadcrumb: Breadcrumb = {
        message: `${timestampString} ${failedAxiosInfoMessage}. ${isRetryingInfoMessage}`,
      }

      if (response?.data) {
        // include server error response payload data
        breadcrumb.data = {
          rawResponse: response.data,
        }
      }
      addBreadcrumb(breadcrumb)

      // report to Sentry when a retriable request failed for the first time,
      // or when failed and there are no more retries left (which means it's failing definitely)
      const shouldReportToSentry =
        (shouldRetryReturnValue && isFirstFailedAttempt) || isLastFailedRetry

      if (!shouldReportToSentry) {
        // no Sentry report, return early
        return shouldRetryReturnValue
      }

      reportPluggyApiRetryToSentry({
        error,
        maxRetries,
        currentRetry: currentRetryAttempt,
      })

      return shouldRetryReturnValue
    },
  }
  retryAxios.attach(pluggyAxios)
}

class PluggyExtension extends Pluggy {
  /**
   * Retrieve connectToken internal data such as clientUserId
   *
   * @return {Promise<{clientUserId?: string, connectTokenId: string}>}
   */
  fetchConnectTokenPayload(): Promise<
    AxiosResponse<Pick<ConnectTokenPayload, 'clientUserId' | 'connectTokenId'>>
  > {
    return this.getServiceInstance().get(`${pluggyCoreApiUrl}/connect_token`)
  }
}

/**
 * Retrieve pluggy-api client instance
 *
 * @param options.withRetries {boolean}: If set to false, won't retry retriable requests (such as 429, 404, Network error, etc). Optional, default: true.
 */
export function getPluggyClient(
  options: { withRetries: boolean } = { withRetries: true },
): PluggyExtension {
  const { withRetries } = options

  const pluggy = new PluggyExtension(connectToken, pluggyCoreApiUrl)

  // configure selected language for all Pluggy API requests
  setRequestLanguage(pluggy, i18next.language)

  // configure pluggy-user-agent with connect sdk versions
  setPluggyUserAgentHeader(pluggy)

  // configure axios-better-stacktrace for Pluggy Axios instance requests
  setupAxiosBetterStacktrace(pluggy)

  if (withRetries) {
    // configure retry-axios for failed Pluggy API retriable requests
    setupRetryAxios(pluggy)
  }

  return pluggy
}
