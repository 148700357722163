import type { ExecutionErrorCode, ExecutionStatus, Item } from 'pluggy-js'

export type EXECUTION_ERROR =
  | 'ERROR'
  | 'SITE_NOT_AVAILABLE'
  | 'ACCOUNT_LOCKED'
  | 'CREATE_ERROR'
  | 'CONNECTION_ERROR'
  | 'ACCOUNT_NEEDS_ACTION'

const EXECUTION_ERROR_FINAL_STATUSES: ExecutionStatus[] = [
  'ERROR',
  'SITE_NOT_AVAILABLE',
  'CREATE_ERROR',
  'CONNECTION_ERROR',
  'USER_AUTHORIZATION_PENDING',
  'USER_AUTHORIZATION_NOT_GRANTED',
]

// TODO: this should not be needed.
//  check item.error.code too, in some cases where item.status is not properly mapped.
//  Ticket: https://pluggy.atlassian.net/browse/CAPI-327
const EXECUTION_ERROR_CODE_FINAL_STATUSES: ExecutionErrorCode[] = [
  'UNEXPECTED_ERROR',
  'SITE_NOT_AVAILABLE',
  'CONNECTION_ERROR',
]

/**
 * Utility to check if Item is on a error final status, this means
 * that we should navigate to the last ConnectStatus screen
 * and show the appropriate terminal error message.
 *
 * @param item
 */
export function isExecutionErrorFinalStatus(item: Item): boolean {
  const isExecutionFinalStatus =
    item.status === 'OUTDATED' &&
    EXECUTION_ERROR_FINAL_STATUSES.includes(item.executionStatus)

  // TODO: this should not be needed.
  //  check item.error.code too, in some cases where item.status is not properly mapped.
  //  Ticket: https://pluggy.atlassian.net/browse/CAPI-327
  const isExecutionErrorCodeFinalStatus =
    item.error?.code !== undefined &&
    EXECUTION_ERROR_CODE_FINAL_STATUSES.includes(item.error.code)

  return isExecutionFinalStatus || isExecutionErrorCodeFinalStatus
}

/**
 * Check if execution status is a product collecting status.
 * These are all statuses finished in "_IN_PROGRESS" (such as:
 * "ACCOUNTS_IN_PROGRESS", "TRANSACTIONS_IN_PROGRESS"), except
 * for the "LOGIN" ones.
 *
 * TODO we should move this to pluggy-js
 *
 * @param {ExecutionStatus} status
 * @return {boolean}
 */
export function isCollectingProducts(status: ExecutionStatus): boolean {
  return !status.includes('LOGIN') && status.endsWith('_IN_PROGRESS')
}
