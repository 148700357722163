import { action } from 'typesafe-actions'

/**
 *  Missing Bank Submit
 */

export const MISSING_BANK_SUBMIT_REQUEST = '[Request] Missing Bank Submit'
export const MISSING_BANK_SUBMIT_SUCCESS = '[Success] Missing Bank Submit'
export const MISSING_BANK_SUBMIT_FAILURE = '[Failure] Missing Bank Submit'

export const missingBankSubmitRequest = (bank: string) =>
  action(MISSING_BANK_SUBMIT_REQUEST, { bank })
export const missingBankSubmitSuccess = (bank: string) =>
  action(MISSING_BANK_SUBMIT_SUCCESS, { bank })
export const missingBankSubmitFailure = (error: string) =>
  action(MISSING_BANK_SUBMIT_FAILURE, { error })

export type MissingBankSubmitRequestAction = ReturnType<
  typeof missingBankSubmitRequest
>
export type MissingBankSubmitSuccessAction = ReturnType<
  typeof missingBankSubmitSuccess
>
export type MissingBankSubmitFailureAction = ReturnType<
  typeof missingBankSubmitFailure
>
