import { AxiosResponse } from 'axios'
import { all, put, takeLatest } from 'redux-saga/effects'

import { AuthApiClient } from '../../lib/authApiClient'
import { getAppProps } from '../../utils/appWrapper'
import {
  FETCH_TERMS_OF_USE_REQUEST,
  fetchTermsOfUseFailure,
  FetchTermsOfUseRequestAction,
  fetchTermsOfUseSuccess,
} from './actions'

function* handleFetchTermsOfUseRequest(_action: FetchTermsOfUseRequestAction) {
  const { connectToken } = getAppProps()

  if (!connectToken) {
    yield put(
      fetchTermsOfUseFailure("Missing connect token, can't fetch terms of use"),
    )
  }

  const authApiClient = new AuthApiClient(connectToken)

  try {
    const [{ data: termsAndConditions }, { data: privacyPolicy }]: [
      AxiosResponse<string>,
      AxiosResponse<string>,
    ] = yield all([
      authApiClient.getTermsAndConditions(),
      authApiClient.getPrivacyPolicy(),
    ])

    yield put(fetchTermsOfUseSuccess({ termsAndConditions, privacyPolicy }))
  } catch (error) {
    yield put(fetchTermsOfUseFailure(error.message))
  }
}

export function* termsOfUseSaga() {
  yield all([
    takeLatest(FETCH_TERMS_OF_USE_REQUEST, handleFetchTermsOfUseRequest),
  ])
}
