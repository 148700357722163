import type { Connector } from 'pluggy-js'

import { loadingReducer, LoadingState } from '../loading/reducer'
import {
  CONNECTOR_SELECT,
  ConnectorSelectAction,
  FETCH_CONNECTORS_FAILURE,
  FETCH_CONNECTORS_REQUEST,
  FETCH_CONNECTORS_SUCCESS,
  FetchConnectorsFailureAction,
  FetchConnectorsRequestAction,
  FetchConnectorsSuccessAction,
} from './actions'

export type ConnectorState = {
  data: {
    connectors: Record<string, Connector>
    selected: Connector | null
  }
  loading: LoadingState
  error: string | null
}

const INITIAL_STATE: ConnectorState = {
  data: {
    connectors: {},
    selected: null,
  },
  loading: [],
  error: null,
}

type ItemReducer =
  | FetchConnectorsRequestAction
  | FetchConnectorsSuccessAction
  | FetchConnectorsFailureAction
  | ConnectorSelectAction

export function connectorReducer(
  state = INITIAL_STATE,
  action: ItemReducer,
): ConnectorState {
  switch (action.type) {
    case FETCH_CONNECTORS_REQUEST: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
      }
    }
    case FETCH_CONNECTORS_SUCCESS: {
      const { connectors } = action.payload
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          ...state.data,
          connectors: connectors.reduce((connectorObj, connector) => {
            connectorObj[connector.id] = connector
            return connectorObj
          }, {} as Record<string, Connector>),
        },
      }
    }
    case FETCH_CONNECTORS_FAILURE: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
      }
    }
    case CONNECTOR_SELECT: {
      return {
        ...state,
        data: {
          ...state.data,
          selected: action.payload.connector,
        },
      }
    }
    default:
      return state
  }
}
