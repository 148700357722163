import ReactDOM from 'react-dom'

import { loadAndStartRecorder } from '@alwaysmeticulous/recorder-loader'
import { captureException } from '@sentry/react'

import { Application } from './Application'
import { initializeSentry } from './lib/sentry'
import { initializeAnalytics } from './modules/analytics/utils'
import { createStoreAndHistory } from './modules/store'
import { addCordovaExternalLinksSupport } from './utils/cordova'
import { initializeI18n } from './utils/i18n'

async function setupMeticulous() {
  let meticulousLoadError: Error | undefined
  if (window.location.origin !== 'https://connect.pluggy.ai') {
    try {
      await loadAndStartRecorder({
        projectId: 'Neuc6HcsV3hJfEwr8qxg1kUAqNNPoWdVgwQBrdOf',
        uploadIntervalMs: 1000,
      })
      console.log(
        `Meticulous snippet injected (origin is ${window.location.origin})`,
      )
    } catch (error) {
      if (error instanceof Error) {
        meticulousLoadError = error
      }
      console.error(`Meticulous failed to initialise: ${error}`)
    }
  }
  if (meticulousLoadError) {
    captureException(meticulousLoadError)
  }
}

async function startApplication() {
  await setupMeticulous()
  initializeSentry()
  initializeI18n()
  initializeAnalytics()
  const { store, history } = createStoreAndHistory()
  addCordovaExternalLinksSupport()

  ReactDOM.render(
    <Application store={store} history={history} />,
    document.getElementById('root'),
  )
}

startApplication().catch((error) => {
  console.error(error)
  captureException(error)
})
