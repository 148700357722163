import type { Connector } from 'pluggy-js'

import { getAppProps } from '../../utils/appWrapper'
import { getCurrentCustomization } from '../config/selectors'
import { RootState } from '../reducer'
import { ConnectorState } from './reducer'
import {
  isSandboxConnector,
  normalizeSandboxConnectorNameByLanguage,
} from './utils'

export const getState = (state: RootState): ConnectorState => state.connector
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const getConnectors = (state: RootState): Connector[] =>
  Object.values(getData(state).connectors)

export const getSelectedConnector = (state: RootState): Connector | null =>
  getData(state).selected

export const getConnectorsFilteredByConfig = (
  state: RootState,
): Connector[] => {
  const connectors = getConnectors(state)
  const config = getCurrentCustomization(state)

  const connectorIdsFilter = config.connectorsFilters?.ids
  const {
    includeSandbox,
    connectorTypes: propConnectorTypes,
    connectorIds: propConnectorIds,
  } = getAppProps()

  let connectorsFilteredByPropTypes = connectors

  if (propConnectorTypes) {
    connectorsFilteredByPropTypes = connectors.filter((connector) =>
      propConnectorTypes.includes(connector.type),
    )
  }

  const connectorsFilteredByConfig = connectorsFilteredByPropTypes.filter(
    (connector) => {
      const { id } = connector
      const isInConfigConnectorIdsFilter =
        !connectorIdsFilter || connectorIdsFilter.includes(id)
      const isIncludedByConnectorIdsProp =
        !propConnectorIds || propConnectorIds.includes(id)

      const isInConnectorIdsFilters =
        isInConfigConnectorIdsFilter && isIncludedByConnectorIdsProp

      const isIncludedBySandboxFlag =
        includeSandbox && isSandboxConnector(connector)

      return isInConnectorIdsFilters || isIncludedBySandboxFlag
    },
  )

  const connectorsFilteredByConfigWithNormalizedNames =
    connectorsFilteredByConfig.map((connector) => {
      if (isSandboxConnector(connector)) {
        normalizeSandboxConnectorNameByLanguage(connector)
      }

      return connector
    })

  return connectorsFilteredByConfigWithNormalizedNames
}
