import { memo } from 'react'
import { Trans } from 'react-i18next'

import { TrackEventName } from '../../../../../modules/analytics/events'
import { track } from '../../../../../modules/analytics/utils'

import './WiseInstructions.css'

const WISE_SETTINGS_URL = 'https://wise.com/settings/'

const WiseInstructions = () => {
  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    track(TrackEventName.LINK_CLICKED, {
      location: 'ConnectForm',
      link: event.currentTarget.innerText,
    })
  }

  return (
    <div className={'WiseInstructions'}>
      <Trans
        i18nKey={'connectorForm.wise-api-token-instructions'}
        components={{
          a: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href={WISE_SETTINGS_URL}
              onClick={handleLinkClick}
              target={'_blank'}
              rel={'noreferrer'}
            ></a>
          ),
        }}
      />
    </div>
  )
}

export default memo(WiseInstructions)
