import type { Connector } from 'pluggy-js'

import i18n from '../../utils/i18n'

/**
 * Exhaustive list of all Connector keys and their IDs.
 * This list might get outdated as we continue to add more in pluggy-api.
 *  TODO: Should we migrate this to the SDKs such as pluggy-js?
 */
export enum ConnectorId {
  AR_PERSONAL_PLUGGY_BANK = 0,
  AR_PERSONAL_PLUGGY_BANK_MFA_1STEP = 1,
  BR_PERSONAL_PLUGGY_BANK = 2,
  AR_PERSONAL_PLUGGY_BANK_MFA_2STEP = 3,
  BR_PERSONAL_PLUGGY_BANK_MFA_1STEP = 4,
  BR_PERSONAL_PLUGGY_BANK_MFA_2STEP = 5,
  AR_PERSONAL_PLUGGY_BANK_MFA_1_2_STEP = 6,
  AR_PERSONAL_PLUGGY_BANK_MFA_1_2_STEP_MULTI_ACCOUNTS = 7,
  BR_BUSINESS_PLUGGY_BANK = 8,
  BR_PERSONAL_PLUGGY_BANK_MFA_1_2_STEP_MULTI_ACCOUNTS = 9,
  BR_BUSINESS_PLUGGY_BANK_MFA_2STEP = 10,
  BR_BUSINESS_PLUGGY_BANK_CERTIFICATE = 11,
  BR_PERSONAL_PLUGGY_BANK_COOPERATIVE_SELECTION = 12,
  BR_BUSINESS_PLUGGY_BANK_COOPERATIVE_SELECTION = 13,
  BR_INVESTMENT_PLUGGY_BANK = 16,
  BR_INVESTMENT_PLUGGY_BANK_MFA_1STEP = 17,
  BR_INVESTMENT_PLUGGY_BANK_MFA_2STEP = 18,
  BR_PERSONAL_PLUGGY_BANK_QR = 19,
  AR_BUSINESS_BBVA = 100,
  AR_PERSONAL_BBVA = 101,
  AR_BUSINESS_BANCO_DE_CORDOBA = 102,
  AR_BUSINESS_BANCO_DE_COMERCIO = 103,
  AR_BUSINESS_BANCO_PROVINCIA_DE_NEUQUEN = 104,
  AR_BUSINESS_BANCO_NACION = 105,
  AR_BUSINESS_BANCO_DE_CORRIENTES = 106,
  AR_BUSINESS_BANCO_SAN_JUAN = 107,
  AR_BUSINESS_BANCO_ROELA = 108,
  AR_BUSINESS_BANCO_INDUSTRIAL = 109,
  AR_BUSINESS_BANCO_PROVINCIA = 110,
  AR_BUSINESS_BANCO_PATAGONIA = 111,
  AR_BUSINESS_BANCO_GALICIA = 112,
  AR_BUSINESS_BANCO_MACRO = 113,
  AR_BUSINESS_BANCO_DEL_CHACO = 114,
  AR_BUSINESS_BANCO_DEL_CHUBUT = 115,
  AR_BUSINESS_BANCO_CIUDAD = 116,
  AR_PERSONAL_MERCADOPAGO = 117,
  AR_BUSINESS_SUPERVIELLE = 118,
  AR_BUSINESS_REBANKING_MULTIFINANZAS = 119,
  AR_BUSINESS_SANTANDER = 120,
  AR_PERSONAL_LEMON = 121,
  BR_MEU_PLUGGY = 200,
  BR_PERSONAL_ITAU = 201,
  BR_INVESTMENT_XP = 202,
  BR_PERSONAL_BRADESCO = 203,
  BR_PERSONAL_MODALMAIS = 204,
  BR_INVESTMENT_RICO_INVESTIMENTOS = 205,
  BR_PERSONAL_MERCADOPAGO = 206,
  BR_INVESTMENT_EASYNVEST = 207,
  BR_PERSONAL_SANTANDER = 208,
  BR_BUSINESS_BRADESCO = 209,
  BR_INVESTMENT_ORAMA_INVESTIMENTOS = 210,
  BR_PERSONAL_BANCO_DO_BRASIL = 211,
  BR_PERSONAL_NUBANK = 212,
  BR_INVESTMENT_GENIAL_INVESTIMENTOS = 213,
  BR_INVESTMENT_BTG_PACTUAL = 214,
  BR_PERSONAL_BANCO_INTER_PERSONAS = 215,
  BR_BUSINESS_CAIXA = 216,
  BR_BUSINESS_BANCO_DO_BRASIL = 217,
  BR_BUSINESS_ITAU = 218,
  BR_PERSONAL_CAIXA = 219,
  BR_INVESTMENT_AGORA = 220,
  BR_BUSINESS_SANTANDER = 221,
  BR_INVESTMENT_B3_CEI = 222,
  BR_INVESTMENT_CLEAR = 223,
  BR_PERSONAL_AILOS = 224,
  BR_BUSINESS_INTER = 225,
  BR_BUSINESS_SICREDI = 227,
  BR_BUSINESS_SICOOB = 228,
  BR_PERSONAL_SAFRA = 229,
  BR_INVESTMENT_AVENUE = 230,
  BR_BUSINESS_AILOS = 231,
  BR_PERSONAL_AILOS_CREDIT_CARD = 232,
  BR_INVESTMENT_EMPIRICUS = 233,
  BR_PERSONAL_INTER_QR = 234,
  BR_PERSONAL_ORIGINAL = 236,
  BR_PERSONAL_ITI = 238,
  CORE_SPLITWISE = 240,
  BR_TELCO_VIVO = 241,
  BR_INVOICING_CONTAAZUL = 242,
  BR_PERSONAL_STONE = 243,
  BR_REGULATED_INICIADOR = 244,
  BR_PERSONAL_ONTOP = 245,
  BR_INVESTMENT_CAIXA_PREVIDENCIA = 246,
  BR_PERSONAL_ITAU_CARTOES = 247,
  BR_INVESTMENT_XP_ADVISOR = 248,
  BR_BUSINESS_AILOS_CREDIT_CARD = 249,
  BR_BUSINESS_CORA = 250,
  BR_PERSONAL_WEB3 = 290,
  BR_INSS = 278,
  BR_INVESTMENT_MERCADO_BITCOIN = 282,
  CORE_WISE = 291,
  CORE_PERSONAL_LEMON = 292,
  MX_BUSINESS_SANTANDER = 301,
  MX_BUSINESS_BBVA = 302,

  EU_PERSONAL_N26 = 401,
  EU_PERSONAL_ES_SANTANDER = 402,
  // We define 6xx as the range for regulated connectors, matching the same xx as Pluggy connectors
  // For example Pluggy Nubank is 212, so we define Regulated Nubank as 612 and so on
  // For the ones that don't have a Pluggy connector, we define a new number above 650
  OF_REGULATED_MOCK_BANK = 600,
  OF_REGULATED_ITAU = 601,
  OF_REGULATED_XP = 602,
  OF_REGULATED_BRADESCO = 603,
  OF_REGULATED_MERCADO_PAGO = 606,
  OF_REGULATED_SANTANDER = 608,
  OF_REGULATED_BRADESCO_BUSINESS = 609,
  OF_REGULATED_BB = 611,
  OF_REGULATED_NUBANK = 612,
  OF_REGULATED_BTG_INVESTMENTS = 614,
  OF_REGULATED_CAIXA_BUSINESS = 616,
  OF_REGULATED_ITAU_BUSINESS = 618,
  OF_REGULATED_CAIXA = 619,
  OF_REGULATED_AGORA = 620,
  OF_REGULATED_SANTANDER_BUSINESS = 621,
  OF_REGULATED_SICREDI_BUSINESS = 627,
  OF_REGULATED_SICOOB_BUSINESS = 628,
  OF_REGULATED_SAFRA = 629,
  OF_REGULATED_ITI = 638,
  OF_REGULATED_ITAU_CARTOES = 647,
  OF_REGULATED_PICPAY = 651,
  OF_REGULATED_BMG = 652,
  OF_REGULATED_DIGIO = 653,
  OF_REGULATED_ORIGINAL = 654,
  OF_REGULATED_BTG_BUSINESS = 655,
  OF_REGULATED_NEXT = 656,
  OF_REGULATED_PAN = 657,
  OF_REGULATED_SICOOB_PERSONAL = 658,
  OF_REGULATED_BANRISUL = 659,
  OF_REGULATED_BANRISUL_BUSINESS = 660,
  OF_REGULATED_SICREDI_PERSONAL = 661,
  OF_REGULATED_BB_BUSINESS = 662,
  OF_REGULATED_UNICRED_PERSONAL = 663,
  OF_REGULATED_NUBANK_BUSINESS = 664,
  OF_REGULATED_MERCADO_PAGO_BUSINESS = 665,
  OF_REGULATED_BMG_BUSINESS = 666,
  OF_REGULATED_ORIGINAL_BUSINESS = 667,
  OF_REGULATED_ITAU_CARTOES_BUSINESS = 668,
  OF_REGULATED_ITI_BUSINESS = 669,
  OF_REGULATED_UNICRED_BUSINESS = 670,
  OF_REGULATED_BANCO_NORDESTE_PERSONAL = 671,
  OF_REGULATED_BANCO_NORDESTE_BUSINESS = 672,
  OF_REGULATED_BANCO_BRASIL_INVESTIMENTOS = 673,
  OF_REGULATED_BANCO_UBER = 674,
  OF_REGULATED_BTG_PERSONAL = 675,
  OF_REGULATED_WOOP = 676,
  OF_REGULATED_ION = 677,
  OF_REGULATED_SAFRA_BUSINESS = 697,
  OF_REGULATED_BTG_CORPORATE = 704,
  OF_REGULATED_BANCO_PAULISTA = 705,
  OF_REGULATED_BANCO_PAULISTA_BUSINESS = 706,
  OF_REGULATED_SAFRA_PAY = 708,
  OF_REGULATED_SAFRA_PAY_BUSINESS = 709,
  OF_REGULATED_CITI_BUSINESS = 710,
  OF_REGULATED_SAFRA_FINANCEIRA = 711,
  OF_REGULATED_SAFRA_FINANCEIRA_BUSINESS = 712,
  OF_REGULATED_PICPAY_BUSINESS = 713,

  // Only payments connectors. Eventually this connectors will be used in Open Finance flow
  PAYMENTS_REGULATED_GENIAL_INVESTIMENTOS = 613,
  PAYMENTS_REGULATED_INTER = 615,
  PAYMENTS_REGULATED_INTER_BUSINESS = 625,
  PAYMENTS_REGULATED_C6 = 626,
  PAYMENTS_REGULATED_AGIBANK = 678,
  PAYMENTS_REGULATED_BANCO_DA_AMAZONIA = 679,
  PAYMENTS_REGULATED_BANCO_MASTER = 680,
  PAYMENTS_REGULATED_BANESTES = 681,
  PAYMENTS_REGULATED_BRB = 682,
  PAYMENTS_REGULATED_CREDISIS = 683,
  PAYMENTS_REGULATED_CRESOL = 684,
  PAYMENTS_REGULATED_DAYCOVAL = 685,
  PAYMENTS_REGULATED_EFI = 686,
  PAYMENTS_REGULATED_MEI_FACIL = 687,
  PAYMENTS_REGULATED_MIDWAY = 688,
  PAYMENTS_REGULATED_NEON = 689,
  PAYMENTS_REGULATED_ORBI_BANK = 690,
  PAYMENTS_REGULATED_OURINVEST = 691,
  PAYMENTS_REGULATED_PAGBANK = 692,
  PAYMENTS_REGULATED_PARANA_BANCO = 693,
  PAYMENTS_REGULATED_QI_SCD = 694,
  PAYMENTS_REGULATED_QUERO_QUERO_PAG = 695,
  PAYMENTS_REGULATED_REDE_CELCOIN = 696,
  PAYMENTS_REGULATED_SUPERDIGITAL = 698,
  PAYMENTS_REGULATED_U4C = 699,
  PAYMENTS_REGULATED_ZEMA_FINANCEIRA = 700,
  PAYMENTS_REGULATED_PAN_BUSINESS = 701,
  PAYMENTS_REGULATED_XP_BUSINESS = 702,
  PAYMENTS_REGULATED_SUPERDIGITAL_BUSINESS = 703,
  PAYMENTS_REGULATED_ALFA = 707,
}

export function isSandboxConnector({ isSandbox }: Connector): boolean {
  return isSandbox
}

/**
 * Extract connector primaryColor, but if it's not defined or if
 * it's not readable on the app background, default to the brandColor.
 *
 * @param connector
 */
export function getPrimaryConnectorColor(connector: Connector): string {
  const { primaryColor } = connector

  const brandColor = getComputedStyle(document.body)
    .getPropertyValue('--primary')
    .trim()

  if (!primaryColor) {
    return brandColor
  }

  return !primaryColor || primaryColor.startsWith('#')
    ? primaryColor
    : `#${primaryColor}`
}

/**
 *
 * @param connector
 */
export function hasConnectorAccountFlowOptions(connector: Connector): boolean {
  const accountFlowOptionsConnectorIds = [
    ConnectorId.BR_PERSONAL_BRADESCO,
    ConnectorId.AR_PERSONAL_PLUGGY_BANK_MFA_1_2_STEP_MULTI_ACCOUNTS,
    ConnectorId.BR_PERSONAL_PLUGGY_BANK_MFA_1_2_STEP_MULTI_ACCOUNTS,
  ]
  return accountFlowOptionsConnectorIds.includes(connector.id)
}

export function hasCooperativeSelectionFlow(connector: Connector): boolean {
  const cooperativeSelectionFlowConnectorIds = [
    ConnectorId.BR_PERSONAL_AILOS,
    ConnectorId.BR_BUSINESS_AILOS,
    ConnectorId.BR_PERSONAL_PLUGGY_BANK_COOPERATIVE_SELECTION,
    ConnectorId.BR_BUSINESS_PLUGGY_BANK_COOPERATIVE_SELECTION,
  ]
  return cooperativeSelectionFlowConnectorIds.includes(connector.id)
}

export function hasEmailMfaFlow(
  connector: Connector | null | undefined,
): boolean {
  if (!connector) {
    // no connector -> just return
    return false
  }
  const emailMfaFlowConnectorIds = [
    ConnectorId.BR_PERSONAL_NUBANK,
    ConnectorId.BR_INVESTMENT_B3_CEI,
  ]
  return emailMfaFlowConnectorIds.includes(connector.id)
}

export function isCaixaConnector(connector: Connector): boolean {
  return [
    ConnectorId.BR_PERSONAL_CAIXA,
    ConnectorId.BR_BUSINESS_CAIXA,
  ].includes(connector.id)
}

export function isBBPJConnector(connector: Connector): boolean {
  return connector.id === ConnectorId.BR_BUSINESS_BANCO_DO_BRASIL
}

export function isAilosCreditCardConnector(connector: Connector): boolean {
  return connector.id === ConnectorId.BR_PERSONAL_AILOS_CREDIT_CARD
}

export function isGenialConnector(connector: Connector): boolean {
  return connector.id === ConnectorId.BR_INVESTMENT_GENIAL_INVESTIMENTOS
}

/**
 * Connectors that have Oauth2.0 login flow.
 * TODO: this should be in a new 'oauth: true' field in connector data or something
 *
 * @param {number} id
 * @return {boolean}
 */
export function isOauth2Connector({ id }: Connector): boolean {
  return (
    id === ConnectorId.CORE_SPLITWISE ||
    id === ConnectorId.BR_INVOICING_CONTAAZUL ||
    id === ConnectorId.BR_MEU_PLUGGY
  )
}

export function hasDeviceRegistrationNotificationAlert(
  connector: Connector,
): boolean {
  const deviceRegistrationNotificationConnectorIds = [
    ConnectorId.BR_PERSONAL_BANCO_DO_BRASIL,
    ConnectorId.BR_BUSINESS_BANCO_DO_BRASIL,
    ConnectorId.BR_PERSONAL_NUBANK,
  ]
  return deviceRegistrationNotificationConnectorIds.includes(connector.id)
}

export function isINSSConnector(connector: Connector): boolean {
  return connector.id === ConnectorId.BR_INSS
}

export function hasMultiCompanySelectionMfaFlow(connector: Connector): boolean {
  const multiCompanySelectionMfaFlowConnectorIds = [
    ConnectorId.BR_BUSINESS_PLUGGY_BANK_MFA_2STEP,
    ConnectorId.BR_PERSONAL_PLUGGY_BANK_MFA_2STEP,
    ConnectorId.BR_INVESTMENT_PLUGGY_BANK_MFA_2STEP,
    ConnectorId.BR_BUSINESS_BRADESCO,
    ConnectorId.BR_BUSINESS_BANCO_DO_BRASIL,
  ]

  return multiCompanySelectionMfaFlowConnectorIds.includes(connector.id)
}

/**
 * Helper function to check if a sandbox connector is unique in its type
 * @param connectors - list of current connectors
 * @param connector - connector to check
 * @returns
 */
export function isUniqueSandboxAvailableInType(
  connectors: Connector[],
  connector: Connector,
): boolean {
  const connectorType = connector.type
  const isUniqueSandboxInSpecifiedType =
    connectors.filter(
      (connector_) =>
        connector_.type === connectorType && isSandboxConnector(connector_),
    ).length === 1

  return isUniqueSandboxInSpecifiedType
}

export function normalizeSandboxConnectorNameByLanguage(
  connector: Connector,
): void {
  const connectorNameTranslated = i18n.t(`connectorName.${connector.id}`, {
    defaultValue: connector.name,
  })
  connector.name = connectorNameTranslated
}

export function isQRConnector(connector: Connector): boolean {
  const qrConnectors = [
    ConnectorId.BR_PERSONAL_INTER_QR,
    ConnectorId.BR_PERSONAL_PLUGGY_BANK_QR,
    ConnectorId.BR_BUSINESS_CORA,
    ConnectorId.BR_PERSONAL_BANCO_INTER_PERSONAS,
  ]

  return qrConnectors.includes(connector.id)
}

export function isPersonalSafraConnector(connector: Connector): boolean {
  return connector.id === ConnectorId.BR_PERSONAL_SAFRA
}

export function isWiseConnector(connector: Connector): boolean {
  return connector.id === ConnectorId.CORE_WISE
}

export function isSandbox2StepConnector(connector: Connector): boolean {
  const sandbox2StepConnectorIds = [
    ConnectorId.BR_PERSONAL_PLUGGY_BANK_MFA_2STEP,
    ConnectorId.BR_BUSINESS_PLUGGY_BANK_MFA_2STEP,
    ConnectorId.BR_INVESTMENT_PLUGGY_BANK_MFA_2STEP,
  ]
  return sandbox2StepConnectorIds.includes(connector.id)
}

/**
 * returns a new array with the connectors ordered by the connectorIds given
 */
export function orderConnectorsByConnectorIds(
  connectors: Connector[],
  connectorIds: ConnectorId[],
): Connector[] {
  const connectorsOrdered = [...connectors].sort((a, b) => {
    const aIndex = connectorIds.indexOf(a.id)
    const bIndex = connectorIds.indexOf(b.id)

    // if both connectors are not in the custom order, keep their original order
    if (aIndex === -1 && bIndex === -1) {
      return 0
    }
    // if only connector 'a' is not in the custom order, move it after connector 'b'
    if (aIndex === -1) {
      return 1
    }
    // if only connector 'b' is not in the custom order, move it before connector 'a'
    if (bIndex === -1) {
      return -1
    }

    // if both connectors are in the custom order, sort them based on their index in the custom order
    return aIndex - bIndex
  })

  return connectorsOrdered
}

/**
 * Returns true if the connector has a browser login flow.
 * This is used to open the login flow in the external browser instead of a webview.
 * @param connector
 */
export function hasBrowserLogin(connector: Connector) {
  const connectorsWithBrowserLogin = [
    ConnectorId.OF_REGULATED_MOCK_BANK,
    ConnectorId.OF_REGULATED_BTG_BUSINESS,
    ConnectorId.OF_REGULATED_BTG_PERSONAL,
    ConnectorId.OF_REGULATED_ITI,
    ConnectorId.OF_REGULATED_ITI_BUSINESS,
    ConnectorId.OF_REGULATED_ITAU_CARTOES,
    ConnectorId.OF_REGULATED_ITAU_CARTOES_BUSINESS,
    ConnectorId.OF_REGULATED_UNICRED_BUSINESS,
    ConnectorId.OF_REGULATED_UNICRED_PERSONAL,
    ConnectorId.OF_REGULATED_DIGIO,
    ConnectorId.OF_REGULATED_PAN,
    ConnectorId.OF_REGULATED_SICREDI_BUSINESS,
    ConnectorId.OF_REGULATED_SICREDI_PERSONAL,
    ConnectorId.OF_REGULATED_WOOP,
  ]
  return connectorsWithBrowserLogin.includes(connector.id)
}
