import type { Connector } from 'pluggy-js'

import { ConnectConfig } from '../../lib/authApiClient'
import { getPrimaryConnectorColor } from '../../modules/connector/utils'
import {
  getAppProps,
  isRunningAsZoidComponentInstance,
} from '../../utils/appWrapper'
import {
  calculateButtonTextColorForBackgroundColor,
  calculatePrimaryHoverColor,
  calculateSecondaryColor,
  calculateSecondaryHoverColor,
} from '../../utils/colors'
import { PRIMARY_COLOR_DEFAULT } from '../../utils/customizations'
import { getPluggyConnectSdkVersionIncrement } from '../../utils/utils/version'

const MAX_MOBILE_SCREEN_WIDTH = 500

export function getCustomAppCss(authApiConnectConfig: ConnectConfig): string {
  const {
    styles: {
      borderRadius,
      colors: { primary },
    },
  } = authApiConnectConfig

  const primaryOrDefault = primary ?? PRIMARY_COLOR_DEFAULT

  const { theme } = getAppProps()

  const baseColor = theme === 'dark' ? 'black' : 'white'

  const primaryHoverColor = calculatePrimaryHoverColor(primaryOrDefault)

  const secondaryHoverColor = calculateSecondaryHoverColor(
    primaryOrDefault,
    baseColor,
  )

  const secondaryColor = calculateSecondaryColor(primaryOrDefault, 0.1)

  const primaryButtonTextColor =
    calculateButtonTextColorForBackgroundColor(primaryOrDefault)

  const borderRadiusPxToRemSize =
    borderRadius !== null ? `${borderRadius / 16}` : undefined
  return `
    .Connect .pluggy.ui.button {
      ${
        borderRadiusPxToRemSize
          ? `border-radius: calc(var(--spacing) * ${borderRadiusPxToRemSize});`
          : ''
      }
      --primary-button: ${primaryOrDefault};
      --primary-hover-button: ${primaryHoverColor};
    }

    .Connect .pluggy.ui.secondary.button {
      ${
        borderRadiusPxToRemSize
          ? `border-radius: calc(var(--spacing) * ${borderRadiusPxToRemSize});`
          : ''
      }
      --secondary-text-button: ${secondaryColor};
      --secondary-hover-button: ${secondaryHoverColor};
    }

    .Connect .pluggy.ui.button,
    .Connect .pluggy.ui.secondary.button {
      --secondary-button: transparent;
      --secondary-text-button: ${secondaryColor};
      --secondary-hover-button: ${secondaryHoverColor};
    }

    .Connect .pluggy.ui.button.loading.disabled {
      /* Keep loading+disabled button color to look just like 'loading' */
      --primary-button-disabled: ${primaryOrDefault};
    }

    .Connect .pluggy.ui.button.primary .content {
      color: ${primaryButtonTextColor};
    }
  `
}

export function getCustomConnectorCss(connector: Connector): string {
  const customConnectorColor = getPrimaryConnectorColor(connector)
  const customConnectorHoverColor =
    calculatePrimaryHoverColor(customConnectorColor)

  const { theme } = getAppProps()

  const baseColor = theme === 'dark' ? 'black' : 'white'

  const customConnectorSecondaryHoverColor = calculateSecondaryHoverColor(
    customConnectorColor,
    baseColor,
  )

  const customConnectorSecondaryColor = calculateSecondaryColor(
    customConnectorColor,
    0.1,
  )
  const connectorPrimaryButtonTextColor =
    calculateButtonTextColorForBackgroundColor(customConnectorColor)

  return `
    .Connect .ConnectForm .pluggy.ui.button,
    .Connect .connect-status .pluggy.ui.button,
    .Connect .ErrorStatus .pluggy.ui.button {
       --primary-button: ${customConnectorColor};
       --primary-hover-button: ${customConnectorHoverColor};
    }

    .Connect .ConnectForm .pluggy.ui.secondary.button,
    .Connect .connect-status .pluggy.ui.secondary.button,
    .Connect .ErrorStatus .pluggy.ui.secondary.button {
       --secondary-text-button: ${customConnectorColor};
       --secondary-hover-button: ${customConnectorHoverColor};
    }

    .Connect .ConnectForm .pluggy.ui.button,
    .Connect .connect-status .pluggy.ui.button,
    .Connect .ErrorStatus .pluggy.ui.button {
        --secondary-button: transparent;
        --secondary-text-button: ${customConnectorSecondaryColor};
        --secondary-hover-button: ${customConnectorSecondaryHoverColor};
    }

    .Connect .ConnectForm .pluggy.ui.button.loading.disabled,
    .Connect .connect-status .pluggy.ui.button.loading.disabled {
       /* Keep loading+disabled button color to look just like 'loading' */
       --primary-button-disabled: ${customConnectorColor};
    }

    .Connect .ConnectForm .pluggy.ui.button.primary .content,
    .Connect .connect-status .pluggy.ui.button.primary .content,
    .Connect .ErrorStatus .pluggy.ui.button.primary .content {
        color: ${connectorPrimaryButtonTextColor};
    }

    .Connect .ConnectForm .pluggy.ui.loading.button:after,
    .Connect .connect-status .pluggy.ui.loading.button:after  {
        --primary-hover: ${customConnectorColor};
        --primary: ${customConnectorSecondaryColor};
    }

    .Connect .ConnectForm .pluggy.field {
      --input-focused-border: ${customConnectorColor};
   }

    .Connect .connect-status .icon {
      color: ${customConnectorColor};
    }
    .Connect .connect-status .ui.progress .bar {
      background: ${customConnectorColor};
    }
    .Connect .connect-status .ui.loader:after {
      border-color: ${customConnectorColor} transparent transparent;
    }

    .Connect .ConnectForm .pluggy.Radio {
      --radio-hover-background-color: ${customConnectorSecondaryHoverColor};
      --radio-hover-border-color: ${customConnectorColor};
      --radio-checked-border-color: ${customConnectorColor};
      --radio-checked-dot-color: ${customConnectorColor};
    }
    `
}

/**
 * returns the CSS with the device size factor
 * @param deviceSizeFactor - size factor of the device
 */
function buildDeviceSizeFactorCss(deviceSizeFactor: number): string {
  return `
    :root {
      --spacing: ${deviceSizeFactor}px;
    }
    `
}

// note: this was decided with Agus (1.4rem)
const MAX_SPACING_PX = 22.4

// note: this is the size of the figma design of Connect
const MIN_BASE_DEVICE_WIDTH = 320
// manually calculated by testing with different devices, this is needed for prevent shorter devices to overflow
const MAX_BASE_DEVICE_WIDTH = 360

// base width aspect ratio used is 320px / 568px, which is the default we have for PluggyConnect running in wrapped modal.
// if the device width-to-height ratio is greater than this,
// the components will be scaled down using another base device width
const BASE_WIDTH_ASPECT_RATIO = 320 / 568

/**
 * Helper function to determine if the sdk shows full screen on mobile
 * by checking the sdk version to be greater or equal to 2.2
 * @returns - boolean
 */
function sdkHasMobileFullScreenSupport(): boolean {
  const { sdkVersion } = getAppProps()

  const sdkMajorVersion = getPluggyConnectSdkVersionIncrement(
    sdkVersion,
    'major',
  )
  const sdkMinorVersion = getPluggyConnectSdkVersionIncrement(
    sdkVersion,
    'minor',
  )

  if (sdkMajorVersion === undefined || sdkMinorVersion === undefined) {
    return false
  }

  // sdk version is 2.2 or greater
  return sdkMajorVersion > 2 || (sdkMajorVersion === 2 && sdkMinorVersion >= 2)
}

/**
 * calculate the device size factor and returns the CSS with the device size factor
 * to scale the components
 * @returns {string} - the css with the value of --spacing (device size factor size)
 */
export function getDeviceSizeFactorCss(): string {
  const {
    innerHeight: height,
    innerWidth: width,
    screen: { width: screenWidth },
  } = window

  const { allowFullscreen } = getAppProps()

  const isMobileScreen = screenWidth < MAX_MOBILE_SCREEN_WIDTH

  const isFullScreenWidget =
    isMobileScreen && sdkHasMobileFullScreenSupport() && allowFullscreen

  if (isRunningAsZoidComponentInstance() && !isFullScreenWidget) {
    // we're wrapped as a widget in desktop or in mobile with legacy wrapper, use default spacing
    return ''
  }

  const deviceAspectRatio = width / height

  // shoreter devices needs to be scaled down to prevent overflow
  const deviceHasSmallHeightToWidthAspect =
    deviceAspectRatio > BASE_WIDTH_ASPECT_RATIO

  const baseDeviceWidth = deviceHasSmallHeightToWidthAspect
    ? MAX_BASE_DEVICE_WIDTH
    : MIN_BASE_DEVICE_WIDTH

  // calculate the device factor using width as a reference
  // note: multiplying by 16 to get the size in px
  const deviceScreenFactor = (width / baseDeviceWidth) * 16

  // we don't want to re-scale the components in all device, ie. tablets
  const deviceSizeFactor = Math.min(deviceScreenFactor, MAX_SPACING_PX)

  // is running as standalone app, we need to scale the components
  return buildDeviceSizeFactorCss(deviceSizeFactor)
}
