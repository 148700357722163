import { memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from '@pluggyai/ui'

import { Title } from '../../../../../Title'
import { ConnectorHeader } from '../../../../ConnectorHeader'
import { Props } from './PersonalSafraDeviceAuthorization.types'

import './PersonalSafraDeviceAuthorization.css'

const PersonalSafraDeviceAuthorization = ({
  item,
  onClose,
  onRetry,
}: Props) => {
  const { t } = useTranslation()

  const isDeviceTimeoutExecutionStatus =
    item.executionStatus === 'USER_AUTHORIZATION_PENDING'

  return (
    <div className={'PersonalSafraDeviceAuthorization'}>
      <div className={'content-container'}>
        <Title>
          {t('connectStatus.device-authorization.personal-safra.title')}
        </Title>
        <ConnectorHeader connector={item.connector} />

        <div className={'content'}>
          <b className={'subtitle'}>
            {isDeviceTimeoutExecutionStatus
              ? t(
                  'connectStatus.device-authorization.personal-safra.timeout.title',
                )
              : t(
                  'connectStatus.device-authorization.personal-safra.unauthorized.title',
                )}
          </b>
          <p>
            {isDeviceTimeoutExecutionStatus ? (
              t(
                'connectStatus.device-authorization.personal-safra.timeout.message',
              )
            ) : (
              <Trans
                i18nKey={
                  'connectStatus.device-authorization.personal-safra.unauthorized.message'
                }
              />
            )}
          </p>
        </div>
      </div>
      <div className={'action-container'}>
        <Button secondary onClick={onClose}>
          {t('connectStatus.device-authorization.personal-safra.actions.close')}
        </Button>
        <Button primary onClick={onRetry}>
          {t('connectStatus.device-authorization.personal-safra.actions.retry')}
        </Button>
      </div>
    </div>
  )
}

export default memo(PersonalSafraDeviceAuthorization)
