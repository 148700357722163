import * as Sentry from '@sentry/react'
import { providers } from 'ethers'
import jwtDecode from 'jwt-decode'
import { ConnectEventPayload } from 'pluggy-connect-sdk'
import {
  initialize as pluggyConnectInitialize,
  PluggyConnectPropsExtended,
} from 'pluggy-connect-sdk/dist/main/pluggy-connect'
import {
  AdaptedPluggyConnectProps,
  restorePluggyConnectProps,
} from 'pluggy-connect-sdk/dist/main/utils/props'
import {
  Connector,
  ConnectorType,
  COUNTRY_CODES,
  CountryCode,
  Item,
  PRODUCT_TYPES,
  ProductType,
} from 'pluggy-js'

import { STEPS } from '../components/Connect/Connect'
import {
  addSentryCallbackBreadcrumb,
  CallbackBreadcrumbData,
} from '../lib/sentry'
import { TrackEventName } from '../modules/analytics/events'
import { track } from '../modules/analytics/utils'
import { CurrentPollingState } from '../modules/item/reducer'
import { getPollingDurationInMs } from '../modules/item/utils'
import {
  CustomTheme,
  getClientTheme,
  isSupportedCustomTheme,
} from './customizations'
import {
  clearAllUrlParams,
  clearUrlParam,
  getUrlParam,
  setUrlParam,
} from './location'
import { getPluggyConnectSdkVersionIncrement } from './utils/version'

const WIDGET_CLOSE_DELAY = 600

// The following call is required to enable instantiation of the current application
// as a Zoid component, which will give us the ability to be loaded it inside an iframe on a remote parent site.
// It’ll setup the communication channel for such iframe to the parent site, and vice-versa.
pluggyConnectInitialize()

type ZoidXProps<P> = {
  // see zoid xprops declaration -> https://github.com/krakenjs/zoid/blob/main/docs/api/xprops.md
  /* close the wrapper component */
  close: () => Promise<void>
  /* minimize the wrapper modal */
  hide: () => Promise<void>
  /* retrieve the parent Window reference */
  getParent: () => Window
  /* retrieve the parent domain */
  getParentDomain: () => string
} & P

declare global {
  interface Window {
    // is defined if running inside an iframe with Zoid, either our pluggy-connect-sdk / react-pluggy-connect SDK wrappers
    xprops?: ZoidXProps<AdaptedPluggyConnectProps>

    // is defined if running inside a react-native-webview
    ReactNativeWebView?: {
      postMessage(message: string): void
    }

    // is defined if running inside Flutter InAppWebView
    flutter_inappwebview?: {
      callHandler(handlerName: string, message: string): void
    }

    ethereum?: providers.ExternalProvider
  }
}

/**
 * Custom props, provided by URL only, not via widget wrapper.
 * Used for particular client customizations, or other props
 * we want to handle only internally.
 */
type CustomUrlProps = {
  customTheme?: CustomTheme
}

type PluggyConnectUrlProps = Omit<PluggyConnectPropsExtended, 'sdkVersion'> & {
  sdkVersion?: string
  _runningInCordova?: boolean
}

/**
 * Props provided from the app wrapper, which define the app behavior.
 */
type AppProps = ZoidXProps<PluggyConnectPropsExtended> | PluggyConnectUrlProps

type PluggyConnectMessage = {
  type: 'CONTINUE_IN_BACKGROUND'
}

/**
 * Detect if currently running as a Zoid component instance,
 */
export function isRunningAsZoidComponentInstance(): boolean {
  // if 'xprops' is present then it's being ran as a zoid component
  return !!window.xprops
}

/**
 * Check if current pluggy-connect-sdk version is adapting props,
 * to handle props signature/type accordingly.
 *
 * @return {boolean}
 */
function sdkIsAdaptingProps(sdkVersion: string[]): boolean {
  const versionMajor = getPluggyConnectSdkVersionIncrement(sdkVersion, 'major')
  const versionMinor = getPluggyConnectSdkVersionIncrement(sdkVersion, 'minor')

  return (
    versionMajor !== undefined &&
    versionMinor !== undefined &&
    // since 2.4.0, wrapper is adapting props to avoid collision
    ((versionMajor >= 2 && versionMinor >= 4) || versionMajor > 2)
  )
}

/**
 * If running as Zoid frame (using pluggy-connect-sdk), this method will
 * return xprops object from window.
 * Otherwise, an error will be thrown.
 *
 * @return {ZoidXProps<PluggyConnectPropsExtended>}
 */
export function getZoidXprops(): ZoidXProps<PluggyConnectPropsExtended> {
  const { xprops } = window
  if (!xprops) {
    throw new TypeError(
      'window.xprops is not defined, ensure app is running as zoid component instance first',
    )
  }

  // extract adaptedPluggyConnectProps, to restore them
  const {
    close,
    hide,
    getParent,
    getParentDomain,
    allowFullscreen,
    ...adaptedPluggyConnectProps
  } = xprops

  const { sdkVersion } = adaptedPluggyConnectProps

  let pluggyConnectProps: PluggyConnectPropsExtended

  if (sdkIsAdaptingProps(sdkVersion)) {
    // starting from this version, props are being adapted
    pluggyConnectProps = restorePluggyConnectProps(adaptedPluggyConnectProps)
  } else {
    // previous versions didn't adapt props, so they are kept the same
    pluggyConnectProps = adaptedPluggyConnectProps
  }

  // default allowFullscreen to true if not provided
  const allowFullscreenOrDefault =
    allowFullscreen !== undefined ? allowFullscreen : true

  return {
    close,
    hide,
    getParent,
    getParentDomain,
    allowFullscreen: allowFullscreenOrDefault,
    ...pluggyConnectProps,
  }
}

/**
 * Parse theme from the request to one of the available ones, otherwise undefined.
 */
function parseThemeFromRequest(): CustomTheme | undefined {
  const { connectToken } = getAppProps()
  if (!connectToken) {
    return undefined
  }
  const urlParams = new URLSearchParams(window.location.search)
  const themeParam = (urlParams.get('theme') || '').toUpperCase()
  if (isSupportedCustomTheme(themeParam)) {
    // user provided a valid 'theme' param in the URL, so we use that.
    return themeParam
  }

  let clientId: string
  try {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;({ clientId = '' } = jwtDecode<{ clientId?: string }>(connectToken))
  } catch (error) {
    // failed to decode connectToken as JWT token, probably incomplete or invalid
    return undefined
  }

  const themeValue = getClientTheme(clientId)
  if (isSupportedCustomTheme(themeValue)) {
    // clientId has been parsed correctly from the connectToken
    // and has a custom theme assigned in this project, so we use that.
    return themeValue
  }
  // no valid theme found in the pre-set clientId themes
  return undefined
}

/**
 * Extract valid ConnectorTypes from array-like string parameter, separated by comma.
 */
function parseConnectorTypes(
  connectorTypesParam: string | null,
): ConnectorType[] | undefined {
  if (!connectorTypesParam) {
    return undefined
  }

  const connectorTyes: ConnectorType[] = [
    'PERSONAL_BANK',
    'BUSINESS_BANK',
    'INVESTMENT',
  ]

  return connectorTypesParam
    .replace(/ /g, '')
    .split(',')
    .filter((connectorTypeString): connectorTypeString is ConnectorType =>
      connectorTyes.includes(connectorTypeString as ConnectorType),
    )
}

function parseProducts(
  productsParam: string | null,
): ProductType[] | undefined {
  if (!productsParam) {
    return undefined
  }

  return productsParam
    .replace(/ /g, '')
    .split(',')
    .filter((productTypeString): productTypeString is ProductType =>
      PRODUCT_TYPES.includes(productTypeString as ProductType),
    )
}

function parseConnectorIds(
  connectorIdsParam: string | null,
): number[] | undefined {
  if (!connectorIdsParam) {
    return undefined
  }

  return connectorIdsParam
    .replace(/ /g, '')
    .split(',')
    .map((connectorIdString) => parseInt(connectorIdString, 10))
}

/**
 * Extract valid CountryCodes list from array-like string parameter, separated by comma.
 */
function parseCountries(
  countriesParam: string | null,
): CountryCode[] | undefined {
  if (!countriesParam) {
    return undefined
  }

  return countriesParam
    .replace(/ /g, '')
    .split(',')
    .filter((countryCode): countryCode is CountryCode =>
      COUNTRY_CODES.includes(countryCode as CountryCode),
    )
}

function parseSelectedConnectorId(
  selectedConnectorId: string | null,
): number | undefined {
  if (!selectedConnectorId) {
    return undefined
  }

  const parsedSelectedConnectorId = Number(selectedConnectorId)
  if (Number.isNaN(parsedSelectedConnectorId)) {
    return undefined
  }
  return parsedSelectedConnectorId
}

function getUrlProps(): PluggyConnectUrlProps {
  const urlParams = new URLSearchParams(window.location.search)
  return {
    connectToken: urlParams.get('connect_token') || '',
    includeSandbox: ['true', '1'].includes(
      String(urlParams.get('with_sandbox')),
    ),
    theme: urlParams.get('theme') === 'dark' ? 'dark' : 'light',
    allowConnectInBackground: ['true', '1'].includes(
      String(urlParams.get('allow_connect_in_background')),
    ),
    selectedConnectorId: parseSelectedConnectorId(
      urlParams.get('selected_connector_id'),
    ),
    connectorTypes: parseConnectorTypes(urlParams.get('connector_types')),
    connectorIds: parseConnectorIds(urlParams.get('connector_ids')),
    countries: parseCountries(urlParams.get('countries')),
    language: urlParams.get('language') || urlParams.get('lang') || undefined,
    updateItem: urlParams.get('update_item') || undefined,
    moveSecurityData: urlParams.get('move_security_data') ?? undefined,
    sdkVersion:
      urlParams.get('sdkVersion') || urlParams.get('sdk_version') || undefined,
    allowFullscreen: true, // always allowFullscreen for URL props
    products: parseProducts(urlParams.get('products')),
    _runningInCordova: urlParams.get('running_in_cordova')
      ? urlParams.get('running_in_cordova') === 'true'
      : undefined,
  }
}

export function getInternalUrlProps(): CustomUrlProps {
  return {
    customTheme: parseThemeFromRequest(),
  }
}

export function getAppProps(): AppProps {
  if (isRunningAsZoidComponentInstance()) {
    return getZoidXprops()
  }

  return getUrlProps()
}

type CallbackEvent =
  | { event: 'OPEN' }
  | { event: 'CLOSE' }
  | { event: 'SUCCESS' }
  | { event: 'ERROR' }
  | { event: 'IN_BACKGROUND' }
  | ConnectEventPayload

export type CallbackEventType = CallbackEvent['event']

function notifyLocationChangeToMobileWrapper() {
  // note: sending only to ReactNativeWebView instance, because we're already injecting it to our own Flutter SDK like this as well
  window.ReactNativeWebView?.postMessage(
    JSON.stringify({ type: 'LOCATION', message: window.location.search }),
  )
}

/**
 * send a message to the wrapper to notify it that the app is continuing in background
 * and actually hide it
 */
async function handleWrapperContinueInBackground(): Promise<void> {
  if (!isRunningAsZoidComponentInstance()) {
    // no wrapper, nothing to do
    return
  }
  const { getParent, getParentDomain, hide } = getZoidXprops()

  const parentWindow = getParent()
  const parentDomain = getParentDomain() || ''

  const continueInBackgroundMessage: PluggyConnectMessage = {
    type: 'CONTINUE_IN_BACKGROUND',
  }

  parentWindow.postMessage(
    JSON.stringify(continueInBackgroundMessage),
    parentDomain,
  )

  // actually hide the wrapper
  await hide()
}

function pushTimestampDataToUrl(timestamp: number) {
  setUrlParam('timestamp', timestamp.toString())
}

/**
 * Helper to add a {CallbackEvent} to the 'events' callback URL param
 * @param event
 */
function pushEventParam(event: CallbackEventType, timestamp: number) {
  const eventsParam = getUrlParam('events')
  const newEventsParam = eventsParam
    ? [...eventsParam.split(','), event].join(',')
    : event
  setUrlParam('events', newEventsParam)
  pushTimestampDataToUrl(timestamp)
  notifyLocationChangeToMobileWrapper()
}

export function isRunningAsReactNativeWebView(): boolean {
  return Boolean(window.ReactNativeWebView) && !isRunningAsFlutterWebView()
}

export function isRunningAsFlutterWebView(): boolean {
  return Boolean(window.flutter_inappwebview)
}

export function isRunningAsCordovaWebView(): boolean {
  const { _runningInCordova } = getAppProps()

  if (_runningInCordova) {
    return true
  }
  // not specified via prop, review ancestorOrigins to try detect it (only works in iOS)

  const {
    location: { ancestorOrigins },
  } = document

  const ancestorOriginsArray =
    typeof ancestorOrigins !== 'undefined'
      ? Array.from(ancestorOrigins)
      : undefined

  if (!isRunningAsZoidComponentInstance()) {
    // running as a standalone webview, check if it's a cordova webview in ancestorOrigins
    return Boolean(ancestorOriginsArray?.includes('file://'))
  }
  // running as a zoid component, check if the parent is a cordova webview
  const { getParentDomain } = getZoidXprops()
  const parentDomain = getParentDomain()

  return (
    parentDomain === 'file://' ||
    Boolean(ancestorOriginsArray?.includes('file://'))
  )
}

export function onErrorCallback(error: {
  message: string
  data?: { item: Item }
}): void {
  const { onError } = getAppProps()
  const { message } = error
  const callbackEvent = 'ERROR'
  const urlParam = 'error'
  const breadcrumbData: CallbackBreadcrumbData = {
    callbackEvent,
    urlParam,
    data: error,
  }

  addSentryCallbackBreadcrumb(message, breadcrumbData)
  setUrlParam(urlParam, message)
  pushEventParam(callbackEvent, Date.now())
  ;(onError?.(error) as Promise<void> | undefined)?.catch((error_) =>
    console.error(
      'PluggyConnect onError callback resulted in an error:',
      error_,
    ),
  )
}

export function onSuccessCallback(data: { item: Item }): void {
  const { onSuccess } = getAppProps()
  const callbackEvent: CallbackEventType = 'SUCCESS'

  const breadcrumbData: CallbackBreadcrumbData = {
    callbackEvent,
    data,
  }

  addSentryCallbackBreadcrumb('Success callback', breadcrumbData)

  pushEventParam(callbackEvent, Date.now())
  ;(onSuccess?.(data) as Promise<void> | undefined)?.catch((error) =>
    console.error(
      'PluggyConnect onSuccess callback resulted in an error:',
      error,
    ),
  )
}

export function onOpenCallback(): void {
  const callbackEvent: CallbackEventType = 'OPEN'
  const breadcrumbData: CallbackBreadcrumbData = {
    callbackEvent,
  }

  addSentryCallbackBreadcrumb('Open callback', breadcrumbData)

  pushEventParam(callbackEvent, Date.now())
}

function pushItemDataToUrl(item: Item): void {
  const { executionStatus, id, status } = item

  // TODO: move sentry tags logic into a utils function
  // add item keys as tags
  Sentry.setTags({
    executionStatus,
    itemId: id,
    itemStatus: status,
  })

  // set Item new ids/status as url params

  if (getUrlParam('item_id') !== id) {
    setUrlParam('item_id', id)
  }
  if (getUrlParam('item_status') !== status) {
    setUrlParam('item_status', status)
  }

  if (getUrlParam('execution_status') !== executionStatus) {
    setUrlParam('execution_status', executionStatus)
  }
}

function pushConnectorDataToUrl(connector: Connector | null): void {
  const connectorIdUrlParamValue = getUrlParam('connector_id')
  const connectorNameUrlParamValue = getUrlParam('connector_name')
  const connectorImageUrlUrlParamValue = getUrlParam('connector_image_url')

  if (!connector) {
    // connector has been deselected -> clear keys
    clearUrlParam('connector_id')
    clearUrlParam('connector_name')
    clearUrlParam('connector_image_url')
    return
  }
  const {
    id: connectorId,
    name: connectorName,
    imageUrl: connectorImageUrl,
  } = connector

  // TODO: move sentry tags logic into a utils function
  // add connector keys as tags
  Sentry.setTags({
    connectorId,
    connectorName,
  })

  const connectorIdString = connectorId.toString()
  // set connector new id/name as url params

  if (connectorIdUrlParamValue !== connectorIdString) {
    setUrlParam('connector_id', connectorIdString)
  }
  if (connectorNameUrlParamValue !== connectorName) {
    setUrlParam('connector_name', connectorName)
  }
  if (connectorImageUrlUrlParamValue !== connectorImageUrl) {
    setUrlParam('connector_image_url', connectorImageUrl)
  }
}

export async function onCloseCallback({
  currentStep,
  item,
  selectedConnector,
  currentPolling: { end, start },
}: {
  currentStep: STEPS
  item?: Item
  selectedConnector: Connector | null
  currentPolling: CurrentPollingState
}): Promise<void> {
  const callbackEvent: CallbackEventType = 'CLOSE'
  const breadcrumbData: CallbackBreadcrumbData = {
    callbackEvent,
  }

  addSentryCallbackBreadcrumb('Close callback', breadcrumbData)

  pushEventParam(callbackEvent, Date.now())

  try {
    // wait for all pending Sentry events to be sent
    await Sentry.close(2000)
  } catch (error) {
    console.error('Failed to flush Sentry', error)
  }

  track(
    TrackEventName.CONNECT_WIDGET_CLOSED,
    {
      currentStep,
      itemId: item?.id,
      status: item?.status,
      connectorId: selectedConnector?.id,
      connectorName: selectedConnector?.name,
      pollingDurationInMs: getPollingDurationInMs(start, end),
      pollingStartDate: start,
      pollingEndDate: end,
    },
    undefined,
    async () => {
      if (!isRunningAsZoidComponentInstance()) {
        return
      }
      // explicitly close wrapper
      await getZoidXprops().close()
    },
  )

  if (!isRunningAsZoidComponentInstance()) {
    return
  }

  // close the widget after a delay to allow the widget to close
  // even when the track call fails (e.g. due to tracking blocker)
  // so, if the track call is executed successfully, the callback
  // will be called and the widget will be closed, but if it fails
  // the widget will be closed after a delay
  setTimeout(() => getZoidXprops().close(), WIDGET_CLOSE_DELAY)
}

export async function onContinueInBackgroundCallback(): Promise<void> {
  const callbackEvent: CallbackEventType = 'IN_BACKGROUND'

  const { onHide } = getAppProps()

  // notify to client
  ;(onHide?.() as Promise<void> | undefined)?.catch((error_) =>
    console.error(
      'PluggyConnect onHide callback resulted in an error:',
      error_,
    ),
  )

  pushEventParam(callbackEvent, Date.now())

  // notify pluggy-connect wrapper that the app is continuing in background
  await handleWrapperContinueInBackground()
}

// Custom Omit to make it work with discriminated union type
// https://www.typescriptlang.org/docs/handbook/2/conditional-types.html#distributive-conditional-types
type DistributiveOmit<T, K extends PropertyKey> = T extends unknown
  ? Omit<T, K>
  : never

type EventCallbackPayload = DistributiveOmit<ConnectEventPayload, 'timestamp'>

// Since the SDK has a breaking change on version 2.0.0
// we are maintaining 2 different onEvent API
type OnEventCallbackDeprecated = (
  event: CallbackEventType,
  metadata: { timestamp: number },
) => void | Promise<void>

export function onEventCallback(payload: EventCallbackPayload): void {
  const { onEvent, sdkVersion } = getAppProps()
  const pluggyConnectSdkMajorVersionNumber =
    getPluggyConnectSdkVersionIncrement(sdkVersion, 'major')

  const breadcrumbData: CallbackBreadcrumbData = {
    callbackEvent: payload.event,
    data: payload,
  }

  addSentryCallbackBreadcrumb('Event callback', breadcrumbData)
  if (payload.event === 'SELECTED_INSTITUTION') {
    pushConnectorDataToUrl(payload.connector)
  }

  if (payload.event === 'ITEM_RESPONSE') {
    pushItemDataToUrl(payload.item)
    pushConnectorDataToUrl(payload.item.connector)
  }
  const timestamp = Date.now()

  pushEventParam(payload.event, timestamp)

  const eventPayload = {
    ...(typeof payload !== 'undefined' ? payload : {}),
    timestamp,
  } as ConnectEventPayload

  if (
    pluggyConnectSdkMajorVersionNumber === undefined ||
    pluggyConnectSdkMajorVersionNumber < 2
  ) {
    const onEventCallbackDeprecated =
      // we should check if the onEvent callback is definied before casting
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      typeof onEvent !== 'undefined'
        ? (onEvent as unknown as OnEventCallbackDeprecated)
        : undefined
    // note: this is the old onEvent API from pluggy-connect-sdk version < 2
    ;(
      onEventCallbackDeprecated?.(payload.event, {
        timestamp,
      }) as Promise<void> | undefined
    )?.catch((error) => {
      console.error(
        'PluggyConnect onEvent callback resulted in an error:',
        error,
      )
    })

    return
  }

  // current API
  ;(onEvent?.(eventPayload) as Promise<void> | undefined)?.catch((error) =>
    console.error(
      'PluggyConnect onEvent callback resulted in an error:',
      error,
    ),
  )
}

// clear callback URL params (on page load) that were coming from a previous session
clearAllUrlParams()
