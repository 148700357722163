import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory, History } from 'history'
import { AnyAction, applyMiddleware, compose, createStore, Store } from 'redux'
import { createLogger } from 'redux-logger'
import createSagasMiddleware from 'redux-saga'

import { sentryReduxEnhancer } from '../lib/sentry'
import { isRunningAsZoidComponentInstance } from '../utils/appWrapper'
import { checkAuthRequest } from './auth/actions'
import { fetchConfigRequest } from './config/actions'
import { createRootReducer, RootState } from './reducer'
import { rootSaga } from './sagas'

type AnyWindow = Window &
  typeof globalThis & {
    // ignore eslint warning because 'any' type has been defined by Redux
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: (_: Record<string, unknown>) => any
    getState?: unknown
  }

export function createStoreAndHistory(): {
  store: Store<RootState, AnyAction>
  history: History<unknown>
} {
  const isDev = process.env.NODE_ENV === 'development'

  const anyWindow = window as AnyWindow

  const composeEnhancers =
    isDev && anyWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? anyWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose

  const history = createBrowserHistory()
  const rootReducer = createRootReducer(history)

  const sagasMiddleware = createSagasMiddleware()
  const loggerMiddleware = createLogger({
    collapsed: () => true,
    titleFormatter(
      // 'no-explicit-any' rule disabled because external package 'redux'
      // sets  formattedAction as any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      formattedAction: any,
      _formattedTime: string,
      _took: number,
    ): string {
      if (isRunningAsZoidComponentInstance()) {
        return `>> ${formattedAction.type}`
      }
      return formattedAction.type
    },
    predicate: (_: unknown, action) => isDev || action.type.includes('Failure'),
  })

  const middleware = applyMiddleware(
    sagasMiddleware,
    routerMiddleware(history),
    loggerMiddleware,
  )
  const enhancer = composeEnhancers(middleware, sentryReduxEnhancer)
  const store = createStore(rootReducer, enhancer)

  sagasMiddleware.run(rootSaga)

  if (isDev) {
    const _window = window as AnyWindow
    _window.getState = store.getState
  }

  // dispatch initial token auth validation request
  store.dispatch(checkAuthRequest())
  // dispatch initial Config request
  store.dispatch(fetchConfigRequest())

  return { store, history }
}
