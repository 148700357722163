import React from 'react'
import { useTranslation } from 'react-i18next'

import { usePrevious } from '../../../../utils/hooks/usePrevious'
import { Title } from '../../Title'
import { MissingBankForm } from './MissingBankForm'
import { Props } from './MissingBankPage.types'
import { MissingBankSuccess } from './MissingBankSuccess'

import './MissingBankPage.css'

const MissingBankPage = ({
  onSubmit,
  isLoading,
  error,
  onGoBack,
  connectors,
  onNext,
  displayInstitutionLogos,
  availableConnectors,
}: Props) => {
  const { t } = useTranslation()
  const wasLoading = usePrevious(isLoading)
  const stoppedLoading = !isLoading && wasLoading
  const isSuccess = stoppedLoading && !error

  return (
    <div className={'MissingBankPage'}>
      <Title>{t('missingBank.form.title')}</Title>
      {!isSuccess ? (
        <MissingBankForm
          onSubmit={onSubmit}
          error={error}
          isLoading={isLoading}
          connectors={connectors}
          availableConnectors={availableConnectors}
          onNext={onNext}
          displayInstitutionLogos={displayInstitutionLogos}
        />
      ) : (
        <MissingBankSuccess onGoBack={onGoBack} />
      )}
    </div>
  )
}

export default React.memo(MissingBankPage)
