import { useRef, useCallback, useState, useEffect } from 'react'

import { DashIcon } from './DashIcon'
import { PlusIcon } from './PlusIcon/'

import './FAQOption.css'

const FAQOption = ({
  question,
  answer,
  className,
}: {
  question: string
  answer: React.ReactNode
  className?: string
}) => {
  const [show, setShow] = useState(false)

  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!contentRef.current) {
      return
    }
    const height = contentRef.current.scrollHeight

    if (show) {
      contentRef.current.style.maxHeight = `${height}px`
    } else {
      contentRef.current.style.maxHeight = '0px'
    }
  }, [show])

  const toggleAccordion = useCallback(() => {
    setShow((prev) => !prev)
  }, [])

  return (
    <div className={`FAQOption ${className || ''}`}>
      <button className={'questionSection'} onClick={toggleAccordion}>
        <div className={'question'}>
          <h4 className={'question-title'}>{question}</h4>
          {show ? (
            <DashIcon className={'icon'} />
          ) : (
            <PlusIcon className={'icon'} />
          )}
        </div>
        <div ref={contentRef} className={'answer'}>
          {answer}
        </div>
      </button>
    </div>
  )
}

export default FAQOption
