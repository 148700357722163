import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert, Button } from '@pluggyai/ui'

import './OauthTimeoutError.css'

const OauthTimeoutError = ({ onTryAgain }: { onTryAgain: () => void }) => {
  const { t } = useTranslation()

  return (
    <div className={'OauthTimeoutError'}>
      <Alert
        type={'error'}
        message={t('connectorForm.error.oauth_parameter_expired')}
      />

      <Button primary onClick={onTryAgain}>
        {t('connectStatus.action.retry')}
      </Button>
    </div>
  )
}

export default memo(OauthTimeoutError)
