import { connect } from 'react-redux'

import type { Connector, Parameters } from 'pluggy-js'

import {
  getError as getAuthError,
  isAuthorizing,
} from '../../modules/auth/selectors'
import {
  getCurrentCustomization,
  getConnectApplicationEnvironment,
  isDemoConnectApplication,
  getServerDateDelta,
} from '../../modules/config/selectors'
import {
  connectorSelect,
  FETCH_CONNECTORS_REQUEST,
} from '../../modules/connector/actions'
import {
  getConnectorsFilteredByConfig,
  getLoading,
  getSelectedConnector,
} from '../../modules/connector/selectors'
import {
  clearConnectItem,
  clearConnectItemError,
  FETCH_ITEM_REQUEST,
  updateItemRequest,
} from '../../modules/item/actions'
import {
  getCurrentPolling,
  getError as getItemError,
  getState as getItemState,
} from '../../modules/item/selectors'
import { isLoadingType } from '../../modules/loading/selectors'
import { RootState } from '../../modules/reducer'
import { getAppProps } from '../../utils/appWrapper'
import Connect from './Connect'
import { MapDispatch, MapDispatchProps, MapStateProps } from './Connect.types'

const mapState = (state: RootState): MapStateProps => {
  const {
    data: { item, lastItem },
    loading: itemLoadingState,
  } = getItemState(state)

  const { updateItem: updateItemId, allowConnectInBackground } = getAppProps()

  const isUpdate = Boolean(updateItemId)
  const itemToUpdate = updateItemId
    ? lastItem?.id === updateItemId
      ? lastItem
      : item
    : undefined

  return {
    isAuthorizing: isAuthorizing(state),
    authError: getAuthError(state),
    itemError: getItemError(state),
    connectors: getConnectorsFilteredByConfig(state),
    selectedConnector: getSelectedConnector(state),
    item: (isUpdate ? itemToUpdate : lastItem) || undefined,
    isUpdate,
    backgroundConnectionAllowed: Boolean(allowConnectInBackground),
    isItemLoading: isLoadingType(itemLoadingState, FETCH_ITEM_REQUEST),
    isConnectorsLoading: isLoadingType(
      getLoading(state),
      FETCH_CONNECTORS_REQUEST,
    ),
    customization: getCurrentCustomization(state),
    isDemoApplication: isDemoConnectApplication(state),
    connectApplicationEnvironment: getConnectApplicationEnvironment(state),
    currentPolling: getCurrentPolling(state),
    serverDateDeltaInMs: getServerDateDelta(state),
  }
}

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onClearConnectItem: () => dispatch(clearConnectItem()),
  onClearConnectItemError: (itemId: string) =>
    dispatch(clearConnectItemError(itemId)),
  onUpdateItem: (itemId: string, parameters?: Parameters) =>
    dispatch(updateItemRequest(itemId, parameters)),
  onConnectorSelect: (connector: Connector | null) =>
    dispatch(connectorSelect(connector)),
})

export default connect(mapState, mapDispatch)(Connect)
