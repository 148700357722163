import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@pluggyai/ui'

import { formatMilisecondsToTimer } from '../../../../../../utils/date'
import { Props } from './MfaRemainingTimeMessage.types'

import './MfaRemainingTimeMessage.css'

export const MFA_ALMOST_TIMEOUT_MS = 2000 // 2 seconds

const MfaRemainingTimeMessage = ({
  remainingTime,
  isSandbox,
  isLoading,
  shouldShowMfaRemainingTime,
  onSimuleTimeoutClick,
  onTryAgainSubmit,
}: Props) => {
  const { t } = useTranslation()

  return (
    <div className={'MfaRemainingTimeMessage'}>
      <p className={'mfa-remaining-message'}>
        {shouldShowMfaRemainingTime
          ? t('connectorForm.mfa.remainingTime')
          : t('connectorForm.mfa.timeout')}
        {shouldShowMfaRemainingTime && (
          <span className={'mfa-timer'}>
            {formatMilisecondsToTimer(remainingTime)}
          </span>
        )}
        {/* TODO: this 'Simule timeout' action should be styled as a footer
               according to Figma design, but to work we
               need to migrate also the footer from parent Connect component here.
               https://pluggy.atlassian.net/browse/DVT-395 */}
        {isSandbox &&
          Math.floor(remainingTime / 1000) >
            Math.floor(MFA_ALMOST_TIMEOUT_MS / 1000) && (
            <div className={'action-link-container'}>
              <Button link onClick={onSimuleTimeoutClick} type="button">
                {t('connectorForm.simule-timeout')}
              </Button>
            </div>
          )}
      </p>

      {remainingTime <= 0 && (
        <Button
          link
          disabled={isLoading}
          onClick={isLoading ? undefined : onTryAgainSubmit}
          className={'remaining-time-button'}
        >
          {t('connectorForm.mfa.try-again')}
        </Button>
      )}
    </div>
  )
}

export default React.memo(MfaRemainingTimeMessage)
