import * as React from 'react'

import { ConnectorRow, ConnectorRowProps } from '@pluggyai/ui'

import './ConnectorHeader.css'

type ConnectorHeaderProps = {
  connector: ConnectorRowProps['connector']
  withImage?: boolean
}

const ConnectorHeaderComponent = (props: ConnectorHeaderProps) => {
  const { connector, withImage = true } = props

  return (
    <div className={'pluggy connector-header'}>
      <ConnectorRow connector={connector} withImage={withImage} />
    </div>
  )
}

export default React.memo(ConnectorHeaderComponent)
