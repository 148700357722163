import { AxiosError, isAxiosError } from 'axios'
import type { Item } from 'pluggy-js'
import { UAParser } from 'ua-parser-js'

import { EnvironmentType } from '../../lib/authApiClient'
import { TrackEventName } from '../../modules/analytics/events'
import { track } from '../../modules/analytics/utils'
import { parseConnectToken } from '../../modules/auth/utils'
import { isExecutionErrorFinalStatus } from '../../modules/item/types'
import {
  isAilosCreditCardMaxDeviceLimitReachedError,
  isCaixaAuthorizedDevicesLimitReachedError,
} from '../../modules/item/utils'
import {
  getAppProps,
  getZoidXprops,
  isRunningAsCordovaWebView,
  isRunningAsFlutterWebView,
  isRunningAsReactNativeWebView,
  isRunningAsZoidComponentInstance,
} from '../../utils/appWrapper'
import { Customization } from '../../utils/customizations'
import { STEPS } from './Connect'
import type { ItemError } from './Connect.types'
import { MfaStage } from './Steps/ConnectForm/ConnectForm.types'
import { isUserAccountError } from './Steps/ConnectForm/utils'

const {
  REACT_APP_VERCEL_ENV: vercelEnvironment = '', // preview | production | development
  REACT_APP_VERCEL_URL: vercelUrl = '', // current URL of the deployment
  REACT_APP_VERCEL_GIT_COMMIT_REF: vercelGitCommitRef = '', // current git branch name
  REACT_APP_VERCEL_GIT_COMMIT_SHA: vercelGitCommitHash = '', // current git commit sha
} = process.env

/**
 * Helper to retrieve all application context data & send it to Segment
 */
export function trackApplicationLoadedContext(
  customization: Customization,
  connectApplicationEnvironment: EnvironmentType | null,
): void {
  const {
    navigator: { userAgent },
  } = window

  const parser = new UAParser(userAgent)
  // get data from the user agent
  const { browser, device, os } = parser.getResult()

  // app props, except sensitive information
  const appPropsFiltered = { ...getAppProps() }
  delete (appPropsFiltered as { connectToken?: string }).connectToken

  const { updateItem } = appPropsFiltered

  // extract connectToken 'clientId' & 'options'
  let clientId: string | undefined
  let connectTokenOptions: Record<string, unknown> | undefined
  try {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;({ clientId, options: connectTokenOptions } = parseConnectToken())
  } catch {
    // missing or invalid connectToken prop -> just proceed with empty values
  }

  // track URL from parent document, useful when loaded inside an iframe
  const {
    referrer,
    location: { ancestorOrigins },
  } = document

  const ancestorOriginsArray =
    typeof ancestorOrigins !== 'undefined'
      ? Array.from(ancestorOrigins)
      : undefined

  // track relevant customization values
  const {
    companyName,
    cssClassName,
    displayInstitutionLogos,
    connectorsFilters,
  } = customization

  const isRunningAsModal = isRunningAsZoidComponentInstance()
  const isRunningAsReactNativeWebView_ = isRunningAsReactNativeWebView()
  const isRunningAsFlutterWebView_ = isRunningAsFlutterWebView()
  const isRunningAsCordovaWebView_ = isRunningAsCordovaWebView()
  let runningAs:
    | 'modal'
    | 'ReactNative WebView'
    | 'Flutter WebView'
    | 'Cordova WebView'
    | 'other'
  if (isRunningAsModal) {
    runningAs = 'modal'
  } else if (isRunningAsReactNativeWebView_) {
    runningAs = 'ReactNative WebView'
  } else if (isRunningAsFlutterWebView_) {
    runningAs = 'Flutter WebView'
  } else if (isRunningAsCordovaWebView_) {
    runningAs = 'Cordova WebView'
  } else {
    runningAs = 'other'
  }

  // send useful information about the user device, widget version, props, and config
  track(TrackEventName.CONNECT_WIDGET_LOADED, {
    isUpdate: Boolean(updateItem),
    browser,
    device,
    os,
    clientId,
    connectTokenOptions,
    appProps: appPropsFiltered,
    // track if loaded inside our own iframe in a modal (pluggy-connect wrapper), or from a mobile webview
    isRunningAsModal,
    isRunningAsReactNativeWebView: isRunningAsReactNativeWebView_,
    isRunningAsFlutterWebView: isRunningAsFlutterWebView_,
    isRunningAsCordovaWebView: isRunningAsCordovaWebView_,
    runningAs,
    connectApplicationEnvironment,
    customization: {
      companyName,
      cssClassName,
      displayInstitutionLogos,
      connectorsFilters,
    },
    parent: {
      referrer,
      ancestorOrigins: ancestorOriginsArray,
    },
    context: {
      vercelEnvironment,
      vercelUrl,
      vercelGitCommitRef,
      vercelGitCommitHash,
    },
  })
}

export function isRunningInDashboardApplication(): boolean {
  if (!isRunningAsZoidComponentInstance()) {
    return false
  }

  const { getParentDomain } = getZoidXprops()

  const parentDomain = getParentDomain()

  return parentDomain.includes('dashboard.pluggy')
}

/**
 * helper function to track the login success event in Segment
 * @param isUpdate
 * @param mfaStage
 * @param item
 */
export function trackLoginStepSuccess(
  item: Item,
  isUpdate: boolean,
  mfaStage?: MfaStage,
): void {
  const {
    connector: {
      name: connectorName,
      id: connectorId,
      credentials: connectorCredentials,
    },
    id: itemId,
  } = item

  const connectorCredentialsName = connectorCredentials.map(
    (credential) => credential.name,
  )

  track(TrackEventName.LOGIN_STEP_SUCCESS, {
    itemId,
    connectorName,
    connectorId,
    connectorCredentialsName,
    isUpdate,
    mfaStage,
  })
}

export function getTermsAndConditionsTitleKey({
  step,
}: {
  step: number
}): string {
  if (step === STEPS.TermsPage) {
    return 'termsPage.header'
  }
  if (step === STEPS.PrivacyPolicyPage) {
    return 'privacyPolicyPage.header'
  }
  throw new Error(
    `Step should be TermsPage or PrivacyPolicyPage, but got: ${step}`,
  )
}

/**
 * Helper function to get the error if it's an axios error
 */
function getItemAxiosError(itemError: ItemError): AxiosError<unknown> | null {
  return itemError && isAxiosError(itemError) ? itemError : null
}

/**
 * Error is unexpected (service unavailable) with code 5xx
 */
function isUnexpectedPluggyError(itemError: ItemError): boolean {
  const itemAxiosError = getItemAxiosError(itemError)

  return (
    itemAxiosError?.response?.status !== undefined &&
    itemAxiosError.response.status >= 500
  )
}

/**
 * Not found error with code 404
 */
function isItemNotFoundPluggyApiError(itemError: ItemError): boolean {
  const itemAxiosError = getItemAxiosError(itemError)

  return (
    itemAxiosError?.response?.status !== undefined &&
    itemAxiosError.response.status === 404
  )
}

/**
 * Error is network error or no response
 */
function isItemErrorAxiosNetworkError(itemError: ItemError): boolean {
  const itemAxiosError = getItemAxiosError(itemError)

  return !itemAxiosError?.response || itemAxiosError.message === 'Network Error'
}

/**
 * Connect token is invalid with code 403 (unauthorized)
 */
function isItemUnauthorizedPluggyApiError(itemError: ItemError): boolean {
  const itemAxiosError = getItemAxiosError(itemError)

  return (
    itemAxiosError?.response?.status !== undefined &&
    itemAxiosError.response.status === 403
  )
}

/**
 * Item is in a final error state and the error is not related to the user account,
 * or is a special flow (like Ailos credit card)
 * @param item - current item
 * @param itemError - item error in state
 * @returns - boolean
 */
export function isItemInFinalErrorState(
  item: Item,
  itemError: ItemError,
): boolean {
  // adding { code: null } in case that itemError object is null
  const { code: itemErrorCode } = item.error || { code: null }

  // Error is not related to an user error or user-account error. ie. ACCOUNT_LOCKED, INVALID_CREDENTIALS, etc
  const isErrorComingFromApiOrInstitution =
    itemErrorCode && !isUserAccountError(itemErrorCode)

  return (
    isExecutionErrorFinalStatus(item) ||
    isUnexpectedPluggyError(itemError) ||
    isErrorComingFromApiOrInstitution ||
    isAilosCreditCardMaxDeviceLimitReachedError(item) ||
    isCaixaAuthorizedDevicesLimitReachedError(item)
  )
}

/**
 * Helper function to check if the error is an API error ie. 5xx, 404, 403
 * @param itemError - item error in state
 */
export function isUnexpectedApiOrNetworkItemError(
  itemError: ItemError,
): boolean {
  if (itemError === null) {
    // no error -> just return false
    return false
  }

  return (
    isItemErrorAxiosNetworkError(itemError) ||
    isUnexpectedPluggyError(itemError) ||
    isItemNotFoundPluggyApiError(itemError) ||
    isItemUnauthorizedPluggyApiError(itemError)
  )
}
