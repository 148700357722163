import React, { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Alert, Button } from '@pluggyai/ui'

import { TrackEventName } from '../../../../../../../modules/analytics/events'
import { track } from '../../../../../../../modules/analytics/utils'
import { CaixaQrCodes } from '../CaixaQrCodes'
import { Props } from './CaixaDeviceUnauthorizedStatus.types'

import './CaixaDeviceUnauthorizedStatus.css'

const CaixaDeviceUnauthorizedStatus = ({
  deviceNickname,
  qrCodes,
  onDeviceAuthorizedClick,
}: Props) => {
  const { t } = useTranslation()

  // TODO: review how to improve this so that the top "go back" button lets us
  //  navigate between steps (instead of going back to the previous Connect step)
  const [step, setStep] = useState<'1' | '2'>('1')

  const continueActionI18nKey = `connectStatus.device-unauthorized.caixa.step-${step}.action.continue`

  const continueActionText = t(continueActionI18nKey)

  const handleContinueActionClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      text: continueActionText,
      i18nKey: continueActionI18nKey,
      step,
    })

    if (step === '1') {
      // go to next step
      setStep('2')
      return
    }
    // step === '2' (last step)
    onDeviceAuthorizedClick()
  }, [continueActionText, continueActionI18nKey, step, onDeviceAuthorizedClick])

  return (
    <div className={'CaixaDeviceUnauthorizedStatus'}>
      <div className={'device-authorization-message-container'}>
        <p className="title">
          {t(`connectStatus.device-unauthorized.caixa.step-${step}.header`)}
        </p>
        {step === '1' && (
          <Alert
            size={'small'}
            message={t('connectStatus.device-unauthorized.caixa.step-1.alert')}
            type={'warning'}
          />
        )}
        <p className={'device-authorization-message'}>
          {step === '1' && (
            <Trans
              i18nKey={`connectStatus.device-unauthorized.caixa.step-${step}.message`}
              values={{
                deviceNickname,
              }}
            />
          )}
          {step === '2' && qrCodes && (
            <div>
              <p>
                {`No seu aplicativo Caixa, acesse "Gerenciamento de Dispositivos"
                e autorize o dispositivo '${deviceNickname}'. Logo após isso, escaneie o QR code abaixo.`}
              </p>
              <CaixaQrCodes qrCodes={qrCodes} />
            </div>
          )}
        </p>
      </div>
      <div className="action-container">
        <Button primary onClick={handleContinueActionClick}>
          {continueActionText}
        </Button>
      </div>
    </div>
  )
}

export default React.memo(CaixaDeviceUnauthorizedStatus)
