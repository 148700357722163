import React from 'react'

import { Header } from 'semantic-ui-react'

import { STEPS } from '../Connect'
import { BackgroundIcons } from './BackgroundIcons'
import { BackgroundIconsCollapsed } from './BackgroundIconsCollapsed'
import { CloseIcon } from './CloseIcon'
import { Props } from './ConnectHeader.types'
import { GoBackIcon } from './GoBackIcon'
import { Logo } from './Logo'
import { stepToCssClassName } from './utils'

import './ConnectHeader.css'

const ConnectHeader = ({
  step,
  isUpdate,
  onGoBack,
  companyName,
  logo,
  logoBackground,
  logoBackgroundCollapsed,
  selectedConnector,
  onClose,
  isCloseConfirmationModal,
  isAuthError,
  isWelcomeStepSkipped,
  isWelcomeStepLogoHidden,
  hasPreselectedConnectorId,
}: Props) => {
  // list of steps that should have the header collapsed
  const shouldStepHaveHeaderCollapsed = STEPS.Welcome !== step

  const isCollapsed =
    shouldStepHaveHeaderCollapsed || isCloseConfirmationModal || isAuthError

  const classNames = ['ConnectHeader']
  if (isCollapsed) {
    classNames.push('collapsed')
  }

  if ([STEPS.ConnectSuccess, STEPS.ConnectError].includes(step)) {
    classNames.push('connector-status')
  }

  if (isCloseConfirmationModal) {
    classNames.push('close-confirmation')
  }

  if (isWelcomeStepLogoHidden) {
    classNames.push('logo-hidden')
  }

  classNames.push(stepToCssClassName(step))

  const headerClassName = classNames.join(' ')

  return (
    <Header className={headerClassName}>
      {!isCloseConfirmationModal && <CloseIcon onClick={onClose} />}
      <div className="header-column">
        {selectedConnector || isCollapsed ? (
          <BackgroundIconsCollapsed
            src={logoBackgroundCollapsed}
            companyName={companyName}
          />
        ) : (
          <>
            {!isWelcomeStepLogoHidden && (
              <Logo src={logo} companyName={companyName} />
            )}
            {logoBackground && (
              <BackgroundIcons src={logoBackground} companyName={companyName} />
            )}
          </>
        )}
      </div>
      {((STEPS.ConnectorsList === step && !isWelcomeStepSkipped) ||
        (STEPS.ConnectorForm === step &&
          !isUpdate &&
          !hasPreselectedConnectorId) ||
        STEPS.TermsPage === step ||
        STEPS.PrivacyPolicyPage === step ||
        STEPS.ConnectError === step ||
        STEPS.MissingBankPage === step ||
        STEPS.SandboxSelectionList === step ||
        STEPS.OpenFinanceFAQ === step ||
        isCloseConfirmationModal) && <GoBackIcon onClick={onGoBack} />}
    </Header>
  )
}

export default React.memo(ConnectHeader)
