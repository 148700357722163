import { AnyAction } from 'redux'

import { removeLast, getType, getStatus } from './utils'

export type LoadingState = AnyAction[]

const INITIAL_STATE: LoadingState = []

export function loadingReducer(
  state: LoadingState = INITIAL_STATE,
  action: AnyAction,
): LoadingState {
  const type = getType(action)
  const status = getStatus(action) // REQUEST, SUCCESS, FAILURE ; START, STOP

  switch (status) {
    case 'START':
    case 'REQUEST': {
      return [...state, action]
    }
    case 'STOP':
    case 'FAILURE':
    case 'SUCCESS': {
      return removeLast(state, (actionItem) => getType(actionItem) === type)
    }
    default:
      return state
  }
}
