import { useTranslation } from 'react-i18next'

import './QRActionTimeout.css'

const QRActionTimeout = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div className={'QRActionTimeout'}>
      <p>{t('connectorForm.qr-flow-instructions.timeout')}</p>
    </div>
  )
}

export default QRActionTimeout
