import { Connector } from 'pluggy-js'

import { ConnectorId } from '../../../../../../../modules/connector/utils'

export function getHelpLinkByConnector(
  connector: Connector,
): { href: string; i18nKey: string } | null {
  if (connector.id === ConnectorId.BR_BUSINESS_BANCO_DO_BRASIL) {
    return {
      href: 'https://docs.pluggy.ai/docs/tutorial-banco-do-brasil-empresas-autorizacion-dispositivo',
      i18nKey: 'connectStatus.device-unauthorized.default.help-link',
    }
  }

  return null
}
