import { setContext, setTag } from '@sentry/react'
import { AxiosResponse } from 'axios'
import { all, call, put, takeEvery } from 'redux-saga/effects'

import {
  AuthApiClient,
  ConnectConfig as ApiConnectConfig,
} from '../../lib/authApiClient'
import { getPluggyClient } from '../../lib/pluggyClient'
import { getAppProps } from '../../utils/appWrapper'
import { calculateServerDateDiff } from '../../utils/utils/time'
import { addConnectConfigToSegmentContext } from '../analytics/utils'
import { identifyClientUserId } from '../auth/sagas'
import { ConnectTokenPayload } from '../auth/types'
import { parseConnectToken } from '../auth/utils'
import {
  FETCH_CONFIG_REQUEST,
  fetchConfigFailure,
  fetchConfigSuccess,
} from './actions'
import { ConnectConfig } from './types'

const { REACT_APP_PLUGGY_AUTH_API_URL: authApiUrl } = process.env

if (!authApiUrl) {
  throw new Error('Missing environment variable REACT_APP_PLUGGY_AUTH_API_URL')
}

/**
 * Helper function to retrieve ConnectConfig from auth-api,
 * together with the server date diff.
 *
 * Also retrieve connectTokenPayload from pluggy-api and merge it with
 * connectConfig.teamId to further identify the user.
 * We do it here to be able to still call identify() even if /connect/config request failed.
 *
 * @param {string} connectToken
 * @return {Promise<ConnectConfig>}
 */
async function retrieveExpandedAuthApiConnectConfig(
  connectToken: string,
): Promise<ConnectConfig> {
  const authApiClient = new AuthApiClient(connectToken)

  // retrieve all 3 resources in parallel to optimize network wait times
  const [
    authApiConnectConfigResult,
    serverDateDiffResult,
    connectTokenPayloadResult,
  ]: [
    PromiseSettledResult<AxiosResponse<ApiConnectConfig>>,
    PromiseSettledResult<number | null>,
    PromiseSettledResult<
      AxiosResponse<
        Pick<ConnectTokenPayload, 'clientUserId' | 'connectTokenId'>
      >
    >,
  ] = await Promise.allSettled([
    authApiClient.getConnectConfig(),
    calculateServerDateDiff(),
    getPluggyClient().fetchConnectTokenPayload(),
  ])

  if (
    connectTokenPayloadResult.status === 'fulfilled' &&
    authApiConnectConfigResult.status === 'fulfilled'
  ) {
    const connectTokenDecoded = parseConnectToken()

    // combine payload result with decoded token to gather all data (ie. clientId not included in payload response)
    const connectTokenPayload: Partial<ConnectTokenPayload> = {
      ...connectTokenDecoded,
      ...connectTokenPayloadResult.value.data,
    }

    // could GET /connect_token and GET /connect/config -> call expanded identify(), even if we fail & throw error afterwards
    identifyClientUserId(
      connectTokenPayload,
      authApiConnectConfigResult.value.data,
    )
  }

  if (authApiConnectConfigResult.status === 'rejected') {
    // GET /connect/config failed -> fail operation
    throw authApiConnectConfigResult.reason
  }

  // GET /connect/config succeed -> return ConnectConfig value
  const serverDateDeltaInMs =
    serverDateDiffResult.status === 'rejected'
      ? null
      : serverDateDiffResult.value

  return {
    ...authApiConnectConfigResult.value.data,
    serverDateDeltaInMs,
  }
}

function* handleFetchConfigRequest() {
  const { connectToken } = getAppProps()

  try {
    if (!connectToken) {
      throw new Error('Missing Connect Token')
    }

    const connectConfig: ConnectConfig = yield call(() =>
      retrieveExpandedAuthApiConnectConfig(connectToken),
    )

    yield put(fetchConfigSuccess(connectConfig))
    // add to Sentry context
    setContext('Connect Config', connectConfig)
    setTag('application.environment', connectConfig.environment)

    // add to Segment context
    addConnectConfigToSegmentContext(connectConfig)
  } catch (error) {
    yield put(fetchConfigFailure(error.message))
  }
}

export function* configSaga() {
  yield all([takeEvery(FETCH_CONFIG_REQUEST, handleFetchConfigRequest)])
}
