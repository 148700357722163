import { setTag } from '@sentry/react'

export function calculateItemCreateQuotaExceeded(
  totalItems: number,
  itemsCreateLimit: number,
  isProductionEnabled: boolean,
): boolean {
  return (totalItems >= itemsCreateLimit && !isProductionEnabled) || false
}

const FIFTEEN_SECONDS_IN_MS = 15000

export function getServerSyncedDate(serverDateDeltaInMs: number | null): Date {
  if (serverDateDeltaInMs === null) {
    // couldn't fetch the server's UTC timestamp so we couldn't calculate the delta
    // we trust the client's date to don't block the user
    return new Date()
  }

  return new Date(Date.now() + serverDateDeltaInMs)
}

/**
  Summarize the dateDifferenceInMs in ranges of 15 seconds if it's greater than 120 seconds
  we will return 120+ seconds
  example:
  0-15 seconds
  15-30 seconds
  ...
  120+ seconds
  @param dateDifferenceAbsolute difference between user date and api date
 */
function getDateDifferenceRangeInFifteenSecondsInterval(
  dateDifferenceAbsolute: number,
): string {
  // we need to round down the dateDifferenceAbsolute to 15 seconds intervals
  const dateDifferenceRange =
    Math.floor(dateDifferenceAbsolute / FIFTEEN_SECONDS_IN_MS) * 15

  if (dateDifferenceRange >= 120) {
    return '120+'
  }
  // return the range interval
  return `${dateDifferenceRange} - ${dateDifferenceRange + 15} seconds`
}

/**
 * if api date from parameter is different by a margin of 15s from user date, it will report it to sentry
 * to help us understand if it's a common thing.
 *
 * @param dateDifferenceInMs number - date from the api
 */
export function reportApiDateDeltaToSentry(
  dateDifferenceInMs: number | null,
): void {
  if (dateDifferenceInMs === null) {
    // server date was not retrieved, we can't compare it
    return
  }
  // difference could be negative, we need to make it positive
  const dateDifferenceAbsolute = Math.abs(dateDifferenceInMs)

  if (dateDifferenceAbsolute > FIFTEEN_SECONDS_IN_MS) {
    const dateDifferenceRange = getDateDifferenceRangeInFifteenSecondsInterval(
      dateDifferenceAbsolute,
    )

    setTag('dateDifferenceRange', dateDifferenceRange)
  }
}
