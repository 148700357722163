import React, { useEffect } from 'react'

import { onOpenCallback } from '../../utils/appWrapper'
import { Connect } from '../Connect'

import './HomePage.css'

const HomePage = () => {
  useEffect(() => {
    onOpenCallback()
  }, [])

  return <Connect />
}

export default React.memo(HomePage)
