import jwtDecode from 'jwt-decode'
import type { Connector } from 'pluggy-js'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { getAppProps } from '../../utils/appWrapper'
import { TrackEventName } from '../analytics/events'
import { track } from '../analytics/utils'
import { getConnectors } from '../connector/selectors'
import {
  MISSING_BANK_SUBMIT_REQUEST,
  missingBankSubmitFailure,
  MissingBankSubmitRequestAction,
  missingBankSubmitSuccess,
} from './actions'

function* handleMissingBankSubmitRequest(
  action: MissingBankSubmitRequestAction,
) {
  const { bank } = action.payload
  const { connectToken } = getAppProps()

  try {
    if (!bank) {
      throw new Error("Can't submit missing bank request, can't be empty")
    }
    if (bank.length > 100) {
      throw new Error("Can't submit missing bank request, input too large")
    }

    const connectors: Connector[] = yield select(getConnectors)
    const connectorsSorted = connectors.sort((a, b) => (a.id < b.id ? -1 : 1))
    const connectorNames: Connector['name'][] = []
    const connectorIds: Connector['id'][] = []

    for (const { name, id } of connectorsSorted) {
      connectorNames.push(name)
      connectorIds.push(id)
    }

    const { clientId } = jwtDecode<{ clientId?: string }>(connectToken)

    yield call(
      () =>
        // we create a promise to make the function async to properly manage the loading state
        new Promise((resolve) =>
          track(
            TrackEventName.MISSING_BANK_SUBMITTED,
            {
              value: bank,
              connectorNames,
              connectorIds,
              clientId,
            },
            undefined,
            () => resolve(undefined),
          ),
        ),
    )

    yield put(missingBankSubmitSuccess(bank))
  } catch (error) {
    yield put(missingBankSubmitFailure(error.message))
  }
}

export function* missingBankSaga() {
  yield all([
    takeEvery(MISSING_BANK_SUBMIT_REQUEST, handleMissingBankSubmitRequest),
  ])
}
