import React from 'react'

import './InformationIcon.css'

const InformationIcon = () => (
  <svg
    className={'InformationIcon Icon'}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 11.3333C9.81667 11.3333 9.66667 11.1833 9.66667 11V7.16667C9.66667 6.98333 9.81667 6.83333 10 6.83333C10.1833 6.83333 10.3333 6.98333 10.3333 7.16667V11C10.3333 11.1833 10.1833 11.3333 10 11.3333ZM10 13.1667C9.71667 13.1667 9.5 12.95 9.5 12.6667C9.5 12.3833 9.71667 12.1667 10 12.1667C10.2833 12.1667 10.5 12.3833 10.5 12.6667C10.5 12.95 10.2833 13.1667 10 13.1667ZM10 16.125C6.61667 16.125 3.875 13.3833 3.875 10C3.875 6.61667 6.61667 3.875 10 3.875C13.3833 3.875 16.125 6.61667 16.125 10C16.125 13.3833 13.3833 16.125 10 16.125ZM3 10C3 13.8663 6.13367 17 10 17C13.8663 17 17 13.8663 17 10C17 6.13367 13.8663 3 10 3C6.13367 3 3 6.13367 3 10Z"
      fill="#111111"
    />
  </svg>
)

export default React.memo(InformationIcon)
