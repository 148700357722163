import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from '@pluggyai/ui'

import { TrackEventName } from '../../../../../../../modules/analytics/events'
import { track } from '../../../../../../../modules/analytics/utils'
import {
  isBBPJConnector,
  isCaixaConnector,
} from '../../../../../../../modules/connector/utils'
import { Props } from './DeviceAuthorizedStatus.types'

import './DeviceAuthorizedStatus.css'

const BBPJ_DEVICE_AUTHORIZATION_WATING_TIME = '24 horas'
const CAIXA_DEVICE_AUTHORIZATION_WATING_TIME = '30 minutos'
const SANDBOX_DEVICE_AUTHORIZATION_WAITING_TIME = '2 minutos'

const DeviceAuthorizedStatus = ({ connector, onClose }: Props) => {
  const { t } = useTranslation()

  const buttonCloseI18nKey = 'connectStatus.device-authorized.action.close'
  const buttonCloseText = t(buttonCloseI18nKey)

  const handleCloseButtonClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      i18nKey: buttonCloseI18nKey,
      text: buttonCloseText,
    })
    onClose()
  }, [buttonCloseText, onClose])

  const deviceAuthorizationWaitingTime = isCaixaConnector(connector)
    ? CAIXA_DEVICE_AUTHORIZATION_WATING_TIME
    : isBBPJConnector(connector)
    ? BBPJ_DEVICE_AUTHORIZATION_WATING_TIME
    : SANDBOX_DEVICE_AUTHORIZATION_WAITING_TIME

  return (
    <div className={'DeviceAuthorizedStatus'}>
      <div className={'message-container'}>
        <p className={'title'}>{t('connectStatus.device-authorized.title')}</p>
        <p className={'message'}>
          <Trans
            i18nKey={'connectStatus.device-authorized.message'}
            values={{ timeToWait: deviceAuthorizationWaitingTime }}
          />
        </p>
      </div>

      <div className={'action-container'}>
        <Button onClick={handleCloseButtonClick} primary>
          {buttonCloseText}
        </Button>
      </div>
    </div>
  )
}

export default React.memo(DeviceAuthorizedStatus)
