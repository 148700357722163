import { connect } from 'react-redux'

import { getSelectedConnector } from '../../../../../../modules/connector/selectors'
import { RootState } from '../../../../../../modules/reducer'
import QRActionInstructions from './QRActionInstructions'
import { MapStateProps } from './QRActionInstructions.types'

const mapState = (state: RootState): MapStateProps => ({
  selectedConnector: getSelectedConnector(state),
})

export default connect(mapState)(QRActionInstructions)
