import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@pluggyai/ui'

import { Props } from './ConnectTokenExpiredError.types'

import './ConnectTokenExpiredError.css'

const ConnectTokenExpiredError = ({ onClose }: Props) => {
  const { t } = useTranslation()

  return (
    <div className={'ConnectTokenExpiredError'}>
      <div className={'message'}>
        <img src={'../time_expired.png'} alt="time expired" />
        <p>{t('connectStatus.session-expired.message')}</p>
      </div>
      <div className="action-container">
        <Button primary onClick={onClose}>
          {t('connectStatus.action.close')}
        </Button>
      </div>
    </div>
  )
}
export default React.memo(ConnectTokenExpiredError)
