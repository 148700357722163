import { InputModeProps } from './types'

// Sources
//  - https://css-tricks.com/finger-friendly-numerical-inputs-with-inputmode/
//  - https://baymard.com/labs/touch-keyboard-types

/**
 * Intended for all credential inputs, never offer autoCorrect nor autoCapitalize
 */
export const BASE_INPUT_MODE: InputModeProps = {
  // always disable auto correct for input fields
  autoCorrect: 'off',
  // never auto-capitalize
  autoCapitalize: 'off',
}

/**
 * Shows in mobile, an email-friendly keyboard in 'email' fields
 */
export const EMAIL_INPUT_MODE: InputModeProps = {
  type: 'email',
  inputMode: 'email',
  autoComplete: 'email',
}

/**
 * Shows a numeric-only [0-9] keyboard in mobile.
 * Depending if ios or android also shows more or less punctuation, but
 * it's still more useful than a regular text keyboard.
 */
export const NUMERIC_INPUT_MODE: InputModeProps = {
  type: 'text',
  inputMode: 'decimal',
  pattern: '[0-9]*',
  noValidate: true,
  autoCorrect: 'off',
  autoComplete: 'cc-csc',
}
