import { all } from 'redux-saga/effects'

import { authSaga } from './auth/sagas'
import { configSaga } from './config/sagas'
import { connectorSaga } from './connector/sagas'
import { itemSaga } from './item/sagas'
import { missingBankSaga } from './missingBank/sagas'
import { termsOfUseSaga } from './termsOfUse/sagas'

export function* rootSaga() {
  yield all([
    authSaga(),
    configSaga(),
    connectorSaga(),
    itemSaga(),
    missingBankSaga(),
    termsOfUseSaga(),
  ])
}
