import React from 'react'

type Props = {
  src: string
  companyName: string
}

const BackgroundIconsCollapsed = ({ src, companyName }: Props) => (
  <div className={'header-background-icons-collapsed'}>
    <img className={''} alt={`${companyName} logo`} src={src} />
  </div>
)

export default React.memo(BackgroundIconsCollapsed)
