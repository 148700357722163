import { ConnectorTypeTab } from '@pluggyai/ui'
import { ResourceKey } from 'i18next'
import type { ConnectorType, CountryCode } from 'pluggy-js'
import { SemanticICONS } from 'semantic-ui-react'

import { ConnectConfig } from '../modules/config/types'
import { ConnectorId } from '../modules/connector/utils'
import { getInternalUrlProps } from './appWrapper'
import {
  calculateButtonTextColorForBackgroundColor,
  calculatePrimaryHoverColor,
  calculateSecondaryHoverColor,
} from './colors'

const CUSTOM_THEMES = [
  'DEMO',
  'GYRA',
  'SPC',
  'LINKER',
  'LIZARD_TI',
  'KAMINO',
  'ALPHA_ASSET',
  'F360',
  'APTUS',
  'GRANATUM',
  'EVLOS',
  'EXTRATY',
  'PACTO',
  'VLG',
  'KINVO_PREVIDENCIA',
  'PLICK',
  'W1',
  'DU_OPEN',
  'DOMTEC',
  'AUDTAX',
  'WTM',
] as const
export type CustomTheme = (typeof CUSTOM_THEMES)[number]

const clientIdThemesMap: Record<string, CustomTheme | undefined> = {
  'b3a4e4c9-5bc9-4b60-83c2-a9caacb18bb3': 'GYRA', // PRODUCTION
  '629e4d23-7f81-4f72-9104-89c081389989': 'GYRA', // DEVELOPMENT
  'bab6ea2a-35d0-4a06-9d2a-fbc2c5b5828a': 'SPC',
  '0a87945e-2ea0-4843-bee4-b71d2475b46e': 'LINKER', // ex-prod
  '4aaf1afb-89c4-4961-aae2-04e71a96f4f0': 'LINKER', // prod - Dashboard app, client 'Linker - Pluggy Demo App'
  '9cc0bdf9-238f-4b61-a85b-c59585728706': 'LIZARD_TI', // DEMO
  '3ca92c78-5f4f-4b1a-9bc5-6540cad93a5b': 'LIZARD_TI', // DEVELOPMENT
  'ad22aa50-efb5-4610-98e0-43ffb7daf4f1': 'LIZARD_TI',
  '807494e2-9eb0-4ee2-9745-cb1487cceffb': 'KAMINO',
  'be719e15-e122-433e-96cd-d1774a6bae18': 'ALPHA_ASSET',
  'd82e51d6-8cdd-4683-96c7-39055513ce3a': 'F360', // DEMO
  '6427891e-ffe4-4fd0-9f14-2ad3e999c030': 'F360', // DEMO
  '48ce35fe-d09d-4bcd-a9b9-f7ef2a10dea6': 'F360', // DEVELOPMENT
  '9f9256f5-e265-4197-945a-a737cb7fb510': 'F360', // DEVELOPMENT
  'dec41ed2-23ab-46a9-b921-ace7a55feeab': 'F360', // DEVELOPMENT
  'edb03563-f808-4628-a6e5-c410e836c82f': 'F360', // PRODUCTION
  '77d6fd1f-d5ec-4532-b2c7-93b54d758def': 'APTUS', // PRODUCTION
  '9cad1f1f-bc58-487c-8072-dfa94e9caddd': 'GRANATUM', // PRODUCTION
  '8e14e8d4-b43e-4559-b938-794eece9c4db': 'GRANATUM', // DEVELOPMENT
  'fce02479-1823-4eef-9a4a-34d0218a145a': 'GRANATUM', // DEMO
  '88c534b1-41fe-4402-aca5-b81eeda4efc5': 'EVLOS', // PRODUCTION
  'ead0d3c1-11a8-4faf-a59e-edfe704dd92d': 'EXTRATY', // PRODUCTION
  'df3a7980-2f6e-49ae-a8c4-bd27f96eb04f': 'EXTRATY', // DEVELOPMENT
  'e58c91c8-dc9b-4181-af90-ae6aa7f73361': 'PACTO', // PRODUCTION
  'b3db0fd1-9d07-46bc-af56-002dced4fa23': 'VLG', // PRODUCTION
  'a56714c9-f174-42db-8194-fc23396eac9f': 'KINVO_PREVIDENCIA', // PRODUCTION KINVO PREVIDENCIA APP
  'a2dad3c1-4a4a-422f-9c74-d604e98f5d6f': 'PLICK', // Plick Soluções prod
  '5fafd3ef-06f4-4cba-862f-3fcbfe78c1bd': 'W1', // PRODUCTION
  'dc895723-5b71-4e8b-ac47-b3a885b80fe9': 'W1', // DEMO
  '91454159-5d5c-42d7-8693-34b84ca4ae1c': 'W1', // PRODUCTION
  '1b7e1da5-3223-47a6-9d2f-2765a87865ff': 'W1', // DEVELOPMENT
  '573945ae-3427-4a3c-8730-694da1309362': 'DU_OPEN',
  'f81599a7-1687-4ad7-99dd-f4634762176f': 'DU_OPEN',
  '90ee183b-db94-4f50-bb45-eb505079ea79': 'DU_OPEN',
  '76485c65-4541-4fef-9124-0c0f6a3da8e8': 'DU_OPEN',
  '51cffcfe-8452-4e37-a7ff-096db8b9933d': 'DOMTEC',
  '87a4aff2-2ee6-4b47-bcc3-36d126759a57': 'DOMTEC',
  'c6e46753-577f-4b0b-8a72-9793628059dc': 'DOMTEC',
  '9768fccb-3808-4926-b412-1d28d8dd0ada': 'DOMTEC',
  '4aed4e2c-b752-4c86-84b5-8a589c099ec1': 'DOMTEC',
  '6568c303-0ce1-4938-acac-5c6384c659e0': 'DOMTEC',
  'b19d8524-35d6-48f4-aef4-2b0bfb297cd9': 'AUDTAX', // prod
  '31120323-8dc8-40c3-9671-4ef0739001c8': 'AUDTAX', // dev
  '334a0023-72a0-42cc-a028-0304d2c39e8e': 'AUDTAX', // demo
  '181552c4-8ebc-4f4e-a782-b1ef870e8fa2': 'WTM', // DEMO
  '8e72ba74-d027-460b-88d8-9d98c1fb3b72': 'WTM', // DEVELOPMENT
  '0e4da0e3-2d9a-43cc-a5d9-7a7cc05e3554': 'WTM', // PRODUCTION
}

export const getClientTheme = (clientId: string): CustomTheme => {
  const customTheme = clientIdThemesMap[clientId]
  if (!customTheme) {
    return 'DEMO'
  }
  return customTheme
}

export const isSupportedCustomTheme = (theme: string): theme is CustomTheme =>
  CUSTOM_THEMES.includes(theme as CustomTheme)

export type Customization = {
  key: CustomTheme
  companyName?: string
  cssClassName?: string
  // gyramais want to be able to skip the welcome step because they have a flow in their application
  // and the user already accept the terms and conditions (so the welcome screen is unnecessary)
  isWelcomeStepSkipped?: boolean
  // if true, don't display the logo in the Welcome step
  // this is useful for clients that want to reduce the text and/or content in this screen
  hideWelcomeStepLogo?: boolean
  // some clients are interested in collecting only some products data from users,
  // therefore they don't want to display in the UI the detail of all the actual products being collected.
  hideConnectStatusStepDetail?: boolean
  // F360 don't want to allow users to abort the connection process (because they have a flow in their application)
  canUserAbortConnection?: boolean
  // override default connector tabs order (if not provided, the default order is used)
  connectorTabsOrder?: ConnectorTypeTab[]
  brand?: {
    logo: string
    logoBackground?: string
    logoBackgroundCollapsed?: string
  }
  styles?: {
    logo?: {
      top?: string
      left?: string
      height?: string
      width?: string
    }
    logoBackground?: {
      top?: string
      left?: string
      height?: string
      width?: string
    }
    logoBackgroundCollapsed?: {
      top?: string
      left?: string
      height?: string
      width?: string
    }
    icons?: {
      welcomeCheckIcon?: SemanticICONS
    }
    colors: {
      primary?: string
      primaryHover?: string
      secondaryHover?: string
      textLink?: string
      textLinkHover?: string
    }
    fonts?: {
      primary?: {
        fontFamily?: string
        fontFamilyFallbacks?: string
        fontSize?: string
        fontWeight?: string
        fontStretch?: string
        fontStyle?: string
        lineHeight?: string
        letterSpacing?: string
      }
      secondary?: {
        fontFamily?: string
        fontFamilyFallbacks?: string
        fontSize?: string
        fontWeight?: string
        fontStretch?: string
        fontStyle?: string
        lineHeight?: string
        letterSpacing?: string
      }
      form?: {
        inputFontSize?: string
        inputLineHeight?: string
        errorFontSize?: string
        errorLineHeight?: string
      }
    }
  }
  customCss?: string
  connectorsFilters?: {
    ids?: ConnectorId[]
    countries?: CountryCode[]
    connectorTypes?: ConnectorType[]
  }
  // whether to display institution logos in ConnectorsList. Default: true.
  displayInstitutionLogos?: boolean
  i18n?: {
    en?: ResourceKey
    pt?: ResourceKey
  }
  withRegulatedTags?: boolean
}

type Customizations = Record<CustomTheme, Customization>

const defaultDemoTheme: CustomTheme = 'DEMO'

// Customization defaults for Pluggy Demo application and connectConfig initial values
export const PRIMARY_COLOR_DEFAULT = '#ef294b' // Pluggy brand color
export const COMPANY_NAME_DEFAULT = 'Demo'
export const LOGO_DEFAULT =
  'https://pluggy.ai/_next/image?url=%2Flogo.png&w=256&q=75'

export const customizations: Customizations = {
  DEMO: {
    key: 'DEMO',
    companyName: COMPANY_NAME_DEFAULT,
    brand: {
      logo: LOGO_DEFAULT,
    },
    styles: {
      logoBackground: {
        top: '-12px',
        left: '7px',
      },
      colors: {
        primary: PRIMARY_COLOR_DEFAULT,
        primaryHover: '#c71b39',
        secondaryHover: '#ffeff2',
      },
      fonts: {
        primary: {
          fontFamily: 'Work Sans',
          fontFamilyFallbacks:
            "-apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
          fontSize: '0.875rem',
        },
        secondary: {
          fontFamily: 'Poppins',
          fontFamilyFallbacks:
            "-apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
          fontSize: '1.125rem',
        },
        form: {
          inputFontSize: '0.75rem',
          inputLineHeight: '1rem',
          errorFontSize: '0.75rem',
          errorLineHeight: '0.75rem',
        },
      },
    },
  },
  GYRA: {
    key: 'GYRA',
    isWelcomeStepSkipped: true,
  },
  F360: {
    key: 'F360',
    canUserAbortConnection: false,
  },
  SPC: {
    key: 'SPC',
    styles: {
      colors: {
        textLink: '#586682',
        textLinkHover: '#586682',
      },
    },
  },
  LINKER: {
    key: 'LINKER',
    displayInstitutionLogos: false,
    i18n: {
      pt: {
        welcome: {
          header: 'Conecte suas contas e visualize tudo em um só lugar!',
        },
      },
    },
  },
  LIZARD_TI: {
    key: 'LIZARD_TI',
    i18n: {
      pt: {
        // remove 'Pluggy' from welcome text, for white-label request
        welcome: {
          header: null,
        },
      },
    },
  },
  DOMTEC: {
    key: 'DOMTEC',
    i18n: {
      pt: {
        welcome: {
          header: null,
        },
      },
    },
  },
  DU_OPEN: {
    key: 'DU_OPEN',
    i18n: {
      pt: {
        // remove 'Pluggy' from welcome text, for white-label request
        welcome: {
          header: null,
        },
      },
    },
  },
  PLICK: {
    key: 'PLICK',
    i18n: {
      pt: {
        // remove 'Pluggy' from welcome text, for white-label request
        welcome: {
          header: null,
        },
      },
    },
  },
  PACTO: {
    key: 'PACTO',
    i18n: {
      pt: {
        // remove 'Pluggy' from welcome text, for white-label request
        welcome: {
          header: null,
        },
      },
    },
  },
  KAMINO: {
    key: 'KAMINO',
    i18n: {
      pt: {
        // remove 'Pluggy' from welcome text, for white-label request
        // https://pluggy.atlassian.net/browse/RAM-324
        welcome: {
          header: null,
        },
      },
    },
  },
  ALPHA_ASSET: {
    key: 'ALPHA_ASSET',
    hideConnectStatusStepDetail: true,
  },
  APTUS: {
    key: 'APTUS',
    hideConnectStatusStepDetail: true,
  },
  GRANATUM: {
    key: 'GRANATUM',
    hideConnectStatusStepDetail: true,
    connectorTabsOrder: ['BUSINESS_BANK', 'PERSONAL_BANK'],
  },
  EVLOS: {
    key: 'EVLOS',
    i18n: {
      pt: {
        welcome: {
          header:
            'A Evlos só se conecta às suas contas, com sua prévia autorização',
        },
      },
    },
  },
  AUDTAX: {
    key: 'AUDTAX',
    i18n: {
      pt: {
        welcome: {
          header: 'Audtax conectando você',
        },
      },
    },
  },
  KINVO_PREVIDENCIA: {
    key: 'KINVO_PREVIDENCIA',
    i18n: {
      pt: {
        welcome: {
          header:
            'Precisamos nos conectar ao seu banco ou corretora para obter as informações da <strong>sua previdência</strong>',
          bullets: {
            'its-secured': {
              content:
                'Nossa conexão é criptografada e segue os mais altos padrões do mercado, graças à <strong>Pluggy</strong>.',
            },
          },
        },
      },
    },
    customCss: `.Welcome .Title {
      font-size: calc(var(--header-title-font-size) * 0.9);
      width: 100%;
      line-height: calc(var(--header-title-font-size) * 1.25);
      font-weight: 400;
      text-align: center;
      padding: 0 var(--spacing);
      color: var(--header-text);
    }`,
  },
  EXTRATY: {
    key: 'EXTRATY',
    i18n: {
      pt: {
        // remove 'Pluggy' from welcome text, for white-label request
        welcome: {
          header: null,
        },
      },
    },
  },
  VLG: {
    key: 'VLG',
    hideWelcomeStepLogo: true,
    i18n: {
      pt: {
        // remove 'Pluggy' from welcome text, for white-label request
        welcome: {
          header: null,
        },
      },
    },
  },
  W1: {
    key: 'W1',
    withRegulatedTags: false,
  },
  WTM: {
    key: 'WTM',
    connectorTabsOrder: ['BUSINESS_BANK', 'PERSONAL_BANK'],
  },
}

/**
 * Build custom CSS string based on the retrieved ConnectConfig,
 * and/or the currently specified theme Customization.
 */
export function buildCustomConfigCss(
  connectConfig: ConnectConfig | null,
  customization: Customization,
): string {
  const { styles, customCss = '', brand } = customization
  const { logo, logoBackground, logoBackgroundCollapsed, colors, fonts } =
    styles || {}

  const hasLogoImage = Boolean(brand?.logo)

  const logoCustomCss = hasLogoImage
    ? [
        '.Connect .ConnectHeader .header-logo {',
        ` top: calc(50% + ${logo?.top || '0px'});`,
        ` left: calc(50% + ${logo?.left || '0px'});`,
        logo?.height ? ` height: ${logo.height}` : '',
        logo?.width ? ` width: ${logo.width}` : '',
        '}',
      ].join('\n')
    : ''

  const logoBackgroundCustomCss = logoBackground
    ? [
        '.Connect .ConnectHeader .header-background-icons {',
        ` top: calc(50% + ${logoBackground.top || '0px'});`,
        ` left: calc(50% + ${logoBackground.left || '0px'});`,
        ` transform: translate(calc(-50% + ${
          logoBackground.left || '0px'
        }), calc(-50% + ${logoBackground.top || '0px'}));`,
        logoBackground.height ? ` height: ${logoBackground.height}` : '',
        logoBackground.width ? ` width: ${logoBackground.width}` : '',
        '}',
      ].join('\n')
    : ''

  const logoBackgroundCollapsedCustomCss = logoBackgroundCollapsed
    ? [
        '.Connect .ConnectHeader .header-background-icons-collapsed {',
        logoBackgroundCollapsed.top
          ? `  top: ${logoBackgroundCollapsed.top};`
          : '',
        logoBackgroundCollapsed.left
          ? `  left: ${logoBackgroundCollapsed.left};`
          : '',
        logoBackgroundCollapsed.height
          ? ` height: ${logoBackgroundCollapsed.height}`
          : '',
        logoBackgroundCollapsed.width
          ? ` width: ${logoBackgroundCollapsed.width}`
          : '',
        '}',
      ].join('\n')
    : ''

  const presetColorPrimaryOrDefault = colors?.primary || PRIMARY_COLOR_DEFAULT
  const configColorPrimary =
    typeof colors?.primary === 'string' &&
    colors.primary.toLowerCase() !== PRIMARY_COLOR_DEFAULT
      ? // has manually hardcoded value => prioritize this one until fully migrated
        // TODO migrate Customization then use back connectConfig as priority (https://pluggy.atlassian.net/browse/DVT-488)
        colors.primary
      : // otherwise, use connectConfig value
        connectConfig?.styles.colors.primary

  const configColorPrimaryOrPresetOrDefault =
    configColorPrimary || presetColorPrimaryOrDefault

  // if config has primaryColor -> derive a primaryHover from it (lighten/darken it a bit)
  // if not -> use preset primaryHover
  // if not -> derive it from preset primaryColor, or from default primaryColor
  const configColorPrimaryHoverDerivedOrPreset = configColorPrimary
    ? calculatePrimaryHoverColor(configColorPrimary)
    : colors?.primaryHover ||
      calculatePrimaryHoverColor(presetColorPrimaryOrDefault)

  const colorSecondaryHoverDefault = 'rgba(233, 183, 191, 0.2)'

  // if config has primaryColor -> mix secondaryHoverDefault with some primaryColor
  // if not, use preset secondaryHover
  // if not, use secondaryHoverDefault + mixed with primaryColor or defaultPrimaryColor
  const configColorSecondaryHoverMixedOrPresetOrDefault = configColorPrimary
    ? calculateSecondaryHoverColor(
        configColorPrimary,
        colorSecondaryHoverDefault,
      )
    : colors?.secondaryHover ||
      calculateSecondaryHoverColor(
        presetColorPrimaryOrDefault,
        colorSecondaryHoverDefault,
      )

  const rootOverrideCss = `:root {
  /* fonts */
  --font-family: ${[
    fonts?.primary?.fontFamily || 'Work Sans',
    fonts?.primary?.fontFamilyFallbacks ||
      "-apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
  ]
    .filter(Boolean)
    .join(', ')};
  --font-size: ${fonts?.primary?.fontSize || '0.875rem'};
  --font-weight: ${fonts?.primary?.fontWeight || 'normal'};
  --font-stretch: ${fonts?.primary?.fontStretch || 'normal'};
  --font-style: ${fonts?.primary?.fontStyle || 'normal'};
  --line-height: ${fonts?.primary?.lineHeight || 'normal'};
  --letter-spacing: ${fonts?.primary?.letterSpacing || 'normal'};

  --title-font-family: ${[
    fonts?.secondary?.fontFamily || 'Poppins',
    fonts?.secondary?.fontFamilyFallbacks,
  ]
    .filter(Boolean)
    .join(', ')};
  --title-font-size: ${fonts?.secondary?.fontSize || '1.125rem'};
  --title-font-weight: ${fonts?.secondary?.fontWeight || '500'};
  --title-font-stretch: ${fonts?.secondary?.fontStretch || 'normal'};
  --title-font-style: ${fonts?.secondary?.fontStyle || 'normal'};
  --title-line-height: ${fonts?.secondary?.lineHeight || 'normal'};
  --title-letter-spacing: ${fonts?.secondary?.letterSpacing || 'normal'};

  --form-input-font-size: ${fonts?.form?.inputFontSize || '0.75rem'};
  --form-input-line-height: ${fonts?.form?.inputLineHeight || '1rem'};
  --form-input-error-font-size: ${fonts?.form?.errorFontSize || '0.75rem'};
  --form-input-error-line-height: ${fonts?.form?.errorLineHeight || '0.75rem'};

  /* colors */
  --primary: ${configColorPrimaryOrPresetOrDefault};
  --primary-hover: ${configColorPrimaryHoverDerivedOrPreset};
  --secondary-hover: ${configColorSecondaryHoverMixedOrPresetOrDefault};

  /* text */
  ${colors?.textLink ? `--text-link: ${colors.textLink};` : ''}
  ${colors?.textLinkHover ? `--text-link-hover: ${colors.textLinkHover};` : ''}

  --text-on-primary: ${calculateButtonTextColorForBackgroundColor(
    configColorPrimaryOrPresetOrDefault,
  )}
}
`

  return [
    rootOverrideCss,
    logoCustomCss,
    logoBackgroundCustomCss,
    logoBackgroundCollapsedCustomCss,
    customCss,
  ].join('\n')
}

export function getBaseCustomization(): Customization {
  const { customTheme } = getInternalUrlProps()
  const theme = customTheme || defaultDemoTheme

  return customizations[theme]
}
