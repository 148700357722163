function extractVersionFromPackageString(
  packageString: string,
  versionIncrement: 'major' | 'minor',
): number {
  return Number.parseInt(
    packageString.split('@')[1].split('.')[
      versionIncrement === 'major' ? 0 : 1
    ],
  )
}

export function getPluggyConnectSdkVersionIncrement(
  sdkVersion: string | string[] | undefined,
  versionIncrement: 'major' | 'minor',
): number | undefined {
  if (sdkVersion === undefined) {
    return undefined
  }

  const pluggyConnectSdkVersion = getPluggyConnectSdkVersion(sdkVersion)

  if (pluggyConnectSdkVersion === undefined) {
    return undefined
  }

  return extractVersionFromPackageString(
    pluggyConnectSdkVersion,
    versionIncrement,
  )
}

function getPluggyConnectSdkVersion(
  sdkVersion: string | string[] | undefined,
): string | undefined {
  if (sdkVersion === undefined) {
    return undefined
  }

  if (Array.isArray(sdkVersion)) {
    // we are inside a wrapper, like React, Flutter, etc
    const pluggyConnectSdkVersion = sdkVersion.find((version) =>
      version.includes('pluggy-connect-sdk'),
    )
    if (!pluggyConnectSdkVersion) {
      return undefined
    }
    return pluggyConnectSdkVersion
  }
  // we are only inside pluggy-connect-sdk
  if (!sdkVersion.includes('pluggy-connect-sdk')) {
    // some reason we don't have the SDK name inside the sdkVersion
    return undefined
  }

  return sdkVersion
}
