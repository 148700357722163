import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Alert, ConnectorType, ConnectorTypeTab } from '@pluggyai/ui'
import { ConnectEventPayload } from 'pluggy-connect-sdk'
import type { Connector, ConnectorCredential, Parameters } from 'pluggy-js'
import { Image } from 'semantic-ui-react'

import { TrackEventName } from '../../modules/analytics/events'
import { track } from '../../modules/analytics/utils'
import { getServerSyncedDate } from '../../modules/config/utils'
import {
  isSandboxConnector,
  isUniqueSandboxAvailableInType,
} from '../../modules/connector/utils'
import { isCollectingProducts } from '../../modules/item/types'
import { FINISHED_STATUSES } from '../../modules/item/utils'
import {
  getAppProps,
  isRunningAsZoidComponentInstance,
  onCloseCallback,
  onContinueInBackgroundCallback,
  onErrorCallback,
  onEventCallback,
} from '../../utils/appWrapper'
import { Customization, customizations } from '../../utils/customizations'
import { usePrevious } from '../../utils/hooks/usePrevious'
import { CloseConfirmationModal } from './CloseConfirmationModal'
import { Props } from './Connect.types'
import { ConnectHeader } from './ConnectHeader'
import { ConnectFormSkeletonLoader } from './SkeletonLoader'
import { ConnectForm } from './Steps/ConnectForm'
import { MfaStage } from './Steps/ConnectForm/ConnectForm.types'
import { ConnectorsList as ConnectorsListComponent } from './Steps/ConnectorsList'
import { ConnectStatus } from './Steps/ConnectStatus'
import { ErrorStatus } from './Steps/ConnectStatus/ErrorStatus'
import { MissingBankPage as MissingBankPageComponent } from './Steps/MissingBankPage'
import { OpenFinanceFAQ as OpenFinanceFAQComponent } from './Steps/OpenFinanceFAQ'
import { PrivacyPolicyPage as PrivacyPolicyPageComponent } from './Steps/PrivacyPolicyPage'
import { SandboxSelectionList as SandboxSelectionListComponent } from './Steps/SandboxSelectionList'
import { TermsPage as TermsPageComponent } from './Steps/TermsPage'
import { Welcome as WelcomeComponent } from './Steps/Welcome'
import { Title } from './Title'
import {
  getTermsAndConditionsTitleKey,
  isItemInFinalErrorState,
  isRunningInDashboardApplication,
  isUnexpectedApiOrNetworkItemError,
  trackApplicationLoadedContext,
  trackLoginStepSuccess,
} from './utils'

import './Connect.css'

export enum STEPS {
  Welcome = 1,
  ConnectorsList = 2,
  ConnectorForm = 3,
  ConnectorStatus = 4,
  ConnectSuccess = 5,
  ConnectError = 6,
  TermsPage = 10,
  PrivacyPolicyPage = 11,
  MissingBankPage = 20,
  OpenFinanceFAQ = 21,
  SandboxSelectionList = 30,
}

/**
 * takes the step of the widget (as a number),
 * and returns the name of the current step
 *
 * @param step - number, current step
 * @returns - string, the name of the step
 */
function getStepKey(step: STEPS): string {
  for (const stepKey in STEPS) {
    if (parseInt(STEPS[stepKey]) === step) {
      return stepKey
    }
  }
  return 'Unknown'
}

const Connect = ({
  item,
  itemError,
  isItemLoading,
  isUpdate,
  isAuthorizing,
  authError,
  connectors,
  customization,
  isConnectorsLoading,
  selectedConnector,
  onConnectorSelect,
  onClearConnectItem,
  onClearConnectItemError,
  onUpdateItem,
  backgroundConnectionAllowed,
  isDemoApplication,
  connectApplicationEnvironment,
  currentPolling,
  serverDateDeltaInMs,
}: Props) => {
  const { t } = useTranslation()

  const {
    displayInstitutionLogos = true,
    cssClassName: themeClassName,
    brand,
    isWelcomeStepSkipped: isCustomizationWelcomeStepSkipped,
    hideWelcomeStepLogo,
    hideConnectStatusStepDetail = false,
    canUserAbortConnection = true,
    connectorTabsOrder,
  } = customization

  const isWelcomeStepSkipped =
    isCustomizationWelcomeStepSkipped ||
    getAppProps().moveSecurityData !== undefined

  const companyName =
    customization.companyName || customizations['DEMO'].companyName || ''
  const logo = brand?.logo || customizations['DEMO'].brand?.logo || ''
  const logoBackground = brand?.logoBackground
  const logoBackgroundCollapsed = brand?.logoBackgroundCollapsed
  const { withRegulatedTags } = customization

  // check if it was decided to navigate to a specific connector form directly when advancing Welcome step
  const { selectedConnectorId } = getAppProps()
  const preselectedConnector = useMemo(
    () => connectors.find(({ id }) => id === selectedConnectorId),
    [connectors, selectedConnectorId],
  )

  let initialStep: STEPS = STEPS.Welcome

  if (isWelcomeStepSkipped) {
    // skip welcome step
    if (preselectedConnector) {
      // was specified selectedConnectorId prop, and it's present in filtered connectors list
      // navigate directly to its login form
      initialStep = STEPS.ConnectorForm
    } else {
      // no selectedConnectorId
      initialStep = STEPS.ConnectorsList
    }
  }

  useEffect(() => {
    if (!isWelcomeStepSkipped || preselectedConnector === undefined) {
      return
    }
    onConnectorSelect(preselectedConnector)
  }, [isWelcomeStepSkipped, onConnectorSelect, preselectedConnector])

  const [step, setStep] = useState<STEPS>(initialStep)

  const [isCloseModalOpen, setIsCloseModalOpen] = useState<boolean>(false)

  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false)

  const isSelectedConnectorIdMissingError =
    typeof selectedConnectorId !== 'undefined' &&
    !preselectedConnector &&
    t('config.selected_connector_id_missing', { selectedConnectorId })

  // use a ref for customization, to ensure we only track the first retrieved values
  const customizationRef = useRef<Customization>(customization)

  useEffect(() => {
    // first render -> track Connect loaded event with all related context data
    trackApplicationLoadedContext(
      customizationRef.current,
      connectApplicationEnvironment,
    )
  }, [connectApplicationEnvironment])

  // if is updating, set current item connector
  useEffect(() => {
    if (!isUpdate) {
      return
    }
    // isUpdate -> navigate to ConnectForm screen & update state with item data
    if (step === STEPS.Welcome) {
      setStep(STEPS.ConnectorForm)
    }

    if (!item) {
      // is update, but the item to update is has not yet been retrieved by the API
      return
    }

    if (selectedConnector) {
      // connector already selected
      return
    }
    // no selectedConnector yet -> select it
    onConnectorSelect(item.connector)
  }, [item, selectedConnector, step, isUpdate, onConnectorSelect])

  // check if there is authError and run submit onErrorCallback
  useEffect(() => {
    if (authError) {
      onErrorCallback({ message: authError })
    }
  }, [authError])

  const [isClosed, setIsClosed] = useState(false)

  const handleClose = useCallback(() => {
    // update 'isClosed' to unmount react components first, then actually close
    setIsClosed(true)

    if (isRunningAsZoidComponentInstance()) {
      // running as modal component -> nothing more to do
      return
    }
    // not running as modal -> reset state to first step
    setStep(STEPS.Welcome)
    onConnectorSelect(null)
    onClearConnectItem()
    setIsCloseModalOpen(false)
  }, [onConnectorSelect, onClearConnectItem])

  const handleConnectInBackground = useCallback(() => {
    // close modal so if it's re-opened, it will be in the same state without the modal
    setIsCloseModalOpen(false)
    onContinueInBackgroundCallback()
  }, [])

  const handleIconCloseClick = useCallback(() => {
    const stepFinishStatus = [STEPS.ConnectSuccess, STEPS.ConnectError]

    track(TrackEventName.ICON_CLICKED, {
      icon: 'iconClose',
      currentStep: step,
      currentStepName: getStepKey(step),
    })
    const isFinishStepStatus = stepFinishStatus.includes(step)
    const isItemFinishExecutionStatus =
      item && FINISHED_STATUSES.includes(item.executionStatus)

    if (
      (isItemLoading || item || isFormSubmitting) &&
      backgroundConnectionAllowed &&
      !isFinishStepStatus &&
      !isItemFinishExecutionStatus &&
      !isUpdate
    ) {
      setIsCloseModalOpen(true)
      return
    }
    handleClose()
  }, [
    backgroundConnectionAllowed,
    handleClose,
    isFormSubmitting,
    isItemLoading,
    isUpdate,
    item,
    step,
  ])

  useEffect(() => {
    if (!isClosed) {
      return
    }
    // actually do close
    onCloseCallback({
      currentStep: step,
      item,
      selectedConnector,
      currentPolling,
    })
  }, [isClosed, item, selectedConnector, step, currentPolling])

  const goToFaqPage = useCallback(() => {
    setStep(STEPS.OpenFinanceFAQ)
  }, [])

  const prevStep = usePrevious(step)

  const handleGoBack = useCallback(() => {
    if (isCloseModalOpen) {
      track(TrackEventName.ICON_CLICKED, {
        icon: 'iconOnGoBack',
        // necessary to know if we are in the closeModal
        isCloseModalOpen,
        currentStep: step,
        currentStepName: getStepKey(step),
      })
      // is closing modal open -> just close modal
      setIsCloseModalOpen(false)
      return
    }

    if (step === STEPS.ConnectorForm) {
      onConnectorSelect(null)
      onClearConnectItem()
      onEventCallback({
        event: 'SELECTED_INSTITUTION',
        connector: null,
      })
    }

    let nextStep: STEPS
    if (step === STEPS.TermsPage || step === STEPS.PrivacyPolicyPage) {
      if (prevStep === undefined) {
        return
      }
      nextStep = prevStep
    } else if (step === STEPS.MissingBankPage) {
      nextStep = STEPS.ConnectorsList
    } else if (step === STEPS.ConnectError) {
      if (item) {
        onClearConnectItemError(item.id)
        nextStep = STEPS.ConnectorForm
      } else {
        onClearConnectItem()
        nextStep = STEPS.ConnectorsList
      }
    } else if (
      step === STEPS.ConnectorForm &&
      selectedConnector &&
      isSandboxConnector(selectedConnector) &&
      !isUniqueSandboxAvailableInType(connectors, selectedConnector)
    ) {
      // we are in a sandbox connector, go back to Sandbox selection page instead of conenctors list
      nextStep = STEPS.SandboxSelectionList
    } else if (step === STEPS.SandboxSelectionList) {
      nextStep = STEPS.ConnectorsList
    } else if (step === STEPS.OpenFinanceFAQ) {
      nextStep = STEPS.ConnectorForm
    } else {
      nextStep = step - 1
    }

    track(TrackEventName.ICON_CLICKED, {
      icon: 'iconOnGoBack',
      currentStep: step,
      currentStepName: getStepKey(step),
      nextStep,
      nextStepName: getStepKey(nextStep),
      isCloseModalOpen,
    })

    setStep(nextStep)
  }, [
    isCloseModalOpen,
    step,
    selectedConnector,
    connectors,
    onConnectorSelect,
    onClearConnectItem,
    prevStep,
    item,
    onClearConnectItemError,
  ])

  // necessary to know the current stage of the form
  const [currentMfaStage, setCurrentMfaStage] = useState<MfaStage | undefined>()
  const handleMfaFormStageChange = useCallback((stage?: MfaStage) => {
    setCurrentMfaStage(stage)
  }, [])

  const handleFormLodingStateChange = useCallback((isFormLoading: boolean) => {
    setIsFormSubmitting(isFormLoading)
  }, [])

  // Register modal close() on 'Escape' key button press
  useEffect(() => {
    function escapeKeyCloseHandler(event: KeyboardEvent) {
      if (event.key !== 'Escape') {
        return
      }
      // escape key -> close, and remove event listener
      handleClose()
      document.removeEventListener('keydown', escapeKeyCloseHandler)
    }

    document.addEventListener('keydown', escapeKeyCloseHandler)

    return () => document.removeEventListener('keydown', escapeKeyCloseHandler)
  }, [handleClose])

  const connectInitiationDate = useMemo(
    () => getServerSyncedDate(serverDateDeltaInMs),
    [serverDateDeltaInMs],
  )

  const handleConnectorFormStepCompleted = useCallback(() => {
    if (!item) {
      return
    }
    setStep(STEPS.ConnectorStatus)
    onEventCallback({
      event: 'LOGIN_STEP_COMPLETED',
      item,
    })
  }, [item])

  const handleGoToErrorStatus = useCallback(() => {
    setStep(STEPS.ConnectError)
  }, [])

  useEffect(() => {
    const isItemFromAnotherConnectExecution =
      item && item.updatedAt <= connectInitiationDate

    const isItemUpdatedStatus = item?.status === 'UPDATED'
    const isItemCollectingStatus =
      item && isCollectingProducts(item.executionStatus)

    if (
      step === STEPS.ConnectorForm &&
      !isItemFromAnotherConnectExecution &&
      item
    ) {
      if (isItemCollectingStatus || isItemUpdatedStatus) {
        trackLoginStepSuccess(item, isUpdate, currentMfaStage)
        let event: ConnectEventPayload['event']
        if (currentMfaStage === undefined || currentMfaStage === '1-step') {
          event = 'LOGIN_SUCCESS'
        } else {
          event = 'LOGIN_MFA_SUCCESS'
        }
        onEventCallback({
          event,
          item,
        })
        // item started updating -> go to ConnectorStatus step to track it
        handleConnectorFormStepCompleted()
      } else if (isItemInFinalErrorState(item, itemError)) {
        // item connect resulted in error state -> go to error screen
        handleGoToErrorStatus()
      }
    }

    if (
      (step === STEPS.ConnectorForm || step === STEPS.ConnectorStatus) &&
      isUnexpectedApiOrNetworkItemError(itemError)
    ) {
      // got a Network Error (ie. server did not respond)
      // or an unexpected API error (ie. 404, 403, >=500)
      handleGoToErrorStatus()
    }

    if (step === STEPS.ConnectError && itemError === null && !item?.error) {
      // We don't have an error anymore, and the item is not in error state anymore -> go back to ConnectorForm
      setStep(STEPS.ConnectorForm)
    }
  }, [
    connectInitiationDate,
    currentMfaStage,
    handleConnectorFormStepCompleted,
    handleGoToErrorStatus,
    isUpdate,
    item,
    itemError,
    step,
  ])

  const handleWelcomeStepCompleted = useCallback(() => {
    onEventCallback({ event: 'SUBMITTED_CONSENT' })

    if (preselectedConnector) {
      // was specified selectedConnectorId prop, and it's present in filtered connectors list
      // navigate directly to its login form
      onConnectorSelect(preselectedConnector)
      setStep(STEPS.ConnectorForm)
      return
    }

    setStep(STEPS.ConnectorsList)
  }, [onConnectorSelect, preselectedConnector])

  const handleGoToTermsPage = useCallback(
    (event?: React.MouseEvent<HTMLElement>) => {
      event?.stopPropagation()
      event?.preventDefault()
      track(TrackEventName.LINK_CLICKED, {
        location: 'welcomePage',
        linkTo: 'termsPage',
      })
      setStep(STEPS.TermsPage)
    },
    [],
  )

  const handleGoToPrivacyPolicyPage = useCallback(
    (event?: React.MouseEvent<HTMLElement>) => {
      event?.stopPropagation()
      event?.preventDefault()
      track(TrackEventName.LINK_CLICKED, {
        location: 'welcomePage',
        linkTo: 'privacyAndPolicyPage',
      })
      setStep(STEPS.PrivacyPolicyPage)
    },
    [],
  )

  const isAnyLoading = isAuthorizing || isConnectorsLoading || isItemLoading

  const [selectedSandboxType, setSelectedSandboxType] =
    useState<ConnectorType>()

  const handleGoToSandboxSelection = useCallback(() => {
    setStep(STEPS.SandboxSelectionList)
  }, [])

  const handleConnectorStepCompleted = useCallback(
    (connector: Connector) => {
      if (
        step === STEPS.ConnectorsList &&
        isSandboxConnector(connector) &&
        !isUniqueSandboxAvailableInType(connectors, connector)
      ) {
        // user is attempting select a sandbox connector in connector List -> go to sandbox selection flow
        setSelectedSandboxType(connector.type)
        handleGoToSandboxSelection()
        return
      }
      track(TrackEventName.LIST_ITEM_CLICKED, {
        connector,
      })
      onEventCallback({
        event: 'SELECTED_INSTITUTION',
        connector,
      })
      setStep(STEPS.ConnectorForm)
      onConnectorSelect(connector)
      setFormCredentials(undefined)
    },
    [connectors, handleGoToSandboxSelection, onConnectorSelect, step],
  )

  const handleGoToMissingBankPage = useCallback(() => {
    track(TrackEventName.LINK_CLICKED, {
      location: 'connectorsList',
      linkTo: 'missingBankPage',
    })
    setStep(STEPS.MissingBankPage)
  }, [])

  const handleConnectSuccess = useCallback(() => {
    setStep(STEPS.ConnectSuccess)
  }, [])

  const handleGoToConnectorsList = useCallback(() => {
    onConnectorSelect(null)
    onClearConnectItem()
    setStep(STEPS.ConnectorsList)
  }, [onClearConnectItem, onConnectorSelect])

  const handleConnectorFormSubmitRetry = useCallback(() => {
    if (!item) {
      // nothing to retry!
      return
    }
    onClearConnectItemError(item.id)
    setStep(STEPS.ConnectorForm)
    const is1StepMfa = item.connector.credentials.some(
      (c: ConnectorCredential) => c.mfa,
    )
    if (is1StepMfa) {
      // not retry since we need a new token
      return
    }
    onUpdateItem(item.id)
  }, [item, onClearConnectItemError, onUpdateItem])

  const [formCredentials, setFormCredentials] = useState<Parameters>()
  const handleCredentialsFormSubmit = useCallback((credentials: Parameters) => {
    setFormCredentials(credentials)
  }, [])

  const [connectorsSearch, setConnectorsSearch] = useState<string>()
  const handleConnectorsSearchChange = useCallback(
    (connectorsSearch_: string) => {
      if (connectorsSearch_ === connectorsSearch) {
        // no change
        return
      }

      setConnectorsSearch(connectorsSearch_)
    },
    [connectorsSearch],
  )

  const [selectedConnectorTab, setSelectedConnectorTab] =
    useState<ConnectorTypeTab>()
  const handleSelectedConnectorTabChange = useCallback(
    (selectedConnectorTab_: ConnectorTypeTab) => {
      if (selectedConnectorTab_ === selectedConnectorTab) {
        // no change
        return
      }
      setSelectedConnectorTab(selectedConnectorTab_)
    },
    [selectedConnectorTab],
  )

  const connectDocumentationHref = 'https://docs.pluggy.ai/docs/introduction'
  const connectDocumentationI18nKey = 'auth.invalid_connect_token.link'
  const connectDocumentationI18nText = t(connectDocumentationI18nKey)
  const trackGoToConnectDocumentation = useCallback(() => {
    track(TrackEventName.LINK_CLICKED, {
      location: 'welcomePage',
      text: connectDocumentationI18nText,
      i18nKey: connectDocumentationI18nKey,
      linkTo: connectDocumentationHref,
    })
  }, [connectDocumentationI18nText])

  const isInvalidConnectTokenAuthError =
    authError === t('auth.invalid_connect_token.message')

  const isConnectRunningInDashboardApplication =
    isRunningInDashboardApplication()

  const isCurrentStepSandboxConnectorForm =
    selectedConnector &&
    isSandboxConnector(selectedConnector) &&
    step === STEPS.ConnectorForm &&
    !selectedConnector.isOpenFinance

  const footer =
    isDemoApplication &&
    !isConnectRunningInDashboardApplication &&
    !isCurrentStepSandboxConnectorForm ? (
      <div className={'footer demo-application'}>
        {t('footer.demo-application-text')}
      </div>
    ) : isCurrentStepSandboxConnectorForm && !isCloseModalOpen ? (
      <div className="footer credentials">
        Credentials: <b>user</b>: user-ok | <b>password</b>: password-ok
      </div>
    ) : null

  const body =
    authError || isSelectedConnectorIdMissingError ? (
      <div className={'error-container auth-error'}>
        {isInvalidConnectTokenAuthError ? (
          <>
            {/* TODO refactor to a AccessTokenAuthError component & improve UI
               tkt: https://pluggy.atlassian.net/browse/DVT-573
           */}
            <Alert
              type={'error'}
              message={t('auth.invalid_connect_token.alert')}
            />
            <p>{t('auth.invalid_connect_token.message')}</p>
            <div className={'action-link-container'}>
              <a
                href={connectDocumentationHref}
                onClick={trackGoToConnectDocumentation}
                className={'link'}
                target="_blank"
                rel="noopener noreferrer"
              >
                {connectDocumentationI18nText}
              </a>
            </div>
          </>
        ) : (
          <>
            {/* TODO refactor to a AuthError component */}
            <Alert
              type={'error'}
              size={'medium'}
              message={
                authError ? (
                  <Trans
                    i18nKey={authError}
                    components={{
                      a: (
                        // we don't need content inside the anchor because we are
                        // interpolating the element
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                          href={'https://docs.pluggy.ai/docs/updating-an-item'}
                          className={'link'}
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      ),
                    }}
                  />
                ) : (
                  isSelectedConnectorIdMissingError
                )
              }
            />
          </>
        )}
      </div>
    ) : (
      <>
        {isCloseModalOpen && (
          <CloseConfirmationModal
            onClose={handleClose}
            onContinueInBackground={handleConnectInBackground}
            item={item}
            canUserAbortConnection={canUserAbortConnection}
          />
        )}
        {step === STEPS.Welcome && (
          <WelcomeComponent
            onNext={handleWelcomeStepCompleted}
            onGoToTermsPage={handleGoToTermsPage}
            onGoToPrivacyPolicyPage={handleGoToPrivacyPolicyPage}
            companyName={companyName}
          />
        )}
        {step === STEPS.TermsPage && <TermsPageComponent />}
        {step === STEPS.PrivacyPolicyPage && <PrivacyPolicyPageComponent />}
        {step === STEPS.ConnectorsList && (
          <ConnectorsListComponent
            isLoading={isAnyLoading}
            connectors={connectors}
            connectorTabsOrder={connectorTabsOrder}
            withImages={displayInstitutionLogos}
            onNext={handleConnectorStepCompleted}
            search={connectorsSearch}
            onSearchChange={handleConnectorsSearchChange}
            selectedConnectorTab={selectedConnectorTab}
            onSelectedConnectorTabChange={handleSelectedConnectorTabChange}
            onGoToMissingBankPage={handleGoToMissingBankPage}
            withRegulatedTags={withRegulatedTags}
          />
        )}
        {step === STEPS.SandboxSelectionList && selectedSandboxType && (
          <SandboxSelectionListComponent
            connectorsList={connectors}
            onSandboxConnectorSelect={handleConnectorStepCompleted}
            selectedConnectorType={selectedSandboxType}
          />
        )}
        {step === STEPS.OpenFinanceFAQ && <OpenFinanceFAQComponent />}
        {step === STEPS.MissingBankPage && (
          <MissingBankPageComponent
            onGoBack={handleGoBack}
            onNext={handleConnectorStepCompleted}
            displayInstitutionLogos={displayInstitutionLogos}
          />
        )}
        {step === STEPS.ConnectorForm &&
          (!selectedConnector ? (
            isAnyLoading ? (
              <ConnectFormSkeletonLoader />
            ) : (
              <div className={'error-container'}>
                <Alert
                  type={'error'}
                  message={t('connectorForm.update.state.error')}
                />
                <Image centered src="../error.png" alt={'Oops! Auth error'} />
              </div>
            )
          ) : (
            <ConnectForm
              item={item}
              isUpdate={isUpdate}
              onCredentialsSubmit={handleCredentialsFormSubmit}
              credentials={formCredentials}
              onRetry={handleConnectorFormSubmitRetry}
              onMfaStageChange={handleMfaFormStageChange}
              onFormLoadingStateChange={handleFormLodingStateChange}
              onGoToFAQPage={goToFaqPage}
            />
          ))}
        {[STEPS.ConnectorStatus, STEPS.ConnectSuccess].includes(step) &&
          selectedConnector &&
          item && (
            <ConnectStatus
              hideConnectStatusStepDetail={hideConnectStatusStepDetail}
              connector={selectedConnector}
              item={item}
              onGoToErrorStatus={handleGoToErrorStatus}
              onClose={handleClose}
              onSuccess={handleConnectSuccess}
              isUpdate={isUpdate}
            />
          )}
        {STEPS.ConnectError === step && selectedConnector && (
          <ErrorStatus
            connector={selectedConnector}
            item={item}
            itemError={itemError}
            onClose={handleClose}
            onGoToConnectorsList={handleGoToConnectorsList}
            onRetry={handleConnectorFormSubmitRetry}
            displayInstitutionLogos={displayInstitutionLogos}
            hasPreselectedConnector={preselectedConnector !== undefined}
          />
        )}
      </>
    )

  const classNames = ['Connect', themeClassName]
  if (isRunningAsZoidComponentInstance()) {
    classNames.push('is-modal')
  }
  const containerClassName = classNames.join(' ')

  const currentStepName = STEPS[step]

  if (isClosed && isRunningAsZoidComponentInstance()) {
    // app has been closed, unmount all components, then window cleans and closes
    return null
  }

  return (
    <div className={containerClassName}>
      <ConnectHeader
        isWelcomeStepSkipped={Boolean(isWelcomeStepSkipped)}
        isWelcomeStepLogoHidden={Boolean(hideWelcomeStepLogo)}
        companyName={companyName}
        logo={logo}
        logoBackground={logoBackground}
        logoBackgroundCollapsed={logoBackgroundCollapsed || logo}
        isUpdate={isUpdate}
        step={step}
        selectedConnector={selectedConnector}
        onGoBack={handleGoBack}
        onClose={handleIconCloseClick}
        isCloseConfirmationModal={isCloseModalOpen}
        formMfaStage={currentMfaStage}
        isAuthError={Boolean(authError)}
        hasPreselectedConnectorId={preselectedConnector !== undefined}
      />
      {/* only handle title outside Privacy and Terms page since they are fully scrollable components */}
      {(STEPS.TermsPage === step || STEPS.PrivacyPolicyPage === step) && (
        <Title className={'connect-title'}>
          <Trans
            i18nKey={getTermsAndConditionsTitleKey({
              step,
            })}
          />
        </Title>
      )}

      <div
        className={`content ${currentStepName} ${
          isCloseModalOpen ? 'close-modal-open' : ''
        }`}
      >
        {body}
      </div>
      {footer}
    </div>
  )
}

export default React.memo(Connect)
