import React, { useCallback } from 'react'

import { Input } from '@pluggyai/ui'

import { Props } from './CooperativeFlowSelection.types'

import './CooperativeFlowSelection.css'

const CooperativeFlowSelection = ({
  onSelect,
  selected,
  cooperativeOptions,
  disabled,
}: Props) => {
  const handleRadioSelectInputChange = useCallback(
    ({ _name, value }: { _name?: string; value: string }): void => {
      onSelect(value)
    },
    [onSelect],
  )
  return (
    <div className={'CooperativeFlowSelection'}>
      <Input
        type={'radio-select'}
        onChange={handleRadioSelectInputChange}
        value={selected}
        options={cooperativeOptions || []}
        // these are mandatory props, mocked them because they are not used in the UI or logic
        label={'Cooperative Flow'}
        name={'cooperativeFlow'}
        disabled={disabled}
      />
    </div>
  )
}

export default React.memo(CooperativeFlowSelection)
