import { AnyAction } from 'redux'

export function removeLast<T>(
  actions: T[],
  comparator: (action: T) => boolean,
): T[] {
  const last = actions.filter(comparator).pop()
  return actions.filter((action) => action !== last)
}

// action type is the part after [] brackets
export const getType = (action: AnyAction): string =>
  action.type.slice(action.type.indexOf(']') + 1)

// action status is the part inside [] brackets
export const getStatus = (action: AnyAction): string =>
  action.type.match(/\[(\w+)]/)?.[1].toUpperCase()
