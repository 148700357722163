import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Dropdown, DropdownOption } from '@pluggyai/ui'
import type { Connector } from 'pluggy-js'

import { TrackEventName } from '../../../../modules/analytics/events'
import { track } from '../../../../modules/analytics/utils'
import { SANDBOX_DOCUMENTATION_LINK } from '../../../../utils/constants'
import { Title } from '../../Title'
import { ConnectorHeader } from '../ConnectorHeader'
import { Props } from './SandboxSelectionList.types'
import {
  getMockedConnectorForConnectorType,
  sortSandboxConnectors,
} from './utils'

import './SandboxSelectionList.css'

const SandboxSelectionList = ({
  connectorsList,
  onSandboxConnectorSelect,
  selectedConnectorType,
}: Props) => {
  const { t } = useTranslation()

  const onlySandboxConnectorsListSorted = sortSandboxConnectors(
    connectorsList,
    selectedConnectorType,
  )

  // since the most used sandbox per connector type is the basic flow, we set it as default (they are the first in the list)
  const [sandboxBasicFlow] = onlySandboxConnectorsListSorted

  const [currentSelectedSandbox, setCurrentSelectedSandbox] = useState<
    Connector | undefined
  >(sandboxBasicFlow)

  const sandboxDocumentationI18nKey =
    'sandboxSelectionList.action.sandboxDocsLink'
  const sandboxDocumentationI18nText = t(sandboxDocumentationI18nKey)

  const trackLinkClick = useCallback(() => {
    track(TrackEventName.LINK_CLICKED, {
      location: 'sandboxSelectionList',
      text: sandboxDocumentationI18nText,
      i18nKey: sandboxDocumentationI18nKey,
      linkTo: SANDBOX_DOCUMENTATION_LINK,
    })
  }, [sandboxDocumentationI18nText])

  const handleSandboxConnectorSelect = useCallback(
    ({
      value,
    }: {
      name?: string
      value: DropdownOption | DropdownOption[]
    }) => {
      const valueAsConnector = value as Connector
      setCurrentSelectedSandbox(valueAsConnector)
    },
    [],
  )

  const continueButtonI18nKey = 'sandboxSelectionList.action.continue'
  const continueButtonText = t(continueButtonI18nKey)

  const handleContinueButtonClick = useCallback(() => {
    if (!currentSelectedSandbox) {
      return
    }
    track(TrackEventName.BUTTON_CLICKED, {
      text: continueButtonText,
      i18nKey: continueButtonI18nKey,
    })

    onSandboxConnectorSelect(currentSelectedSandbox)
  }, [continueButtonText, currentSelectedSandbox, onSandboxConnectorSelect])

  return (
    <div className={'SandboxSelectionList'}>
      <div className={'flow-selection-header'}>
        <Title>{t('connectorForm.header.credentials')}</Title>
        <ConnectorHeader
          connector={getMockedConnectorForConnectorType(selectedConnectorType)}
          withImage
        />
        <p className={'instruction'}>
          {t('sandboxSelectionList.dropdown.instruction')}
        </p>
        <Dropdown
          label={t('sandboxSelectionList.dropdown.instruction')}
          options={onlySandboxConnectorsListSorted}
          onChange={handleSandboxConnectorSelect}
          value={currentSelectedSandbox?.name}
        />
        <a
          href={SANDBOX_DOCUMENTATION_LINK}
          className="link"
          target="_blank"
          rel="noopener noreferrer"
          onClick={trackLinkClick}
        >
          {sandboxDocumentationI18nText}
        </a>
      </div>
      <Button
        onClick={handleContinueButtonClick}
        primary
        disabled={!currentSelectedSandbox}
      >
        {continueButtonText}
      </Button>
    </div>
  )
}

export default React.memo(SandboxSelectionList)
