import type { Connector, ConnectorType } from 'pluggy-js'

import {
  ConnectorId,
  isSandboxConnector,
  orderConnectorsByConnectorIds,
} from '../../../../modules/connector/utils'
import i18n from '../../../../utils/i18n'

const PERSONAL_SANDBOX_CONNECTOR_IDS_ORDER: ConnectorId[] = [
  ConnectorId.BR_PERSONAL_PLUGGY_BANK,
  ConnectorId.BR_PERSONAL_PLUGGY_BANK_MFA_1STEP,
  ConnectorId.BR_PERSONAL_PLUGGY_BANK_MFA_2STEP,
  ConnectorId.BR_PERSONAL_PLUGGY_BANK_MFA_1_2_STEP_MULTI_ACCOUNTS,
  ConnectorId.BR_PERSONAL_PLUGGY_BANK_COOPERATIVE_SELECTION,
]

const BUSINESS_SANDBOX_CONNECTOR_IDS_ORDER: ConnectorId[] = [
  ConnectorId.BR_BUSINESS_PLUGGY_BANK,
  ConnectorId.BR_BUSINESS_PLUGGY_BANK_MFA_2STEP,
  ConnectorId.BR_BUSINESS_PLUGGY_BANK_CERTIFICATE,
  ConnectorId.BR_BUSINESS_PLUGGY_BANK_COOPERATIVE_SELECTION,
]

const INVESTMENT_SANDBOX_CONNECTOR_IDS_ORDER: ConnectorId[] = [
  ConnectorId.BR_INVESTMENT_PLUGGY_BANK,
  ConnectorId.BR_INVESTMENT_PLUGGY_BANK_MFA_1STEP,
  ConnectorId.BR_INVESTMENT_PLUGGY_BANK_MFA_2STEP,
]

export function sortSandboxConnectors(
  connectors: Connector[],
  connectorType: ConnectorType,
): Connector[] {
  const onlySandboxConnectorsListFromType = connectors.filter(
    (connector) =>
      isSandboxConnector(connector) && connector.type === connectorType,
  )

  const connectorIdsOrder: ConnectorId[] = []

  if (connectorType === 'PERSONAL_BANK') {
    connectorIdsOrder.push(...PERSONAL_SANDBOX_CONNECTOR_IDS_ORDER)
  }

  if (connectorType === 'BUSINESS_BANK') {
    connectorIdsOrder.push(...BUSINESS_SANDBOX_CONNECTOR_IDS_ORDER)
  }

  if (connectorType === 'INVESTMENT') {
    connectorIdsOrder.push(...INVESTMENT_SANDBOX_CONNECTOR_IDS_ORDER)
  }

  return orderConnectorsByConnectorIds(
    onlySandboxConnectorsListFromType,
    connectorIdsOrder,
  )
}

const MOCK_SANDBOX_CONNECTOR: Connector = {
  id: 0,
  name: 'Sandbox',
  primaryColor: 'ef294b',
  institutionUrl: 'https://pluggy.ai',
  country: 'BR',
  type: 'PERSONAL_BANK',
  credentials: [
    {
      label: 'User',
      name: 'user',
      type: 'text',
      placeholder: '',
      validation: '^user-.{2,50}$',
      validationMessage: 'O user deve começar com "user-"',
      optional: false,
    },
    {
      label: 'Password',
      name: 'password',
      type: 'password',
      placeholder: '',
      validation: '^.{6,20}$',
      validationMessage: 'A senha deve ter entre 6 e 20 caracteres',
      optional: false,
    },
  ],
  imageUrl: 'https://cdn.pluggy.ai/assets/connector-icons/sandbox.svg',
  hasMFA: false,
  health: {
    status: 'ONLINE',
    stage: null,
  },
  products: [
    'ACCOUNTS',
    'CREDIT_CARDS',
    'TRANSACTIONS',
    'PAYMENT_DATA',
    'INVESTMENTS',
    'INVESTMENTS_TRANSACTIONS',
    'OPPORTUNITIES',
    'IDENTITY',
  ],
  createdAt: new Date('2020-09-07T00:08:06.588Z'),
  isOpenFinance: false,
  isSandbox: true,
  supportsPaymentInitiation: false,
}

export function getMockedConnectorForConnectorType(
  connectorType: ConnectorType,
): Connector {
  return {
    ...MOCK_SANDBOX_CONNECTOR,
    name: i18n.t(`sandboxSelectionList.sandbox-name.${connectorType}`),
    type: connectorType,
  }
}
