import { AnyAction } from 'redux'

import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { TermsOfUseState } from './reducer'

export const getState = (state: RootState): TermsOfUseState =>
  state.termsOfUse as TermsOfUseState
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType: (state: LoadingState, type: string) => boolean = (
  state,
  type,
) => state.some((action: AnyAction) => action.type === type)

export const getTermsAndConditions = (state: RootState): string | null =>
  getData(state)?.termsAndConditions || null

export const getPrivacyPolicy = (state: RootState): string | null =>
  getData(state)?.privacyPolicy || null
