import React from 'react'
import { useTranslation } from 'react-i18next'

import { InformationIcon } from './InformationIcon'
import { Props } from './StepSlowMessage.types'

import './StepSlowMessage.css'

const StepSlowMessage = ({ averageConnectorTime }: Props) => {
  const { t } = useTranslation()
  return (
    <div className={'StepSlowMessage'}>
      <InformationIcon />
      <span className={'message'}>
        {t('connectStatus.step-slow.message', {
          count: averageConnectorTime,
        })}
      </span>
    </div>
  )
}

export default React.memo(StepSlowMessage)
