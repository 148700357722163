import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import Fuse from 'fuse.js'

import { useOnClickOutside } from '../../../../../utils/hooks/useOnClickOutside'
import { Props } from './SandboxCredentialSuggestion.types'
import { getInputSandboxCredentials } from './utils'

import './SandboxCredentialSuggestion.css'

const SandboxCredentialSuggestion = ({
  onSandboxCredentialSuggestionSelect,
  selectedConnector,
  inputType,
  inputName,
  isInputFocused,
  inputValue = '',
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [credentialsSuggestion, setCredentialsSuggestionList] = useState<
    Fuse.FuseResult<string>[]
  >([])

  useEffect(() => {
    if (!isInputFocused) {
      return
    }

    setIsOpen(true)
  }, [isInputFocused])

  const sandboxCredentialsList = getInputSandboxCredentials(
    selectedConnector,
    inputType,
    inputName,
  )

  const fuse = useMemo(
    () =>
      new Fuse(sandboxCredentialsList, {
        isCaseSensitive: false,
        threshold: 0.1,
        keys: ['result'],
      }),
    [sandboxCredentialsList],
  )

  useEffect(() => {
    const result = fuse.search(inputValue)

    setCredentialsSuggestionList(result)
  }, [fuse, inputValue])

  const handleSandboxCredentialSuggestionClick = useCallback(
    (credentail: string) => {
      onSandboxCredentialSuggestionSelect({
        [inputName]: credentail,
      })
      setIsOpen(false)
    },
    [inputName, onSandboxCredentialSuggestionSelect],
  )

  const handleCloseSandboxCredentialSuggestion = useCallback(() => {
    setIsOpen(false)
  }, [])

  const sandboxCredentialSuggestion = useRef(null)

  useOnClickOutside(
    sandboxCredentialSuggestion,
    handleCloseSandboxCredentialSuggestion,
  )

  if (!inputValue || !isOpen || credentialsSuggestion.length === 0) {
    return null
  }

  return (
    <div
      className={'SandboxCredentialSuggestion'}
      ref={sandboxCredentialSuggestion}
    >
      {credentialsSuggestion.map(({ item: result }) => (
        <button
          tabIndex={0}
          type={'button'}
          className={'result'}
          key={result}
          onClick={() => handleSandboxCredentialSuggestionClick(result)}
        >
          {result}
        </button>
      ))}
    </div>
  )
}

export default memo(SandboxCredentialSuggestion)
