import { connect } from 'react-redux'

import { getWelcomeStepCustomButtonText } from '../../../../modules/config/selectors'
import { RootState } from '../../../../modules/reducer'
import Welcome from './Welcome'
import { MapDispatch, MapDispatchProps, MapStateProps } from './Welcome.types'

const mapState = (state: RootState): MapStateProps => ({
  customWelcomeButtonText: getWelcomeStepCustomButtonText(state),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({
  // nothing here, yet
})

export default connect(mapState, mapDispatch)(Welcome)
