import React, { useEffect, useState } from 'react'

import { Image } from 'semantic-ui-react'

import { Props } from './CaixaQrCodes.types'

const CaixaQrCodes = ({ qrCodes }: Props) => {
  const qrCodesArray = qrCodes.split(',')
  const [qrCodeIndex, setQrCodeIndex] = useState(0)
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setQrCodeIndex((currentQrCodeIndex) => currentQrCodeIndex + 1)
    }, 5000)
    return () => clearTimeout(timeoutId) // Cleanup the timeout on component unmount or when effect re-runs
  }, [qrCodeIndex])

  return (
    <Image
      className={'field-image'}
      centered
      src={`data:image/png;base64,${
        qrCodesArray[qrCodeIndex % qrCodesArray.length]
      }`}
    />
  )
}

export default React.memo(CaixaQrCodes)
