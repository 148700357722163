import { connect } from 'react-redux'

import { getServerDateDelta } from '../../../../../../modules/config/selectors'
import { RootState } from '../../../../../../modules/reducer'
import WaitUserAction from './WaitUserAction'
import { MapStateProps } from './WaitUserAction.types'

const mapState = (state: RootState): MapStateProps => ({
  serverDateDeltaInMs: getServerDateDelta(state),
})

export default connect(mapState)(WaitUserAction)
