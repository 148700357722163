import { addBreadcrumb, Severity } from '@sentry/react'
import axios, { AxiosInstance, AxiosResponse } from 'axios'
import axiosBetterStacktrace from 'axios-better-stacktrace'
import * as retryAxios from 'retry-axios'

const AXIOS_DATE_REQUEST_RETRY_TIMES = 5
const AXIOS_DATE_REQUEST_RETRY_DELAY_MS = 300

const { REACT_APP_PLUGGY_AUTH_API_URL: authApiUrl } = process.env

if (!authApiUrl) {
  throw new Error('Missing environment variable REACT_APP_PLUGGY_AUTH_API_URL')
}

const ENVIRONMENT_TYPES = ['DEMO', 'DEVELOPMENT', 'PRODUCTION'] as const
export type EnvironmentType = (typeof ENVIRONMENT_TYPES)[number]

export type ConnectConfigText = { en: string; pt: string }

export type ConnectConfig = {
  text: {
    button: {
      connectFormStep: {
        submit: ConnectConfigText
        submitLoading: ConnectConfigText
      }
      welcomeStep: {
        continue: ConnectConfigText
      }
    }
  }
  companyName: string
  brand: {
    logo: string | null
  }
  styles: {
    colors: {
      primary: string | null
    }
    borderRadius: number | null
  }
  connectorsFilters: {
    ids: number[] | null
  }
  totalItems: number
  itemsCreateLimit: number
  isProductionEnabled: boolean
  environment: EnvironmentType
  teamId: string | null
}

export class AuthApiClient {
  private service: AxiosInstance | undefined

  public connectToken: string

  constructor(connectToken: string) {
    this.connectToken = connectToken
  }

  /**
   * Get or initialize the Axios client instance
   */
  protected getServiceInstance(): AxiosInstance {
    if (this.service === undefined) {
      this.service = axios.create({
        headers: {
          Authorization: `Bearer ${this.connectToken}`,
        },
      })

      // extend axios instance to include full stacktraces
      axiosBetterStacktrace(this.service, {
        errorMsg: this.constructor.name,
      })
    }
    return this.service
  }

  async getConnectConfig(): Promise<AxiosResponse<ConnectConfig>> {
    return this.getServiceInstance().get(`${authApiUrl}/connect/config`)
  }

  async getTermsAndConditions(): Promise<AxiosResponse<string>> {
    return this.getServiceInstance().get(
      `${authApiUrl}/connect/config/terms-and-conditions`,
    )
  }

  async getPrivacyPolicy(): Promise<AxiosResponse<string>> {
    return this.getServiceInstance().get(
      `${authApiUrl}/connect/config/privacy-policy`,
    )
  }

  async getApiDate(): Promise<AxiosResponse<{ date: number }>> {
    this.getServiceInstance().defaults.raxConfig = {
      // The config instance to use for requests
      instance: this.getServiceInstance(),
      retry: AXIOS_DATE_REQUEST_RETRY_TIMES,
      // retry even if there is no response, it should always get a response though
      noResponseRetries: AXIOS_DATE_REQUEST_RETRY_TIMES,
      retryDelay: AXIOS_DATE_REQUEST_RETRY_DELAY_MS,
      backoffType: 'static',
      // retry in all cases 1xx - 4xx - 5xx
      statusCodesToRetry: [
        [100, 199],
        [400, 429],
        [500, 599],
      ],
      onRetryAttempt(error) {
        const retryConfig = retryAxios.getConfig(error)
        if (!retryConfig) {
          // should never happen, we are narrowing the type
          return
        }

        // add a breadcrumb to the current scope
        addBreadcrumb({
          category: 'auth-api',
          message: `Retrying request to /date auth-api endpoint, attempt ${retryConfig.currentRetryAttempt}`,
          level: Severity.Info,
          data: {
            error,
          },
        })
      },
    }
    // attach retryAxios (rax) interceptor to axios
    retryAxios.attach(this.getServiceInstance())
    return this.getServiceInstance().get(`${authApiUrl}/date`)
  }
}
