import { LoadingState } from '../loading/reducer'
import { isLoadingType } from '../loading/selectors'
import { RootState } from '../reducer'
import {
  CREATE_ITEM_REQUEST,
  OAUTH_ITEM_SUCCESS_CALLBACK,
  POLL_ITEM_START,
  SEND_MFA_ITEM_REQUEST,
  StartPollingItemAction,
  UPDATE_ITEM_REQUEST,
} from './actions'
import { CurrentPollingState, ItemDataState, ItemState } from './reducer'

export const getState = (state: RootState): ItemState => state.item

export const getData = (state: RootState): ItemDataState => getState(state).data
export const getLoading = (state: RootState): LoadingState =>
  getState(state).loading
export const getError = (state: RootState): ItemState['error'] =>
  getState(state).error

export const getCurrentPolling = (state: RootState): CurrentPollingState => {
  return getData(state).currentPolling
}

export const isPollingItemAnyAction = (state: RootState): boolean =>
  isLoadingType(getLoading(state), POLL_ITEM_START)

export const getActivePollingActions = (
  state: RootState,
): StartPollingItemAction[] =>
  getLoading(state).filter(
    (loadingState): loadingState is StartPollingItemAction =>
      loadingState.type === POLL_ITEM_START,
  )

const isPollingActionTypeLoading = (
  state: RootState,
  pollingTypePredicate: (pollingAction: StartPollingItemAction) => boolean,
): boolean => getActivePollingActions(state).some(pollingTypePredicate)

export const isPollingItemCreate = (state: RootState): boolean =>
  isPollingActionTypeLoading(
    state,
    (pollingAction) =>
      pollingAction.payload.originalAction.type === CREATE_ITEM_REQUEST ||
      pollingAction.payload.originalAction.type === OAUTH_ITEM_SUCCESS_CALLBACK,
  )

export const isPollingItemUpdate = (state: RootState): boolean =>
  isPollingActionTypeLoading(
    state,
    (pollingAction) =>
      pollingAction.payload.originalAction.type === UPDATE_ITEM_REQUEST,
  )

export const isPollingItemSendMfa = (state: RootState): boolean =>
  isPollingActionTypeLoading(
    state,
    (pollingAction) =>
      pollingAction.payload.originalAction.type === SEND_MFA_ITEM_REQUEST,
  )
