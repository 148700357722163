/**
 * Return distinct values intersection of arrays.
 * If any of them is not defined then it's ignored.
 * If none is defined, returns undefined.
 *
 * @param arr1
 * @param arr2
 */
export const intersectDefined = <T>(
  arr1: T[] | undefined,
  arr2: T[] | undefined,
): T[] | undefined => {
  if (!arr1 && !arr2) {
    return undefined
  }
  if (!arr1) {
    return arr2
  }
  if (!arr2) {
    return arr1
  }
  const arr2Set = new Set(arr2)
  return arr1.filter((x) => arr2Set.has(x))
}
