import { action } from 'typesafe-actions'

/**
 *  Terms of Use Fetch
 */

export const FETCH_TERMS_OF_USE_REQUEST = '[Request] Terms of Use Fetch'
export const FETCH_TERMS_OF_USE_SUCCESS = '[Success] Terms of Use Fetch'
export const FETCH_TERMS_OF_USE_FAILURE = '[Failure] Terms of Use Fetch'

export const fetchTermsOfUseRequest = () => action(FETCH_TERMS_OF_USE_REQUEST)
export const fetchTermsOfUseSuccess = ({
  termsAndConditions,
  privacyPolicy,
}: {
  termsAndConditions: string
  privacyPolicy: string
}) => action(FETCH_TERMS_OF_USE_SUCCESS, { termsAndConditions, privacyPolicy })
export const fetchTermsOfUseFailure = (error: string) =>
  action(FETCH_TERMS_OF_USE_FAILURE, { error })

export type FetchTermsOfUseRequestAction = ReturnType<
  typeof fetchTermsOfUseRequest
>
export type FetchTermsOfUseSuccessAction = ReturnType<
  typeof fetchTermsOfUseSuccess
>
export type FetchTermsOfUseFailureAction = ReturnType<
  typeof fetchTermsOfUseFailure
>
