import { connect } from 'react-redux'

import { RootState } from '../../../../modules/reducer'
import { FETCH_TERMS_OF_USE_REQUEST } from '../../../../modules/termsOfUse/actions'
import {
  getError,
  getLoading,
  getTermsAndConditions,
  isLoadingType,
} from '../../../../modules/termsOfUse/selectors'
import TermsPage from './TermsPage'
import { MapDispatch, MapDispatchProps, MapStateProps } from './TermsPage.types'

const mapState = (state: RootState): MapStateProps => ({
  termsAndConditions: getTermsAndConditions(state),
  isLoading: isLoadingType(getLoading(state), FETCH_TERMS_OF_USE_REQUEST),
  error: getError(state),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({
  // nothing here, yet
})

export default connect(mapState, mapDispatch)(TermsPage)
