import { connect } from 'react-redux'

import type { Connector, Parameters } from 'pluggy-js'

import {
  getConfig,
  getCurrentCustomization,
  getFormStepCustomButtonTexts,
  getServerDateDelta,
  isItemsCreateQuotaExceeded,
} from '../../../../modules/config/selectors'
import { getSelectedConnector } from '../../../../modules/connector/selectors'
import {
  clearConnectItem,
  clearConnectItemError,
  CREATE_ITEM_REQUEST,
  createItemRequest,
  FETCH_ITEM_REQUEST,
  SEND_MFA_ITEM_REQUEST,
  sendMFAItemRequest,
  UPDATE_ITEM_REQUEST,
  updateItemRequest,
} from '../../../../modules/item/actions'
import {
  getError as getItemError,
  getLoading,
  isPollingItemCreate,
  isPollingItemSendMfa,
  isPollingItemUpdate,
} from '../../../../modules/item/selectors'
import { isLoadingType } from '../../../../modules/loading/selectors'
import { RootState } from '../../../../modules/reducer'
import ConnectForm from './ConnectForm'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './ConnectForm.types'

const mapState = (state: RootState): MapStateProps => ({
  selectedConnector: getSelectedConnector(state) as Connector,
  error: getItemError(state),
  withImage: getCurrentCustomization(state).displayInstitutionLogos || true,
  isItemFetching: isLoadingType(getLoading(state), FETCH_ITEM_REQUEST),
  isItemCreating: isLoadingType(getLoading(state), CREATE_ITEM_REQUEST),
  isItemUpdating: isLoadingType(getLoading(state), UPDATE_ITEM_REQUEST),
  isItemMfaSubmitting: isLoadingType(getLoading(state), SEND_MFA_ITEM_REQUEST),
  isPollingItemCreate: isPollingItemCreate(state),
  isPollingItemUpdate: isPollingItemUpdate(state),
  isPollingItemMfaSubmit: isPollingItemSendMfa(state),
  isItemsCreateQuotaExceeded: isItemsCreateQuotaExceeded(state),
  itemsCreateLimit: getConfig(state)?.itemsCreateLimit || 100,
  serverDateDeltaInMs: getServerDateDelta(state),
  customFormButtonText: getFormStepCustomButtonTexts(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onCreateItem: (connector: Connector, parameters: Parameters) =>
    dispatch(createItemRequest(connector, parameters)),
  onSendMFA: (itemId: string, parameters: Parameters) =>
    dispatch(sendMFAItemRequest(itemId, parameters)),
  onClearConnectItem: () => dispatch(clearConnectItem()),
  onClearConnectItemError: (itemId: string) =>
    dispatch(clearConnectItemError(itemId)),
  onUpdateItem: (itemId: string, parameters?: Parameters) =>
    dispatch(updateItemRequest(itemId, parameters)),
})

export default connect(mapState, mapDispatch)(ConnectForm)
