import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  FETCH_CONFIG_FAILURE,
  FETCH_CONFIG_REQUEST,
  FETCH_CONFIG_SUCCESS,
  FetchConfigFailureAction,
  FetchConfigRequestAction,
  FetchConfigSuccessAction,
} from './actions'
import { ConnectConfig } from './types'

export type ConfigState = {
  data: ConnectConfig | null
  loading: LoadingState
  error: string | null
}

const INITIAL_STATE: ConfigState = {
  data: null,
  loading: [],
  error: null,
}

type ConfigReducerAction =
  | FetchConfigRequestAction
  | FetchConfigSuccessAction
  | FetchConfigFailureAction

export function configReducer(
  state: ConfigState = INITIAL_STATE,
  action: ConfigReducerAction,
): ConfigState {
  switch (action.type) {
    case FETCH_CONFIG_REQUEST:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
      }
    case FETCH_CONFIG_SUCCESS:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: action.payload.config,
      }
    case FETCH_CONFIG_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
        data: null,
      }
    default:
      return state
  }
}
