import { useCallback } from 'react'

import './CloseIcon.css'

type Props = {
  onClick: () => void
  className?: string
}

const CloseIcon = ({ onClick, className }: Props): JSX.Element => {
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key !== 'Enter') {
        return
      }
      onClick()
    },
    [onClick],
  )

  return (
    <div
      onClick={onClick}
      className={`CloseIcon ${className || ''}`}
      role="button"
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
      >
        <g fill="none" fillRule="evenodd">
          <g fill="#292828">
            <g>
              <path
                d="M15.057 0L8 7.057 0.943 0 0 0.943 7.057 8 0 15.057 0.943 16 8 8.943 15.057 16 16 15.057 8.943 8 16 0.943z"
                transform="translate(-280 -24) translate(280 24)"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}
export default CloseIcon
