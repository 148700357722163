import { connect } from 'react-redux'

import type { Item } from 'pluggy-js'

import { deleteItemRequest } from '../../../modules/item/actions'
import { RootState } from '../../../modules/reducer'
import CloseConfirmationModal from './CloseConfirmationModal'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './CloseConfirmationModal.types'

const mapState = (_state: RootState): MapStateProps => ({
  // nothing here yet
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onDeleteItem: (item: Item) => dispatch(deleteItemRequest(item)),
})

export default connect(mapState, mapDispatch)(CloseConfirmationModal)
