import type { ExecutionStatus, Item } from 'pluggy-js'

import {
  ConnectorId,
  isAilosCreditCardConnector,
  isCaixaConnector,
} from '../connector/utils'

export function toItem(remoteItem: Item): Item & {
  accounts: []
  investments: []
} {
  const { lastUpdatedAt, createdAt, updatedAt, ...item } = remoteItem
  return {
    ...item,
    accounts: [],
    investments: [],
    lastUpdatedAt: lastUpdatedAt && new Date(lastUpdatedAt),
    createdAt: new Date(createdAt),
    updatedAt: new Date(updatedAt),
  }
}

// require to start over on bad initial credentials
const ITEM_STATUSES_REQUIRE_ALL_CREDENTIALS = ['LOGIN_ERROR']

// require to start over on some 'unrecoverable' errors
const EXECUTION_STATUSES_REQUIRE_ALL_CREDENTIALS = [
  'ACCOUNT_LOCKED',
  'ALREADY_LOGGED_IN',
  'ACCOUNT_NEEDS_ACTION',
]

/**
 * Bradesco PF captcha is a special case where the user needs to solve a captcha
 * to continue the connection flow. In this case, we don't want to require all
 * credentials to be re-entered.
 *
 * @param item - item to check
 * @returns boolean
 */
function isBusinessBradescoCaptchaAccountNeedsActionCase({
  executionStatus,
  connector: { id: connectorId },
}: Item): boolean {
  return (
    connectorId === ConnectorId.BR_PERSONAL_BRADESCO &&
    executionStatus === 'ACCOUNT_NEEDS_ACTION'
  )
}

export function isAilosCreditCardMaxDeviceLimitReachedError(
  item: Item,
): boolean {
  return (
    isAilosCreditCardConnector(item.connector) &&
    item.error?.code === 'ACCOUNT_NEEDS_ACTION' &&
    Boolean(item.error.providerMessage?.includes('quantidade máxima'))
  )
}

export function isCaixaAuthorizedDevicesLimitReachedError(
  item?: Item,
): boolean {
  return (
    (item !== undefined &&
      (item.error?.code === 'ACCOUNT_NEEDS_ACTION' ||
        item.executionStatus === 'ACCOUNT_NEEDS_ACTION') &&
      isCaixaConnector(item.connector) &&
      item.error?.providerMessage?.includes(
        'QUANTIDADE DE DISPOSITIVOS MOVEIS MAIOR QUE O PERMITIDO',
      )) ||
    false
  )
}

export function itemUpdateRequiresAllCredentials(item: Item): boolean {
  const { status: itemStatus, executionStatus } = item

  const itemStatusRequiresAllCredentials =
    ITEM_STATUSES_REQUIRE_ALL_CREDENTIALS.includes(itemStatus)
  const executionStatusRequiresAllCredentials =
    EXECUTION_STATUSES_REQUIRE_ALL_CREDENTIALS.includes(executionStatus)
  return (
    itemStatusRequiresAllCredentials ||
    (executionStatusRequiresAllCredentials &&
      !isBusinessBradescoCaptchaAccountNeedsActionCase(item))
  )
}

export const getPollingDurationInMs = (
  start: Date | null,
  end?: Date | null,
): number | null => {
  if (!start) {
    return null
  }
  if (end) {
    // if polling end date present, return the difference between the two
    return end.getTime() - start.getTime()
  }
  // polling in progress, return the difference between now and the start date
  return Date.now() - start.getTime()
}

export const FINISHED_STATUSES: ExecutionStatus[] = [
  'SUCCESS',
  'ERROR',
  'MERGE_ERROR',
  'INVALID_CREDENTIALS',
  'ALREADY_LOGGED_IN',
  'INVALID_CREDENTIALS_MFA',
  'SITE_NOT_AVAILABLE',
  'CONNECTION_ERROR',
  'ACCOUNT_NEEDS_ACTION',
  'USER_AUTHORIZATION_PENDING',
  'USER_AUTHORIZATION_NOT_GRANTED',
  'USER_INPUT_TIMEOUT',
]

/**
 * If item.status is WAITING_USER_INPUT, check if it has an oauth URL
 * in its parameter.data and retrieve it.
 *
 * @param {Item} item
 * @return {string | null} parameter oauthUrl if present, null otherwise
 */
export function getItemParameterOauthUrl(item: Item): string | null {
  return (
    (item.status === 'WAITING_USER_INPUT' &&
      item.parameter &&
      (item.parameter.type === 'text' ||
        // TODO add parameter.type 'oauth' to pluggy-js ConnectorCredential type
        (item.parameter.type as unknown as 'oauth' | undefined) === 'oauth') &&
      item.parameter.name === 'oauthCode' &&
      item.parameter.data) ||
    null
  )
}
