import { AxiosError } from 'axios'
import type { Item } from 'pluggy-js'

import { loadingReducer, LoadingState } from '../loading/reducer'
import {
  CLEAR_CONNECT_ITEM,
  CLEAR_CONNECT_ITEM_ERROR,
  ClearConnectItemAction,
  ClearConnectItemErrorAction,
  CREATE_ITEM_FAILURE,
  CREATE_ITEM_REQUEST,
  CREATE_ITEM_SUCCESS,
  CreateItemFailureAction,
  CreateItemRequestAction,
  CreateItemSuccessAction,
  DELETE_ITEM_FAILURE,
  DELETE_ITEM_REQUEST,
  DELETE_ITEM_SUCCESS,
  DeleteItemFailureAction,
  DeleteItemRequestAction,
  DeleteItemSuccessAction,
  FETCH_ITEM_FAILURE,
  FETCH_ITEM_REQUEST,
  FETCH_ITEM_SUCCESS,
  FetchItemFailureAction,
  FetchItemRequestAction,
  FetchItemSuccessAction,
  POLL_ITEM_START,
  POLL_ITEM_STOP,
  SEND_MFA_ITEM_FAILURE,
  SEND_MFA_ITEM_REQUEST,
  SEND_MFA_ITEM_SUCCESS,
  SendMFAItemFailureAction,
  SendMFAItemRequestAction,
  SendMFAItemSuccessAction,
  StartPollingItemAction,
  StopPollingItemAction,
  UPDATE_ITEM_FAILURE,
  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  UpdateItemFailureAction,
  UpdateItemRequestAction,
  UpdateItemSuccessAction,
} from './actions'
import { toItem } from './utils'

export type CurrentPollingState = {
  start: Date | null
  end: Date | null
}

export type ItemDataState = {
  item: Item | null
  lastItem: Item | null
  currentPolling: CurrentPollingState
}

export type ItemState = {
  data: ItemDataState
  loading: LoadingState
  error: string | Error | AxiosError<unknown> | null
}

const INITIAL_STATE: ItemState = {
  data: {
    item: null,
    lastItem: null,
    currentPolling: {
      start: null,
      end: null,
    },
  },
  loading: [],
  error: null,
}

type ItemReducer =
  | FetchItemRequestAction
  | FetchItemSuccessAction
  | FetchItemFailureAction
  | CreateItemRequestAction
  | CreateItemSuccessAction
  | CreateItemFailureAction
  | DeleteItemRequestAction
  | DeleteItemSuccessAction
  | DeleteItemFailureAction
  | UpdateItemFailureAction
  | UpdateItemSuccessAction
  | UpdateItemRequestAction
  | SendMFAItemFailureAction
  | SendMFAItemRequestAction
  | SendMFAItemSuccessAction
  | ClearConnectItemAction
  | ClearConnectItemErrorAction
  | StartPollingItemAction
  | StopPollingItemAction

export function itemReducer(
  state = INITIAL_STATE,
  action: ItemReducer,
): ItemState {
  switch (action.type) {
    case SEND_MFA_ITEM_REQUEST:
    case FETCH_ITEM_REQUEST:
    case UPDATE_ITEM_REQUEST:
    case DELETE_ITEM_REQUEST: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
      }
    }
    case CLEAR_CONNECT_ITEM: {
      return {
        ...state,
        data: {
          ...state.data,
          lastItem: null,
        },
        error: null,
      }
    }
    case CREATE_ITEM_REQUEST: {
      // note: in this case we don't add loading state, since
      //  we want to allow user to go back and submit more create item requests
      //  even if a previous one was still loading.
      return {
        ...state,
        data: {
          ...state.data,
          lastItem: null,
        },
        error: null,
      }
    }
    case CLEAR_CONNECT_ITEM_ERROR: {
      // clear the current connect item error state
      const { itemId } = action.payload
      const lastItemClearError: Item | null = state.data.lastItem && {
        ...state.data.lastItem,
        executionStatus: 'CREATED',
        error: null,
      }
      const {
        data: { item: currentItem },
      } = state
      const itemClearError = currentItem
        ? {
            ...currentItem,
            executionStatus: 'CREATED',
            error: null,
          }
        : null

      return {
        ...state,
        data: {
          ...state.data,
          ...(itemClearError ? { [itemId]: itemClearError } : {}),
          lastItem: lastItemClearError,
        },
        error: null,
      }
    }
    case UPDATE_ITEM_SUCCESS: {
      const { item } = action.payload
      const parsedItem = toItem(item)

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        ...(parsedItem.connector.hasMFA
          ? {
              lastItem: parsedItem,
            }
          : {
              data: {
                ...state.data,
                [parsedItem.id]: parsedItem,
              },
            }),
        data: {
          ...state.data,
          [parsedItem.id]: parsedItem,
        },
      }
    }
    case SEND_MFA_ITEM_SUCCESS: {
      const { item } = action.payload
      const parsedItem = toItem(item)

      return {
        ...state,
        data: {
          ...state.data,
          lastItem: parsedItem,
        },
        loading: loadingReducer(state.loading, action),
        error: null,
      }
    }
    case CREATE_ITEM_SUCCESS: {
      const { item } = action.payload
      const parsedItem = toItem(item)
      return {
        ...state,
        data: {
          ...state.data,
          lastItem: parsedItem,
        },
        loading: loadingReducer(state.loading, action),
        error: null,
      }
    }
    case FETCH_ITEM_SUCCESS: {
      const { item } = action.payload
      const parsedItem = toItem(item)
      const {
        data: { lastItem },
      } = state
      const isConnectItem = lastItem && lastItem.id === parsedItem.id

      const newLastItem = isConnectItem ? { ...parsedItem } : null

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          ...state.data,
          lastItem: newLastItem,
          item: isConnectItem ? state.data.item : parsedItem,
        },
      }
    }
    case DELETE_ITEM_SUCCESS: {
      const data = { ...state.data, item: null }

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data,
      }
    }
    case FETCH_ITEM_FAILURE:
    case CREATE_ITEM_FAILURE:
    case UPDATE_ITEM_FAILURE:
    case SEND_MFA_ITEM_FAILURE:
    case DELETE_ITEM_FAILURE: {
      const { error } = action.payload

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error,
      }
    }
    case POLL_ITEM_START: {
      const { pollingStartDate } = action.payload

      return {
        ...state,
        data: {
          ...state.data,
          currentPolling: {
            start: pollingStartDate,
            end: null,
          },
        },
        loading: loadingReducer(state.loading, action),
      }
    }
    case POLL_ITEM_STOP: {
      const { pollingEndDate } = action.payload

      return {
        ...state,
        data: {
          ...state.data,
          currentPolling: {
            start: state.data.currentPolling.start,
            end: pollingEndDate,
          },
        },
        loading: loadingReducer(state.loading, action),
      }
    }
    default:
      return state
  }
}
