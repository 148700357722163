import { useTranslation } from 'react-i18next'

import './GenialMfaInstructions.css'

const GenialMfaInstructions = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div className={'GenialMfaInstructions'}>
      <p className={'genial-title'}>
        <strong>
          {t('connectorForm.genial-connector-enable-token.title')}
        </strong>
      </p>
      <p className={'genial-description'}>
        {t('connectorForm.genial-connector-enable-token.instructions')}
      </p>
    </div>
  )
}

export default GenialMfaInstructions
