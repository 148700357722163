import React from 'react'

type Props = {
  src: string
  companyName: string
}

const Logo = ({ src, companyName }: Props) => {
  let classes = 'header-logo'
  if (companyName === 'Pluggy') {
    classes += ' pluggy-logo'
  }
  return <img className={classes} alt={`${companyName} logo`} src={src} />
}

export default React.memo(Logo)
