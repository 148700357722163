import { STEPS } from '../Connect'

export function stepToCssClassName(step: STEPS): string {
  const stepClassNamesMap: Record<STEPS, string> = {
    [STEPS.Welcome]: 'welcome',
    [STEPS.ConnectorsList]: 'connectors-list',
    [STEPS.ConnectorForm]: 'connector-form',
    [STEPS.ConnectorStatus]: 'connector-status',
    [STEPS.ConnectSuccess]: 'connect-success',
    [STEPS.TermsPage]: 'terms-page',
    [STEPS.PrivacyPolicyPage]: 'terms-page', // TODO replace with Privacy's own css
    [STEPS.ConnectError]: 'connect-error',
    [STEPS.MissingBankPage]: 'missing-bank-page',
    [STEPS.OpenFinanceFAQ]: 'open-finance-faq',
    [STEPS.SandboxSelectionList]: 'sandbox-selection-list',
  }
  return stepClassNamesMap[step]
}
