import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CONNECTOR_TABS, ConnectorList, ConnectorTypeTab } from '@pluggyai/ui'
import { Connector } from 'pluggy-js'
import { Grid, Loader } from 'semantic-ui-react'

import { TrackEventName } from '../../../../modules/analytics/events'
import { track } from '../../../../modules/analytics/utils'
import { Title } from '../../Title'
import { Props } from './ConnectorsList.types'
import { sortConnectorsByUsage } from './utils'

import './ConnectorsList.css'

const ConnectorsList = ({
  onNext,
  connectors,
  connectorTabsOrder,
  withImages,
  isLoading,
  onGoToMissingBankPage,
  search,
  onSearchChange,
  selectedConnectorTab,
  onSelectedConnectorTabChange,
  withRegulatedTags,
}: Props) => {
  const { t, i18n } = useTranslation()

  const [initialSelectedConnector] = useMemo(
    () =>
      Array.from(
        new Set(
          connectors
            .map(({ type }) =>
              CONNECTOR_TABS.includes(type as ConnectorTypeTab)
                ? type
                : 'OTHER',
            )
            .sort((a, b) => {
              const order = [
                'PERSONAL_BANK',
                'BUSINESS_BANK',
                'INVESTMENT',
                'OTHER',
              ]

              return order.indexOf(a) - order.indexOf(b)
            }),
        ),
      ),
    [connectors],
  )

  const [selectedConnectorType, setSelectedConnectorType] =
    useState<ConnectorTypeTab>(initialSelectedConnector as ConnectorTypeTab)

  const handleConnectorTypeSelect = useCallback(
    (connectorType: ConnectorTypeTab) => {
      onSelectedConnectorTabChange?.(connectorType)
      setSelectedConnectorType(connectorType)
    },
    [onSelectedConnectorTabChange],
  )

  const connectorsSorted = useMemo(
    () => sortConnectorsByUsage(connectors, selectedConnectorType),
    [connectors, selectedConnectorType],
  )

  const handleClear = useCallback(() => {
    track(TrackEventName.ICON_CLICKED, {
      icon: 'searchBoxCrossIcon',
      location: 'connectorsList',
    })
  }, [])

  const handleConnectorSelect = useCallback(
    (connector: Connector) => {
      const {
        name: connectorName,
        id: connectorId,
        type: connectorType,
      } = connector
      track(TrackEventName.LIST_ITEM_CLICKED, {
        location: 'connectorsList',
        connectorName,
        connectorId,
        connectorType,
      })
      onNext(connector)
    },
    [onNext],
  )

  const availableConnectorTypes: ConnectorTypeTab[] = useMemo(() => {
    const currentConnectorTypes = connectors.map(({ type }) => type)
    return CONNECTOR_TABS.filter((supportedType) =>
      currentConnectorTypes.includes(supportedType),
    )
  }, [connectors])

  const handleConnectorsSearchResult = useCallback(
    ({
      searchQuery,
      previousSearchQuery,
      selectedConnectorTypeTab: selectedConnectorType_,
      previousSelectedConnectorTypeTab,
      connectorsResult,
    }: {
      searchQuery: string
      previousSearchQuery?: string
      selectedConnectorTypeTab: ConnectorTypeTab
      previousSelectedConnectorTypeTab?: ConnectorTypeTab
      connectorsResult: Connector[]
    }) => {
      if (previousSearchQuery === undefined) {
        // user not search yet, just return to avoid track empty events
        return
      }
      const connectorNames: Connector['name'][] = []
      const connectorIds: Connector['id'][] = []
      for (const { name, id } of connectorsResult) {
        connectorIds.push(id)
        connectorNames.push(name)
      }

      track(TrackEventName.CONNECTORS_SEARCHED, {
        location: 'connectorsList',
        search: searchQuery,
        previousSearch: previousSearchQuery,
        connectorType: selectedConnectorType_,
        previousConnectorType: previousSelectedConnectorTypeTab,
        availableConnectorTypes,
        connectorNames,
        connectorIds,
      })
    },
    [availableConnectorTypes],
  )

  if (isLoading) {
    return (
      <div className="loader-container">
        <Loader size="big" active />
      </div>
    )
  }

  return (
    <div className="connector-search">
      <Title>{t('connectorsList.header')}</Title>
      <ConnectorList
        search={search}
        withImages={withImages}
        onSearchClear={handleClear}
        onSelect={handleConnectorSelect}
        connectors={connectorsSorted}
        connectorTabsOrder={connectorTabsOrder}
        searchBox
        searchBoxPlaceholder={t('connectorsList.textFilterPlaceholder')}
        missingBankButtonText={t('connectorsList.footer.missing-bank')}
        onMissingBankButtonClick={onGoToMissingBankPage}
        onSearchResult={handleConnectorsSearchResult}
        onSearchChange={onSearchChange}
        selectedConnectorTypeTab={selectedConnectorTab}
        onSelectedConnectorTypeTabChange={handleConnectorTypeSelect}
        withRegulatedTags={withRegulatedTags}
      />

      {i18n.exists('connectorsList.footer.message') && (
        <Grid.Row className={'footer-text'}>
          {t('connectorsList.footer.message')}
        </Grid.Row>
      )}
    </div>
  )
}

export default React.memo(ConnectorsList)
