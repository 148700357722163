import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@pluggyai/ui'

import { Props } from './AccountFlowSelection.types'

import './AccountFlowSelection.css'

const AccountFlowSelection = ({ selected, onSelect, disabled }: Props) => {
  const { t } = useTranslation()
  const handleSingleSelect = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement>) => {
      if (selected === 'single') {
        return
      }
      onSelect('single')
    },
    [selected, onSelect],
  )
  const handleJointSelect = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement>) => {
      if (selected === 'joint') {
        return
      }
      onSelect('joint')
    },
    [selected, onSelect],
  )

  return (
    <div className={'AccountFlowSelection'}>
      <p className={'message'}>
        {t('connectorForm.account-flow-select.message')}
      </p>
      <Button
        option
        selected={selected === 'single'}
        onClick={handleSingleSelect}
        onFocus={handleSingleSelect}
        type="button"
        disabled={disabled}
      >
        {t('connectorForm.account-flow-select.option.single')}
      </Button>
      <Button
        option
        selected={selected === 'joint'}
        onClick={handleJointSelect}
        onFocus={handleJointSelect}
        type="button"
        disabled={disabled}
      >
        {t('connectorForm.account-flow-select.option.joint')}
      </Button>
    </div>
  )
}

export default React.memo(AccountFlowSelection)
