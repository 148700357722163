import { getServerSyncedDate } from '../../../../../../modules/config/utils'

const DEFAULT_MFA_TIMEOUT = 90000 // 90 seconds

export function getUserActionRemainingTime(
  serverDateDiff: number | null,
  expiresAt?: Date,
): number {
  const dateTime = getServerSyncedDate(serverDateDiff).getTime()

  const defaultMfaExpiresAt = new Date(dateTime + DEFAULT_MFA_TIMEOUT)

  const currentMfaExpiresAt = expiresAt || defaultMfaExpiresAt

  return Math.max(currentMfaExpiresAt.getTime() - dateTime, 0)
}
