/**
 * react-imask base pattern tokens (docs: https://imask.js.org/guide.html#masked-pattern)
 *
 * Definitions are:
 * 0 - any digit
 * a - any letter
 * * - any char
 * other chars which is not in custom definitions supposed to be fixed
 * [] - make input optional
 * {} - include fixed part in unmasked value
 * ` - prevent symbols shift back
 * If definition character should be treated as fixed it should be escaped by \\ (E.g. \\0).
 *
 */
export const PATTERN_ANY_DIGIT = '0'
export const PATTERN_ANY_CHAR = '*'
export const PATTERN_ANY_LETTER = 'a'
export const PATTERN_TO_OPTIONAL = (value: string): string => `[${value}]`
