import { action } from 'typesafe-actions'

import { ConnectConfig } from './types'

/// Fetch Config Action

export const FETCH_CONFIG_REQUEST = '[Request] Fetch Connect Config'
export const FETCH_CONFIG_SUCCESS = '[Success] Fetch Connect Config'
export const FETCH_CONFIG_FAILURE = '[Failure] Fetch Connect Config'

export const fetchConfigRequest = () => action(FETCH_CONFIG_REQUEST, {})

export const fetchConfigSuccess = (config: ConnectConfig) =>
  action(FETCH_CONFIG_SUCCESS, { config })

export const fetchConfigFailure = (error: string) =>
  action(FETCH_CONFIG_FAILURE, { error })

export type FetchConfigRequestAction = ReturnType<typeof fetchConfigRequest>
export type FetchConfigSuccessAction = ReturnType<typeof fetchConfigSuccess>
export type FetchConfigFailureAction = ReturnType<typeof fetchConfigFailure>
