import './WelcomePageSkeletonLoader.css'

const WelcomePageSkeletonLoader = (): JSX.Element => (
  <div className={'WelcomePageSkeletonLoader'}>
    <div className={'header'}>
      <div className={'skeleton logo'} />
      <div className={'title-container'}>
        <div className={'skeleton title'} />
        <div className={'skeleton title'} />
        <div className={'skeleton title'} />
      </div>
    </div>
    <div className={'bullet-sections'}>
      <div className={'bullet-section'}>
        <div className={'skeleton title'} />
        <div className={'skeleton text'} />
        <div className={'skeleton text'} />
      </div>
      <div className={'bullet-section'}>
        <div className={'skeleton title'} />
        <div className={'skeleton text'} />
        <div className={'skeleton text'} />
      </div>
    </div>
    <div className={'footer'}>
      <div className={'skeleton terms'} />
      <div className={'skeleton terms'} />
      <div className={'skeleton button'} />
    </div>
  </div>
)

export default WelcomePageSkeletonLoader
