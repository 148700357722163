import { useRef, useEffect } from 'react'

import type { Item, ConnectorCredential, Connector } from 'pluggy-js'

import {
  hasMultiCompanySelectionMfaFlow,
  isQRConnector,
} from '../../../../modules/connector/utils'
import { usePrevious } from '../../../../utils/hooks/usePrevious'
import { MfaStage } from './ConnectForm.types'
import { FieldValues } from './utils'

/**
 * Custom hook that returns the i18n key of the title of the ConnectForm.
 * @param {Item | undefined} item
 * @param {ConnectorCredential & { stage: MfaStage }} mfaFormCredential
 * @param {Connector} selectedConnector
 * @param {boolean} isUpdate
 * @returns {{ titleI18nKey: string }}
 **/
export function useConnectFormHeaderI18nKey(
  item: Item | undefined,
  mfaFormCredentialStage:
    | (ConnectorCredential & { stage: MfaStage })
    | undefined,
  selectedConnector: Connector,
  isUpdate: boolean,
): { titleI18nKey: string } {
  const isSelectingMultiCompany =
    item &&
    item.status === 'WAITING_USER_INPUT' &&
    hasMultiCompanySelectionMfaFlow(selectedConnector) &&
    item.parameter?.type === 'select'

  const currentI18nKey = useRef('connectorForm.header.credentials')

  const previousI18nKey = usePrevious<string | undefined>(
    currentI18nKey.current,
  )

  const wasSelectingMultiCompany =
    mfaFormCredentialStage?.stage === '2-step' &&
    hasMultiCompanySelectionMfaFlow(selectedConnector) &&
    previousI18nKey === 'connectorForm.header.credentials' &&
    item?.parameter === null

  const isInterQrConnector = item && isQRConnector(item.connector)

  const isInterQRTimeout =
    isInterQrConnector && item.executionStatus === 'USER_INPUT_TIMEOUT'

  useEffect(() => {
    if (selectedConnector.oauthUrl) {
      currentI18nKey.current = 'connectorForm.header.oauth'
    } else if (isInterQrConnector) {
      if (isInterQRTimeout) {
        currentI18nKey.current = 'connectorForm.header.inter-qr-timeout'
      } else {
        currentI18nKey.current = 'connectorForm.header.credentials'
      }
    } else if (isSelectingMultiCompany || wasSelectingMultiCompany) {
      currentI18nKey.current = 'connectorForm.header.credentials'
    } else if (mfaFormCredentialStage?.stage === '2-step') {
      currentI18nKey.current = 'connectorForm.header.mfa-2-step'
    } else if (isUpdate) {
      currentI18nKey.current = 'connectorForm.header.updating'
    }
  }, [
    isInterQRTimeout,
    isInterQrConnector,
    isSelectingMultiCompany,
    isUpdate,
    mfaFormCredentialStage?.stage,
    selectedConnector.oauthUrl,
    wasSelectingMultiCompany,
  ])

  return {
    titleI18nKey: currentI18nKey.current,
  }
}

const OPEN_FINANCE_SANDBOX_CREDENTIALS = '76109277673'
/**
 * Hook to set credentials on the form on the first render.
 * @param setter
 * @param selectedConnector
 * @param item
 */
// eslint-disable-next-line import/no-unused-modules
export function useSetSandboxOFCredentials(
  setter: React.Dispatch<React.SetStateAction<FieldValues>>,
  selectedConnector: Connector,
): void {
  useEffect(() => {
    if (!selectedConnector.isSandbox || !selectedConnector.isOpenFinance) {
      return
    }

    setter((prev) => ({
      ...prev,
      cpf: OPEN_FINANCE_SANDBOX_CREDENTIALS,
    }))
  }, [selectedConnector.isOpenFinance, selectedConnector.isSandbox, setter])
}
