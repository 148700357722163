import { connect } from 'react-redux'

import type { Connector } from 'pluggy-js'
import { Parameters } from 'pluggy-js'

import { getCurrentCustomization } from '../../../../../modules/config/selectors'
import { getSelectedConnector } from '../../../../../modules/connector/selectors'
import {
  CREATE_ITEM_REQUEST,
  createItemRequest,
  FETCH_ITEM_REQUEST,
  oauthItemErrorCallback,
  oauthItemSuccessCallback,
  SEND_MFA_ITEM_REQUEST,
  UPDATE_ITEM_REQUEST,
  updateItemRequest,
} from '../../../../../modules/item/actions'
import {
  getError as getItemError,
  getLoading,
  isPollingItemAnyAction,
} from '../../../../../modules/item/selectors'
import { isLoadingType } from '../../../../../modules/loading/selectors'
import { RootState } from '../../../../../modules/reducer'
import OauthConnectForm from './OauthConnectForm'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './OauthConnectForm.types'

const mapState = (state: RootState): MapStateProps => ({
  error: getItemError(state),
  selectedConnector: getSelectedConnector(state) as Connector,
  isPollingItemAnyAction: isPollingItemAnyAction(state),
  displayInstitutionLogo:
    getCurrentCustomization(state).displayInstitutionLogos || true,
  isAnySubmitLoading:
    isLoadingType(getLoading(state), FETCH_ITEM_REQUEST) ||
    isLoadingType(getLoading(state), CREATE_ITEM_REQUEST) ||
    isLoadingType(getLoading(state), UPDATE_ITEM_REQUEST) ||
    isLoadingType(getLoading(state), SEND_MFA_ITEM_REQUEST) ||
    isPollingItemAnyAction(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onOauthItemSuccess: (itemId: string) =>
    dispatch(oauthItemSuccessCallback(itemId)),
  onOauthItemError: (error: Error) => dispatch(oauthItemErrorCallback(error)),
  onCreateItem: (connector: Connector, parameters: Parameters) =>
    dispatch(createItemRequest(connector, parameters)),
  onUpdateItem: (itemId: string, parameters?: Parameters) =>
    dispatch(updateItemRequest(itemId, parameters)),
})

export default connect(mapState, mapDispatch)(OauthConnectForm)
