import React from 'react'

import { Props } from './ConnectionSecureIcon.types'

import './ConnectionSecureIcon.css'

const ConnectionSecureIcon = ({ className }: Props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`ConnectionSecureIcon ${className || ''}`}
  >
    <mask id="path-1-inside-1_10_2073" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.60083 2.45971C8.35212 2.38013 8.08478 2.38013 7.83607 2.45971L4.07223 3.66414C3.5526 3.83042 3.2 4.31347 3.2 4.85906V7.38266C3.2 8.5054 3.42886 9.66423 4.13429 10.7394C4.8391 11.8137 5.98765 12.7558 7.74175 13.5029C8.04574 13.6324 8.39116 13.6324 8.69515 13.5029C10.4492 12.7558 11.5978 11.8137 12.3026 10.7394C13.0081 9.66423 13.2369 8.5054 13.2369 7.38266V4.85906C13.2369 4.31347 12.8843 3.83042 12.3647 3.66414L8.60083 2.45971ZM8.16383 3.48394C8.19935 3.47256 8.23755 3.47256 8.27307 3.48394L12.0369 4.68836C12.1111 4.71212 12.1615 4.78113 12.1615 4.85906V7.38266C12.1615 8.35648 11.9646 9.29421 11.4034 10.1495C10.8417 11.0057 9.88286 11.8282 8.27375 12.5136C8.239 12.5283 8.1979 12.5283 8.16315 12.5136C6.55403 11.8282 5.59519 11.0057 5.03343 10.1495C4.47228 9.29421 4.27538 8.35648 4.27538 7.38266V4.85906C4.27538 4.78113 4.32575 4.71212 4.39999 4.68836L8.16383 3.48394ZM9.29383 7.02422C9.29383 7.42227 9.07757 7.7698 8.75614 7.95573V9.7127C8.75614 10.0096 8.51541 10.2504 8.21845 10.2504C7.92149 10.2504 7.68076 10.0096 7.68076 9.7127V7.95573C7.35933 7.7698 7.14307 7.42227 7.14307 7.02422C7.14307 6.4303 7.62453 5.94884 8.21845 5.94884C8.81237 5.94884 9.29383 6.4303 9.29383 7.02422Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.60083 2.45971C8.35212 2.38013 8.08478 2.38013 7.83607 2.45971L4.07223 3.66414C3.5526 3.83042 3.2 4.31347 3.2 4.85906V7.38266C3.2 8.5054 3.42886 9.66423 4.13429 10.7394C4.8391 11.8137 5.98765 12.7558 7.74175 13.5029C8.04574 13.6324 8.39116 13.6324 8.69515 13.5029C10.4492 12.7558 11.5978 11.8137 12.3026 10.7394C13.0081 9.66423 13.2369 8.5054 13.2369 7.38266V4.85906C13.2369 4.31347 12.8843 3.83042 12.3647 3.66414L8.60083 2.45971ZM8.16383 3.48394C8.19935 3.47256 8.23755 3.47256 8.27307 3.48394L12.0369 4.68836C12.1111 4.71212 12.1615 4.78113 12.1615 4.85906V7.38266C12.1615 8.35648 11.9646 9.29421 11.4034 10.1495C10.8417 11.0057 9.88286 11.8282 8.27375 12.5136C8.239 12.5283 8.1979 12.5283 8.16315 12.5136C6.55403 11.8282 5.59519 11.0057 5.03343 10.1495C4.47228 9.29421 4.27538 8.35648 4.27538 7.38266V4.85906C4.27538 4.78113 4.32575 4.71212 4.39999 4.68836L8.16383 3.48394ZM9.29383 7.02422C9.29383 7.42227 9.07757 7.7698 8.75614 7.95573V9.7127C8.75614 10.0096 8.51541 10.2504 8.21845 10.2504C7.92149 10.2504 7.68076 10.0096 7.68076 9.7127V7.95573C7.35933 7.7698 7.14307 7.42227 7.14307 7.02422C7.14307 6.4303 7.62453 5.94884 8.21845 5.94884C8.81237 5.94884 9.29383 6.4303 9.29383 7.02422Z"
      fill="#4D4D4D"
      stroke="#4D4D4D"
      strokeWidth="2"
      mask="url(#path-1-inside-1_10_2073)"
    />
  </svg>
)

export default React.memo(ConnectionSecureIcon)
