import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@pluggyai/ui'

import { TrackEventName } from '../../../modules/analytics/events'
import { track } from '../../../modules/analytics/utils'
import { Title } from '../Title'
import { Props } from './CloseConfirmationModal.types'

import './CloseConfirmationModal.css'

const CloseConfirmationModal = ({
  onClose,
  onDeleteItem,
  item,
  canUserAbortConnection,
  onContinueInBackground,
}: Props) => {
  const { t } = useTranslation()

  const continueInBackgroundButtonI18nKey =
    'closeConfirmationModal.action.continue-in-background'
  const continueInBackgrounButtonText = t(continueInBackgroundButtonI18nKey)

  const closeButtonI18nKey = 'closeConfirmationModal.action.close'
  const closeButtonText = t(closeButtonI18nKey)

  const handleCloseClick = useCallback(() => {
    if (item) {
      onDeleteItem(item)
    }
    track(
      TrackEventName.BUTTON_CLICKED,
      {
        location: 'closeConfirmation',
        text: closeButtonText,
        i18nKey: closeButtonI18nKey,
      },
      undefined,
      // note: close when we already track the close button click using a callback
      () => onClose(),
    )
  }, [closeButtonText, item, onClose, onDeleteItem])

  const handleContinueInBackgroundClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      location: 'closeConfirmation',
      i18nKey: continueInBackgroundButtonI18nKey,
      text: continueInBackgrounButtonText,
    })
    onContinueInBackground()
  }, [continueInBackgrounButtonText, onContinueInBackground])

  return (
    <div className={'CloseConfirmation'}>
      {canUserAbortConnection ? (
        <div className={'container'}>
          <Title>{t('closeConfirmationModal.header')}</Title>
          <div className={'text-container'}>
            <p className={'message'}>
              {t('closeConfirmationModal.text.message')}
            </p>
            <p className={'message'}>
              {t('closeConfirmationModal.text.description')}
            </p>
          </div>
          <div className={'action-container'}>
            <Button onClick={handleCloseClick} secondary>
              {closeButtonText}
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <Title>
            {t('closeConfirmationModal.user-delete-connection-disabled.header')}
          </Title>
          <p>
            {t(
              'closeConfirmationModal.user-delete-connection-disabled.message',
            )}
          </p>
        </div>
      )}
      <Button onClick={handleContinueInBackgroundClick} primary>
        {continueInBackgrounButtonText}
      </Button>
    </div>
  )
}

export default React.memo(CloseConfirmationModal)
