import { captureException, Severity } from '@sentry/react'

import { AuthApiClient } from '../../lib/authApiClient'
import { reportApiDateDeltaToSentry } from '../../modules/config/utils'
import { getAppProps } from '../appWrapper'

const { connectToken } = getAppProps()

const authApiClient = new AuthApiClient(connectToken)

/**
 * Fetches the current date from the auth-api and calculates the difference
 * between the server's UTC timestamp and the client's local timestamp
 *
 * @returns number | null - the difference between the server's UTC timestamp and the client's local timestamp
 */
export async function calculateServerDateDiff(): Promise<number | null> {
  try {
    const {
      data: { date: currentServerTimestamp },
    } = await authApiClient.getApiDate()

    const currentClientTimestamp = Date.now()
    const serverDateDelta = currentServerTimestamp - currentClientTimestamp

    reportApiDateDeltaToSentry(serverDateDelta)

    return serverDateDelta
  } catch (error) {
    error.message = `Error fetching auth-api date: ${error.message}`
    captureException(error, {
      level: Severity.Error,
      contexts: {
        error: {
          // attach the error object to the event to have all details of the error
          object: error,
        },
      },
      fingerprint: [error.message],
    })
    throw error
  }
}
