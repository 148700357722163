import './ConnectorsListSkeletonLoader.css'

const ConnectorsListSkeletonLoader = (): JSX.Element => {
  // mock an array of 5 elements
  const connectors = Array.from({ length: 5 })
  return (
    <div className={'ConnectorsListSkeletonLoader'}>
      <div className={'skeleton logo'} />
      <div className={'skeleton title'} />
      <div className={'skeleton search-bar'} />
      <div className={'skeleton connectors-type'} />
      <div className={'connectors-list-container'}>
        {connectors.map((_, index) => (
          <div key={index} className={'connectors-list-item'}>
            <div className={'connector-data-container'}>
              <div className={'skeleton connectors-list-item-logo'} />
              <div className={'skeleton connectors-list-item-name'} />
            </div>
            {index !== connectors.length - 1 && (
              <div className={'skeleton connectors-list-item-line'} />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ConnectorsListSkeletonLoader
