import type { Connector, ConnectorType } from 'pluggy-js'

import {
  ConnectorId,
  orderConnectorsByConnectorIds,
} from '../../../../modules/connector/utils'

const PERSONAL_BANK_TYPE_IDS_ORDER: number[] = [
  ConnectorId.BR_PERSONAL_NUBANK,
  ConnectorId.BR_PERSONAL_ITAU,
  ConnectorId.BR_PERSONAL_BRADESCO,
  ConnectorId.BR_PERSONAL_BANCO_DO_BRASIL,
  ConnectorId.BR_PERSONAL_SANTANDER,
  ConnectorId.BR_PERSONAL_CAIXA,
  ConnectorId.BR_PERSONAL_BANCO_INTER_PERSONAS,
  ConnectorId.BR_PERSONAL_MERCADOPAGO,
  ConnectorId.BR_PERSONAL_MODALMAIS,
  ConnectorId.BR_PERSONAL_AILOS,
  ConnectorId.BR_PERSONAL_AILOS_CREDIT_CARD,
]
const BUSINESS_BANK_TYPE_IDS_ORDER: number[] = [
  ConnectorId.BR_BUSINESS_SANTANDER,
  ConnectorId.BR_BUSINESS_ITAU,
  ConnectorId.BR_BUSINESS_BANCO_DO_BRASIL,
  ConnectorId.BR_BUSINESS_BRADESCO,
  ConnectorId.BR_BUSINESS_CAIXA,
  ConnectorId.BR_BUSINESS_INTER,
  ConnectorId.BR_BUSINESS_AILOS,
  ConnectorId.BR_BUSINESS_AILOS_CREDIT_CARD,
]
const INVESTMENT_BANK_ORDER_TYPE_IDS_ORDER: number[] = [
  ConnectorId.BR_INVESTMENT_XP,
  ConnectorId.BR_INVESTMENT_BTG_PACTUAL,
  ConnectorId.BR_INVESTMENT_B3_CEI,
  ConnectorId.BR_INVESTMENT_EASYNVEST,
  ConnectorId.BR_INVESTMENT_GENIAL_INVESTIMENTOS,
  ConnectorId.BR_INVESTMENT_RICO_INVESTIMENTOS,
  ConnectorId.BR_INVESTMENT_CLEAR,
  ConnectorId.BR_INVESTMENT_AGORA,
  ConnectorId.BR_INVESTMENT_ORAMA_INVESTIMENTOS,
]

/**
 * take the current connectors and sort them,
 * the order is declared by an id array and
 * was established after reviewing analytics
 * @param connectors - actual connectors
 * @param connectorType - the current connectorType selected
 * @returns - the connectors array sorted by custom ids
 */
export function sortConnectorsByUsage(
  connectors: Connector[],
  connectorType: ConnectorType,
): Connector[] {
  const customConnectorIdsOrder: number[] = []

  if (connectorType === 'PERSONAL_BANK') {
    customConnectorIdsOrder.push(...PERSONAL_BANK_TYPE_IDS_ORDER)
  } else if (connectorType === 'BUSINESS_BANK') {
    customConnectorIdsOrder.push(...BUSINESS_BANK_TYPE_IDS_ORDER)
  } else {
    // connectorType === 'INVESTMENT'
    customConnectorIdsOrder.push(...INVESTMENT_BANK_ORDER_TYPE_IDS_ORDER)
  }

  // sort the connectors array matching with the ordered ids array and leave the rest in the same order at the end
  const connectorsSorted = orderConnectorsByConnectorIds(
    connectors,
    customConnectorIdsOrder,
  )

  return connectorsSorted
}
