import { AxiosError } from 'axios'
import type { Connector, Item, Parameters } from 'pluggy-js'
import { action } from 'typesafe-actions'

// Fetch Item by id
export const FETCH_ITEM_REQUEST = '[Request] Fetch item'
export const FETCH_ITEM_SUCCESS = '[Success] Fetch item'
export const FETCH_ITEM_FAILURE = '[Failure] Fetch item'

export const fetchItemRequest = (id: string) =>
  action(FETCH_ITEM_REQUEST, { id })

export const fetchItemSuccess = (item: Item) =>
  action(FETCH_ITEM_SUCCESS, { item })

export const fetchItemFailure = (id: string, error: Error) =>
  action(FETCH_ITEM_FAILURE, { id, error })

export type FetchItemRequestAction = ReturnType<typeof fetchItemRequest>
export type FetchItemSuccessAction = ReturnType<typeof fetchItemSuccess>
export type FetchItemFailureAction = ReturnType<typeof fetchItemFailure>

// Create Item
export const CREATE_ITEM_REQUEST = '[Request] Create item'
export const CREATE_ITEM_SUCCESS = '[Success] Create item'
export const CREATE_ITEM_FAILURE = '[Failure] Create item'

export const createItemRequest = (
  connector: Connector,
  parameters: Parameters,
) => action(CREATE_ITEM_REQUEST, { connector, parameters })

export const createItemSuccess = (item: Item) =>
  action(CREATE_ITEM_SUCCESS, { item })

export const createItemFailure = (error: string | AxiosError<unknown>) =>
  action(CREATE_ITEM_FAILURE, { error })

export type CreateItemRequestAction = ReturnType<typeof createItemRequest>
export type CreateItemSuccessAction = ReturnType<typeof createItemSuccess>
export type CreateItemFailureAction = ReturnType<typeof createItemFailure>

// Oauth Callback Item result
export const OAUTH_ITEM_SUCCESS_CALLBACK = '[Success] Oauth Item Callback'
export const oauthItemSuccessCallback = (itemId: string) =>
  action(OAUTH_ITEM_SUCCESS_CALLBACK, { itemId })
export type OauthItemSuccessCallbackAction = ReturnType<
  typeof oauthItemSuccessCallback
>
export const OAUTH_ITEM_ERROR_CALLBACK = '[Failure] Oauth Item Callback'
export const oauthItemErrorCallback = (error: Error) =>
  action(OAUTH_ITEM_ERROR_CALLBACK, { error })
export type OauthItemErrorCallbackAction = ReturnType<
  typeof oauthItemErrorCallback
>

// Delete Item
export const DELETE_ITEM_REQUEST = '[Request] Delete item'
export const DELETE_ITEM_SUCCESS = '[Success] Delete item'
export const DELETE_ITEM_FAILURE = '[Failure] Delete item'

export const deleteItemRequest = (item: Item) =>
  action(DELETE_ITEM_REQUEST, { item })

export const deleteItemSuccess = (item: Item) =>
  action(DELETE_ITEM_SUCCESS, { item })

export const deleteItemFailure = (item: Item, error: string) =>
  action(DELETE_ITEM_FAILURE, { item, error })

export type DeleteItemRequestAction = ReturnType<typeof deleteItemRequest>
export type DeleteItemSuccessAction = ReturnType<typeof deleteItemSuccess>
export type DeleteItemFailureAction = ReturnType<typeof deleteItemFailure>

// Update Item
export const UPDATE_ITEM_REQUEST = '[Request] Update item'
export const UPDATE_ITEM_SUCCESS = '[Success] Update item'
export const UPDATE_ITEM_FAILURE = '[Failure] Update item'

export const updateItemRequest = (
  itemId: string,
  parameters?: Parameters,
  webhookUrl?: string,
) => action(UPDATE_ITEM_REQUEST, { itemId, webhookUrl, parameters })

export const updateItemSuccess = (item: Item) =>
  action(UPDATE_ITEM_SUCCESS, { item })

export const updateItemFailure = (error: AxiosError<unknown>) =>
  action(UPDATE_ITEM_FAILURE, { error })

export type UpdateItemRequestAction = ReturnType<typeof updateItemRequest>
export type UpdateItemSuccessAction = ReturnType<typeof updateItemSuccess>
export type UpdateItemFailureAction = ReturnType<typeof updateItemFailure>

// Poll Item
export const POLL_ITEM_START = '[Start] Polling item'
export const POLL_ITEM_STOP = '[Stop] Polling item'

export const startPollingItem = (
  itemId: string,
  originalAction:
    | CreateItemRequestAction
    | UpdateItemRequestAction
    | SendMFAItemRequestAction
    | OauthItemSuccessCallbackAction,
) =>
  action(POLL_ITEM_START, {
    itemId,
    originalAction,
    pollingStartDate: new Date(),
  })

export const stopPollingItem = (itemId: string) =>
  action(POLL_ITEM_STOP, { itemId, pollingEndDate: new Date() })

export type StartPollingItemAction = ReturnType<typeof startPollingItem>
export type StopPollingItemAction = ReturnType<typeof stopPollingItem>

// Send poll result notification
export const sendNotificationAction = (data: {
  level: string
  title: string
  message: string
  position: string
}) => {
  // TODO actually send a notification? trigger a callback event?
  return action('NOTIFICATION', { data })
}

// Send Item MFA
export const SEND_MFA_ITEM_REQUEST = '[Request] Send MFA item'
export const SEND_MFA_ITEM_SUCCESS = '[Success] Send MFA item'
export const SEND_MFA_ITEM_FAILURE = '[Failure] Send MFA item'

export const sendMFAItemRequest = (itemId: string, parameters: Parameters) =>
  action(SEND_MFA_ITEM_REQUEST, { itemId, parameters })

export const sendMFAItemSuccess = (item: Item) =>
  action(SEND_MFA_ITEM_SUCCESS, { item })

export const sendMFAItemFailure = (error: AxiosError<unknown>) =>
  action(SEND_MFA_ITEM_FAILURE, { error })

export type SendMFAItemRequestAction = ReturnType<typeof sendMFAItemRequest>
export type SendMFAItemSuccessAction = ReturnType<typeof sendMFAItemSuccess>
export type SendMFAItemFailureAction = ReturnType<typeof sendMFAItemFailure>

// Clear connect item
export const CLEAR_CONNECT_ITEM = '[Request] Clear connect item'
export const clearConnectItem = () => action(CLEAR_CONNECT_ITEM, {})
export type ClearConnectItemAction = ReturnType<typeof clearConnectItem>

// Clear connect item error
export const CLEAR_CONNECT_ITEM_ERROR = '[Request] Clear connect item error'
export const clearConnectItemError = (itemId: string) =>
  action(CLEAR_CONNECT_ITEM_ERROR, { itemId })
export type ClearConnectItemErrorAction = ReturnType<
  typeof clearConnectItemError
>
