import type { Connector } from 'pluggy-js'
import { action } from 'typesafe-actions'

// Fetch Connector
export const FETCH_CONNECTORS_REQUEST = '[Request] Fetch connectors'
export const FETCH_CONNECTORS_SUCCESS = '[Success] Fetch connectors'
export const FETCH_CONNECTORS_FAILURE = '[Failure] Fetch connectors'

export const fetchConnectorsRequest = () => action(FETCH_CONNECTORS_REQUEST, {})

export const fetchConnectorsSuccess = (connectors: Connector[]) =>
  action(FETCH_CONNECTORS_SUCCESS, { connectors })

export const fetchConnectorsFailure = (error: string) =>
  action(FETCH_CONNECTORS_FAILURE, { error })

export type FetchConnectorsRequestAction = ReturnType<
  typeof fetchConnectorsRequest
>
export type FetchConnectorsSuccessAction = ReturnType<
  typeof fetchConnectorsSuccess
>
export type FetchConnectorsFailureAction = ReturnType<
  typeof fetchConnectorsFailure
>

/// Select connector

export const CONNECTOR_SELECT = 'Connector Select'

export const connectorSelect = (connector: Connector | null) =>
  action(CONNECTOR_SELECT, { connector })

export type ConnectorSelectAction = ReturnType<typeof connectorSelect>
