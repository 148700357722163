import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { HomePage } from '../HomePage'
import { Theme } from '../Theme'

const Routes = (): JSX.Element => {
  return (
    <Theme>
      <Switch>
        <Route exact path={'/'} component={HomePage} />
        <Redirect to={'/'} />
      </Switch>
    </Theme>
  )
}

export default Routes
