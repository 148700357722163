import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { combineReducers, Reducer } from 'redux'

import { authReducer as auth } from './auth/reducer'
import { configReducer as config } from './config/reducer'
import { connectorReducer as connector } from './connector/reducer'
import { itemReducer as item } from './item/reducer'
import { missingBankReducer as missingBank } from './missingBank/reducer'
import { termsOfUseReducer as termsOfUse } from './termsOfUse/reducer'

export const createRootReducer = (history: History): Reducer =>
  combineReducers({
    auth,
    config,
    connector,
    item,
    missingBank,
    router: connectRouter(history),
    termsOfUse,
  })

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>
