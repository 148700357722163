import { memo } from 'react'

const PlusIcon = ({ className }: { className?: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6 8.00002C13.6 8.44185 13.2419 8.80002 12.8 8.80002H8.80002V12.8C8.80002 13.2419 8.44185 13.6 8.00002 13.6V13.6C7.5582 13.6 7.20002 13.2419 7.20002 12.8V8.80002H3.20002C2.7582 8.80002 2.40002 8.44185 2.40002 8.00002V8.00002C2.40002 7.5582 2.7582 7.20002 3.20002 7.20002H7.20002V3.20002C7.20002 2.7582 7.5582 2.40002 8.00002 2.40002V2.40002V2.40002C8.44185 2.40002 8.80002 2.7582 8.80002 3.20002V7.20002H12.8C13.2419 7.20002 13.6 7.5582 13.6 8.00002V8.00002Z"
      fill="#111111"
    />
    <mask
      id="mask0_27_1701"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="2"
      y="2"
      width="12"
      height="12"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6 8.00002C13.6 8.44185 13.2419 8.80002 12.8 8.80002H8.80002V12.8C8.80002 13.2419 8.44185 13.6 8.00002 13.6V13.6C7.5582 13.6 7.20002 13.2419 7.20002 12.8V8.80002H3.20002C2.7582 8.80002 2.40002 8.44185 2.40002 8.00002V8.00002C2.40002 7.5582 2.7582 7.20002 3.20002 7.20002H7.20002V3.20002C7.20002 2.7582 7.5582 2.40002 8.00002 2.40002V2.40002V2.40002C8.44185 2.40002 8.80002 2.7582 8.80002 3.20002V7.20002H12.8C13.2419 7.20002 13.6 7.5582 13.6 8.00002V8.00002Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_27_1701)"></g>
  </svg>
)

export default memo(PlusIcon)
