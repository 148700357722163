import type { Connector } from 'pluggy-js'
import UAParser from 'ua-parser-js'

import { isSandboxConnector } from '../../../../../modules/connector/utils'

// Data source: https://analytics.amplitude.com/pluggy/chart/nka5wyf/edit/u5b2dw3?source=workspace
// Processed here: https://docs.google.com/spreadsheets/d/1n8T5yGa4a2gqXE7dZLajjOs-9cG2NRSAO_0YMjW9OQk/edit#gid=789373480
// Gig economy data source: https://www.similarweb.com/es/apps/top/google/app-index/br/all/top-free/ used to check most used
// apps in Brazil per category of gig economy.
const frequentInstitutions = [
  'C6',
  'Sicoob',
  'Sicredi',
  'Banco Original',
  'Cora',
  'Pagseguro',
  'PicPay',
  'Banco Pan',
  'Next',
  'Stone',
  'PagBank',
  'Neon',
  'Safra',
  'Banrisul',
  'Votorantim',
  'Bmg',
  'Bv',
  'Iti',
  'Cresol',
  'Viacredi',
  'Bs2',
  'Safrapay',
  'Mei',
  'Bancoob',
  'Gerencianet',
  'Digio',
  // Gig Economy - Ride hailing
  'Uber',
  'Easy Taxi',
  '99 Taxi,',
  'InDriver',
  // Gig Economy - Taxi
  'iFood',
  'Rappi',
  '99 Food',
]

export function buildMissingBankOptions(connectors: Connector[]): string[] {
  return [...connectors]
    .filter(
      (connector: Connector) =>
        !isSandboxConnector(connector) && connector.country === 'BR',
    )
    .map((connector: Connector) => connector.name)
    .concat(frequentInstitutions)
    .sort()
}

export function isMobile(): boolean {
  const device = new UAParser().getDevice()
  return device.type === 'mobile'
}
/**
 * Given a string value, return a normalized version of it, which implies
 * using a standard version for any accents such as 'ó', using lowerCase,
 * and removing all diacritics/special characters
 *
 * @param value - the string to normalize
 */
export function normalizeText(value: string): string {
  return value
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
}
