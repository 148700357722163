import { action } from 'typesafe-actions'

/// Check Auth Connect Token

export const CHECK_AUTH_REQUEST = '[Request] Check Auth Connect Token'
export const CHECK_AUTH_SUCCESS = '[Success] Check Auth Connect Token'
export const CHECK_AUTH_FAILURE = '[Failure] Check Auth Connect Token'

export const checkAuthRequest = () => action(CHECK_AUTH_REQUEST, {})
export const checkAuthSuccess = () => action(CHECK_AUTH_SUCCESS)
export const checkAuthFailure = (error: string) =>
  action(CHECK_AUTH_FAILURE, { error })

export type CheckAuthRequestAction = ReturnType<typeof checkAuthRequest>
export type CheckAuthSuccessAction = ReturnType<typeof checkAuthSuccess>
export type CheckAuthFailureAction = ReturnType<typeof checkAuthFailure>
