import { providers } from 'ethers'

function getEthereumObject(): providers.ExternalProvider | undefined {
  return window.ethereum
}

export async function connectWallet(): Promise<string> {
  const ethereum = getEthereumObject()
  if (!ethereum?.request) {
    throw new Error('connectorForm.connectWalletInput.error.no-metamask')
  }

  // note: 'eth_requestAccounts' returns a string[],
  // but always with only 1 (or 0) addresses, never more.
  // This seems to be a limitation of Metamask.
  // TODO: use other API methods/strategies to improve this?
  //  https://pluggy.atlassian.net/browse/RAM-138
  const accountArray: string[] = await ethereum.request({
    method: 'eth_requestAccounts',
  })

  if (accountArray.length === 0) {
    throw new Error('connectorForm.connectWalletInput.error.no-accounts')
  }

  return accountArray[0]
}
