import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@pluggyai/ui'
import { Form } from 'semantic-ui-react'

import { Props } from './MissingBankSuccess.types'

import './MissingBankSuccess.css'

const MissingBankSuccess = ({ onGoBack }: Props) => {
  const { t } = useTranslation()
  return (
    <Form className={'MissingBankSuccess'} onSubmit={onGoBack}>
      <div className={'message'}>
        <p className="title">{t('missingBank.success.title')}</p>
        <p className="details">{t('missingBank.success.message')}</p>
      </div>
      <Button primary type="submit">
        {t('missingBank.success.action')}
      </Button>
    </Form>
  )
}

export default React.memo(MissingBankSuccess)
