import { initReactI18next } from 'react-i18next'

import i18n, { Resource } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { getAppProps } from '../appWrapper'
import { getBaseCustomization } from '../customizations'
import EN from './locales/en/translation.json'
import PT from './locales/pt/translation.json'

const isDev = process.env.NODE_ENV === 'development'
const DEFAULT_LANGUAGE = 'pt'

export type SupportedLanguage = 'en' | 'pt'

export function initializeI18n(): void {
  const { language: currentLanguage = DEFAULT_LANGUAGE } = getAppProps()
  const { i18n: { en: customEN = {}, pt: customPT = {} } = {} } =
    getBaseCustomization() // TODO: support retrieving i18n terms from auth-api /connect/config ?

  const resources: Resource = {
    en: {
      custom: customEN,
      translation: EN,
    },
    pt: {
      custom: customPT,
      translation: PT,
    },
  }

  i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      // use 'custom' i18n texts first, falling back to app 'translation' defaults
      ns: ['custom', 'translation'],
      defaultNS: 'custom',
      fallbackNS: 'translation',
      fallbackLng: DEFAULT_LANGUAGE,
      lng: currentLanguage,
      debug: isDev,
      resources,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      react: {
        useSuspense: false,
      },
    })
    .then(() => isDev && console.log('i18n loaded!'))

  // helper to update the document HTML 'lang' prop
  const updateDocumentLang = (lang: string) =>
    document.documentElement.setAttribute('lang', lang)

  i18n.on('languageChanged', (lng) => {
    // update root HTML document 'lang' prop
    updateDocumentLang(lng)
  })

  // set the current language to the root document HTML
  updateDocumentLang(i18n.language)
}

/**
 * Helper to asynchronously check if i18n has been finished initializing.
 * Useful to prevent trying to access an 18n entry before it's loaded, which would result in undefined.
 */
export async function waitForI18nInitialized(): Promise<void> {
  const { isInitialized } = i18n
  if (isInitialized) {
    // already loaded
    return Promise.resolve()
  }

  // not yet loaded, subscribe to 'initialized' event and return promise
  return new Promise((resolve) => i18n.on('initialized', resolve))
}

export default i18n
