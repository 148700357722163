import { IMask } from 'react-imask'

import {
  PATTERN_ANY_DIGIT,
  PATTERN_ANY_LETTER,
  PATTERN_TO_OPTIONAL,
} from './constants'
import { EMAIL_INPUT_MODE, NUMERIC_INPUT_MODE } from './inputMode'
import { literalValidations } from './literalValidationRegexes'
import { IMaskInputProps, InputMaskProps, ValidationKey } from './types'

const CURRENT_YEAR = new Date().getFullYear()
const DATE_SEPARATOR = '/'

/**
 * Mask for date in 'dd/mm/yyyy' format
 */
const BIRTH_DATE_MASK: IMaskInputProps = {
  mask: Date,
  pattern: '`d/`m/Y', // Pattern mask defined blocks
  // you can provide your own blocks definitions, default blocks for date mask are:
  blocks: {
    d: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 31,
      maxLength: 2,
      placeholderChar: 'd',
    },
    m: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12,
      maxLength: 2,
      placeholderChar: 'm',
    },
    Y: {
      mask: IMask.MaskedRange,
      from: 1900,
      to: CURRENT_YEAR,
      placeholderChar: 'y',
    },
  },
  format: function formatDateToDdMmYyyy(date: Date): string {
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    return [day, month, year]
      .map(String)
      .map((datePart) => datePart.padStart(2, '0'))
      .join(DATE_SEPARATOR)
  },
  parse: function parseDate(dateDdMmYyyy: string): Date {
    const [day, month, year] = dateDdMmYyyy.split(DATE_SEPARATOR).map(Number)
    return new Date(year, month - 1, day)
  },

  autofix: true, // attempt to auto-correct if a wrong value is inserted

  lazy: true, // if false, format placeholders always shown; if true they are hidden
  eager: true, // if true, will complete separator symbols as soon as possible

  overwrite: true, // when set to 'true', this works as "insert edit"
}

const GENERIC_MASK: IMaskInputProps = { mask: String }

const CPF_MASK: IMaskInputProps = { mask: '000.000.000-00' }

const CNPJ_MASK: IMaskInputProps = { mask: '00.000.000/0000-00' }

const CPF_OR_CNPJ_MASK: IMaskInputProps = { mask: [CPF_MASK, CNPJ_MASK] }

const OPERADOR_MASK: IMaskInputProps = { mask: '0'.repeat(9) }

const CPF_OR_OPERADOR_MASK: IMaskInputProps = {
  mask: [OPERADOR_MASK, CPF_MASK],
}

const EMAIL_MASK: IMaskInputProps = GENERIC_MASK

const EMAIL_OR_CPF_MASK: IMaskInputProps = {
  mask: [EMAIL_MASK, CPF_MASK],
}

// mask for 'signature' credential, validation regex: '^[A-Z]{3}\\d{4}$'
const SIGNATURE_MASK: IMaskInputProps = {
  mask: `${PATTERN_ANY_LETTER}${PATTERN_TO_OPTIONAL(PATTERN_ANY_LETTER).repeat(
    2,
  )}${PATTERN_TO_OPTIONAL(PATTERN_ANY_DIGIT).repeat(4)}`,
  prepare: (value: string) => value.toUpperCase(),
}

const literalValidationRegexMasksMap: Record<ValidationKey, InputMaskProps> = {
  BIRTH_DATE: {
    imaskProps: BIRTH_DATE_MASK,
  },
  CNPJ: {
    imaskProps: CNPJ_MASK,
    inputModeProps: NUMERIC_INPUT_MODE,
  },
  CPF: {
    imaskProps: CPF_MASK,
    inputModeProps: NUMERIC_INPUT_MODE,
  },
  CPF_OR_CNPJ: {
    imaskProps: CPF_OR_CNPJ_MASK,
    inputModeProps: NUMERIC_INPUT_MODE,
  },
  CPF_OR_OPERADOR: {
    imaskProps: CPF_OR_OPERADOR_MASK,
  },
  EMAIL: {
    imaskProps: EMAIL_MASK,
    inputModeProps: EMAIL_INPUT_MODE,
  },
  EMAIL_OR_CPF: {
    imaskProps: EMAIL_OR_CPF_MASK,
  },
  SIGNATURE: {
    imaskProps: SIGNATURE_MASK,
  },
}

/**
 * Check if validationRegex is one of literalValidations,
 * in that case return the corresponding preset mask.
 *
 * @param validationRegex
 * @returns preset mask if found, null otherwise
 */
export function findPresetMask(validationRegex: string): InputMaskProps | null {
  for (const [validationKey, mask] of Object.entries(
    literalValidationRegexMasksMap,
  )) {
    const literalValidation = literalValidations[validationKey as ValidationKey]
    if (validationRegex === literalValidation) {
      // matched to a pre-set validation regex  => return the pre-built mask
      return mask
    }
  }
  return null
}
