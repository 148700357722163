export default function OFSandboxCredentials() {
  return (
    <div>
      Utilize as seguintes credenciais para se autenticar no Sandbox Open
      Finance: <br /> <br />
      <b>User:</b> ralph.bragg@gmail.com
      <br />
      <b>Password:</b> P@ssword01
    </div>
  )
}
