import React from 'react'
import { useTranslation } from 'react-i18next'

import { Alert } from '@pluggyai/ui'
import { Loader } from 'semantic-ui-react'

import { Props } from './TermsPage.types'

import './TermsPage.css'

const TermsPage = ({ termsAndConditions, isLoading, error }: Props) => {
  const { t } = useTranslation()

  return (
    <div className={'TermsPage'}>
      {error && (
        <Alert
          type={'error'}
          message={error ? t('termsPage.error.fetch') : undefined}
        />
      )}
      {isLoading && !termsAndConditions ? (
        <div className="loader-container">
          <Loader size="large" active />
        </div>
      ) : (
        termsAndConditions && (
          <div dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
        )
      )}
    </div>
  )
}

export default React.memo(TermsPage)
