import './ConnectFormSkeletonLoader.css'

const ConnectFormSkeletonLoader = (): JSX.Element => (
  <div className={'ConnectFormSkeletonLoader'}>
    <div className={'skeleton logo'} />
    <div className={'skeleton title'} />
    <div className={'connector-data-container'}>
      <div className={'skeleton connector-logo'} />
      <div className={'skeleton connector-name'} />
    </div>
    <div className={'form'}>
      <div className={'skeleton form-input'} />
      <div className={'skeleton form-input'} />
    </div>
    <div className={'footer'}>
      <div className={'skeleton button'} />
      <div className={'skeleton reset-password-link'} />
    </div>
  </div>
)

export default ConnectFormSkeletonLoader
