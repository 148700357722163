import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@pluggyai/ui'

import './MfaSelectRequestExpired.css'

type Props = {
  onRetry: () => void
}

const MfaSelectRequestExpired = ({ onRetry }: Props) => {
  const { t } = useTranslation()
  return (
    <div className="MfaSelectRequestExpired">
      <div className={'container'}>
        <div className={'message'}>
          <img src={'../time_expired.png'} alt="time expired" />
          <p>{t('connectStatus.session-expired.message')}</p>
        </div>
      </div>
      <Button primary onClick={onRetry} type="submit">
        {t('connectStatus.action.retry')}
      </Button>
    </div>
  )
}

export default React.memo(MfaSelectRequestExpired)
