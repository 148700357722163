import React from 'react'
import { useTranslation } from 'react-i18next'

import { Alert } from '@pluggyai/ui'
import { Loader } from 'semantic-ui-react'

import { Props } from './PrivacyPolicyPage.types'

import './PrivacyPolicyPage.css'

const PrivacyPolicyPage = ({ privacyPolicy, isLoading, error }: Props) => {
  const { t } = useTranslation()

  return (
    <div className={'PrivacyPolicyPage'}>
      {error && (
        <Alert
          type={'error'}
          message={error ? t('termsPage.error.fetch') : undefined}
        />
      )}
      {isLoading && !privacyPolicy ? (
        <div className="loader-container">
          <Loader size="large" active />
        </div>
      ) : (
        privacyPolicy && (
          <div dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
        )
      )}
    </div>
  )
}

export default React.memo(PrivacyPolicyPage)
