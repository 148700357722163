import React, { useCallback } from 'react'

import './GoBackIcon.css'

type Props = {
  onClick: () => void
}

const GoBackIcon = ({ onClick }: Props) => {
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key !== 'Enter') {
        return
      }
      onClick()
    },
    [onClick],
  )

  return (
    <div
      className="GoBackIcon"
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="16"
        viewBox="0 0 10 16"
      >
        <g fill="none" fillRule="evenodd">
          <g fill="#292828">
            <g>
              <path
                d="M9.103 14.741c.307.288.307.755 0 1.043-.308.288-.807.288-1.115 0L.231 8.522c-.308-.288-.308-.755 0-1.044L7.988.216c.308-.288.807-.288 1.115 0 .307.288.307.755 0 1.043L1.902 8l7.2 6.741z"
                transform="translate(-29 -25) translate(29 25)"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default React.memo(GoBackIcon)
