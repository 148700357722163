import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  MISSING_BANK_SUBMIT_FAILURE,
  MISSING_BANK_SUBMIT_REQUEST,
  MISSING_BANK_SUBMIT_SUCCESS,
  MissingBankSubmitFailureAction,
  MissingBankSubmitRequestAction,
  MissingBankSubmitSuccessAction,
} from './actions'

export type MissingBankState = {
  data: string[]
  loading: LoadingState
  error: string | null
}

const INITIAL_STATE: MissingBankState = {
  data: [],
  loading: [],
  error: null,
}

type MissingBankReducerAction =
  | MissingBankSubmitRequestAction
  | MissingBankSubmitSuccessAction
  | MissingBankSubmitFailureAction

export function missingBankReducer(
  state: MissingBankState = INITIAL_STATE,
  action: MissingBankReducerAction,
): MissingBankState {
  switch (action.type) {
    case MISSING_BANK_SUBMIT_REQUEST:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
      }
    case MISSING_BANK_SUBMIT_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
      }
    case MISSING_BANK_SUBMIT_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload.bank],
        loading: loadingReducer(state.loading, action),
        error: null,
      }
    default:
      return state
  }
}
