import { memo } from 'react'

const FAQIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_26_1008)">
      <mask id="path-1-inside-1_26_1008" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00002 3.45002C5.48713 3.45002 3.45002 5.48713 3.45002 8.00002C3.45002 10.513 5.48713 12.55 8.00002 12.55C10.513 12.55 12.55 10.513 12.55 8.00002C12.55 5.48713 10.513 3.45002 8.00002 3.45002ZM2.40002 8.00002C2.40002 4.90723 4.90723 2.40002 8.00002 2.40002C11.0928 2.40002 13.6 4.90723 13.6 8.00002C13.6 11.0928 11.0928 13.6 8.00002 13.6C4.90723 13.6 2.40002 11.0928 2.40002 8.00002ZM8.70002 10.1C8.70002 10.4866 8.38663 10.8 8.00002 10.8C7.61343 10.8 7.30002 10.4866 7.30002 10.1C7.30002 9.71342 7.61343 9.40002 8.00002 9.40002C8.38663 9.40002 8.70002 9.71342 8.70002 10.1ZM7.2446 6.65981C7.30096 6.54709 7.37725 6.45034 7.48186 6.3806C7.5832 6.31305 7.74189 6.25002 8.00002 6.25002C8.19585 6.25002 8.38706 6.31062 8.51627 6.40752C8.63147 6.49392 8.70002 6.60709 8.70002 6.77502C8.70002 6.89866 8.67146 6.95969 8.64601 6.99787C8.61426 7.0455 8.55949 7.09816 8.45255 7.16947C8.39907 7.20511 8.34154 7.23975 8.2713 7.2819L8.26701 7.28448L8.267 7.28448C8.20141 7.32384 8.12395 7.37031 8.04784 7.42105C7.89228 7.52477 7.70642 7.66901 7.56318 7.88391C7.40236 8.12517 7.46757 8.45112 7.70883 8.61194C7.95009 8.77276 8.27605 8.70755 8.43687 8.46629C8.46862 8.41865 8.52339 8.36598 8.63033 8.29468C8.68379 8.25903 8.74132 8.22439 8.81156 8.18225L8.8159 8.17964L8.81594 8.17962C8.88151 8.14028 8.95893 8.09382 9.035 8.0431C9.19055 7.9394 9.37641 7.79518 9.51966 7.58031C9.66925 7.35598 9.75002 7.08889 9.75002 6.77502C9.75002 6.24296 9.49774 5.83113 9.14627 5.56752C8.80882 5.31443 8.38753 5.20002 8.00002 5.20002C7.55816 5.20002 7.19185 5.312 6.89943 5.50695C6.61029 5.69971 6.42409 5.95296 6.30545 6.19024C6.17578 6.44957 6.28089 6.76493 6.54024 6.8946C6.79957 7.02427 7.11493 6.91915 7.2446 6.65981Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00002 3.45002C5.48713 3.45002 3.45002 5.48713 3.45002 8.00002C3.45002 10.513 5.48713 12.55 8.00002 12.55C10.513 12.55 12.55 10.513 12.55 8.00002C12.55 5.48713 10.513 3.45002 8.00002 3.45002ZM2.40002 8.00002C2.40002 4.90723 4.90723 2.40002 8.00002 2.40002C11.0928 2.40002 13.6 4.90723 13.6 8.00002C13.6 11.0928 11.0928 13.6 8.00002 13.6C4.90723 13.6 2.40002 11.0928 2.40002 8.00002ZM8.70002 10.1C8.70002 10.4866 8.38663 10.8 8.00002 10.8C7.61343 10.8 7.30002 10.4866 7.30002 10.1C7.30002 9.71342 7.61343 9.40002 8.00002 9.40002C8.38663 9.40002 8.70002 9.71342 8.70002 10.1ZM7.2446 6.65981C7.30096 6.54709 7.37725 6.45034 7.48186 6.3806C7.5832 6.31305 7.74189 6.25002 8.00002 6.25002C8.19585 6.25002 8.38706 6.31062 8.51627 6.40752C8.63147 6.49392 8.70002 6.60709 8.70002 6.77502C8.70002 6.89866 8.67146 6.95969 8.64601 6.99787C8.61426 7.0455 8.55949 7.09816 8.45255 7.16947C8.39907 7.20511 8.34154 7.23975 8.2713 7.2819L8.26701 7.28448L8.267 7.28448C8.20141 7.32384 8.12395 7.37031 8.04784 7.42105C7.89228 7.52477 7.70642 7.66901 7.56318 7.88391C7.40236 8.12517 7.46757 8.45112 7.70883 8.61194C7.95009 8.77276 8.27605 8.70755 8.43687 8.46629C8.46862 8.41865 8.52339 8.36598 8.63033 8.29468C8.68379 8.25903 8.74132 8.22439 8.81156 8.18225L8.8159 8.17964L8.81594 8.17962C8.88151 8.14028 8.95893 8.09382 9.035 8.0431C9.19055 7.9394 9.37641 7.79518 9.51966 7.58031C9.66925 7.35598 9.75002 7.08889 9.75002 6.77502C9.75002 6.24296 9.49774 5.83113 9.14627 5.56752C8.80882 5.31443 8.38753 5.20002 8.00002 5.20002C7.55816 5.20002 7.19185 5.312 6.89943 5.50695C6.61029 5.69971 6.42409 5.95296 6.30545 6.19024C6.17578 6.44957 6.28089 6.76493 6.54024 6.8946C6.79957 7.02427 7.11493 6.91915 7.2446 6.65981Z"
        fill="#111111"
        stroke="#4D4D4D"
        strokeWidth="2"
        mask="url(#path-1-inside-1_26_1008)"
      />
    </g>
    <defs>
      <clipPath id="clip0_26_1008">
        <rect
          width="11.2"
          height="11.2"
          fill="white"
          transform="translate(2.40002 2.40002)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default memo(FAQIcon)
