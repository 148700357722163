import { captureMessage } from '@sentry/react'
import i18n from 'i18next'
import type { Connector, Item } from 'pluggy-js'
import { all, call, put, takeEvery } from 'redux-saga/effects'

import { ConnectConfig } from '../../lib/authApiClient'
import { getPluggyClient } from '../../lib/pluggyClient'
import { getAppProps } from '../../utils/appWrapper'
import { waitForI18nInitialized } from '../../utils/i18n'
import { TrackEventName } from '../analytics/events'
import { group, identify, track } from '../analytics/utils'
import {
  fetchConnectorsFailure,
  fetchConnectorsSuccess,
} from '../connector/actions'
import { fetchAppConnectors } from '../connector/sagas'
import { isQRConnector } from '../connector/utils'
import {
  fetchItemFailure,
  fetchItemSuccess,
  updateItemRequest,
} from '../item/actions'
import { fetchTermsOfUseRequest } from '../termsOfUse/actions'
import {
  CHECK_AUTH_REQUEST,
  checkAuthFailure,
  checkAuthSuccess,
} from './actions'
import { ConnectTokenPayload } from './types'
import {
  extractEmail,
  parseAuthErrorI18nData,
  parseConnectToken,
} from './utils'

function* retrieveConnectors() {
  try {
    const connectors: Connector[] = yield call(fetchAppConnectors)
    yield put(fetchConnectorsSuccess(connectors))
  } catch (error) {
    yield put(fetchConnectorsFailure(error.message))
    throw error
  }
}

function* retrieveItemAndUpdate(itemId: string) {
  try {
    // TODO should this be a sub-saga? https://pluggy.atlassian.net/browse/DVT-395
    const item: Item = yield call(() =>
      getPluggyClient({ withRetries: false }).fetchItem(itemId),
    )
    yield put(fetchItemSuccess(item))

    // run update request (only if not 1-step MFA, and didn't have credentials error)
    const { connector } = item
    const is1StepMfa = connector.credentials.some(
      (credential) => credential.mfa,
    )
    const isCredentialsError =
      item.status === 'LOGIN_ERROR' ||
      item.error?.code === 'INVALID_CREDENTIALS'

    // inter QR, user needs to see instructions before update if status is not WAITING_USER_ACTION
    const isInterQRScanMustStart =
      isQRConnector(connector) && item.executionStatus !== 'WAITING_USER_ACTION'

    // 1-step MFA, user needs to enter MFA code before update,
    // credentials error, the user needs to re-enter credentials
    const canUpdateItemWithoutUserInteraction =
      !is1StepMfa && !isCredentialsError && !isInterQRScanMustStart

    if (canUpdateItemWithoutUserInteraction) {
      yield put(updateItemRequest(itemId))
    }
  } catch (error) {
    yield put(fetchItemFailure(itemId, error.message))
    throw error
  }
}

function* initialAuthCheckRequest() {
  const { updateItem } = getAppProps()

  try {
    // run initial app data request
    yield call(() =>
      updateItem
        ? // is UPDATE mode -> retrieve items data
          retrieveItemAndUpdate(updateItem)
        : // is CREATE mode -> retrieve connectors data
          retrieveConnectors(),
    )
  } catch (error) {
    // ensure i18n is loaded to prevent getting an 'undefined' i18n text
    yield call(waitForI18nInitialized)

    // analyze and get i18n message from error
    const { i18nKey, i18nText, response } = parseAuthErrorI18nData(error)

    // send track to Segment for later analysis of error rates
    track(TrackEventName.AUTH_ERROR, { i18nText, i18nKey, response })
    throw new Error(i18nText)
  }
}

/**
 * Identify user in Segment using only connectToken basic data (clientId)
 */
function identifyClientId(connectToken: ConnectTokenPayload): void {
  const { clientId } = connectToken

  identify(undefined, { clientId })
}

/**
 * Identify user in Segment by connectToken payload data retrieved from
 * auth-api and teamId retrieved from auth-api.
 */
export function identifyClientUserId(
  connectTokenPayload: Partial<ConnectTokenPayload>,
  { teamId, companyName, isProductionEnabled }: ConnectConfig,
): void {
  const { clientUserId, clientId, connectTokenId } = connectTokenPayload

  if (!clientId) {
    // clientId not present in connectTokenPayload (this should never happen)
    // we don't call identify() as we don't have enough information
    captureMessage(
      'clientId was not present in connectTokenPayload retrieved from pluggy-api nor in jwt decoded token',
      {
        contexts: {
          data: {
            connectTokenPayload,
            teamId,
          },
        },
      },
    )
    return
  }

  const clientUserIdTrimmed = clientUserId?.trim() || undefined

  if (!clientUserIdTrimmed) {
    // clientUserId has not been provided, just expand current identity with connectTokenId
    identify(undefined, { connectTokenId })
    return
  }

  // attempt to extract email from clientUserId, if any
  const email = extractEmail(clientUserIdTrimmed)

  // namespace the clientUserId with a clientIdentifier,
  // since we can't guarantee the clientUserId is unique.
  // some clients for example use numeric ids.
  let clientIdentifier: string

  if (typeof teamId === 'string') {
    // teamId is present, use it
    clientIdentifier = teamId
  } else {
    // teamId is null: app doesn't belong
    // to any team (ie. shared demo link), so fallback to clientId
    clientIdentifier = `clientId_${clientId}`
  }

  const clientIdClientUserId = `${clientIdentifier}:${clientUserIdTrimmed}`

  // identify user
  identify(clientIdClientUserId, { clientId, connectTokenId, email })

  // identify Team as group
  group(clientIdentifier, { companyName, isProductionEnabled })
}

function* handleCheckAuthLoginRequest() {
  const { connectToken } = getAppProps()

  if (!connectToken) {
    // ensure i18n is loaded to prevent getting an 'undefined' i18n text
    yield call(() => waitForI18nInitialized())
    yield put(checkAuthFailure(i18n.t('auth.no_connect_token')))
    return
  }

  let connectTokenPayload: ConnectTokenPayload
  try {
    connectTokenPayload = parseConnectToken()
    const { clientId } = connectTokenPayload
    if (!clientId) {
      // not a connectToken, using a POST /auth accessToken instead
      yield put(checkAuthFailure(i18n.t('auth.invalid_connect_token.message')))
      return
    }
  } catch {
    // malformed token
    yield put(checkAuthFailure(i18n.t('auth.malformed_connect_token')))
    return
  }

  // connectToken exists and is valid -> identify client user in Segment
  identifyClientId(connectTokenPayload)

  try {
    yield initialAuthCheckRequest()
  } catch (error) {
    yield put(checkAuthFailure(error.message))
    return
  }
  yield put(checkAuthSuccess())
  yield put(fetchTermsOfUseRequest())
}

export function* authSaga() {
  yield all([takeEvery(CHECK_AUTH_REQUEST, handleCheckAuthLoginRequest)])
}
