import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@pluggyai/ui'

import { TrackEventName } from '../../../../../modules/analytics/events'
import { track } from '../../../../../modules/analytics/utils'
import { getEmailProviderFromMessage } from '../utils'

import './Mfa2StepInsructions.css'

type Props = {
  message?: string
  hasEmailMfaFlow?: boolean
  isDisabled?: boolean
}

const Mfa2StepInstructions = ({
  message,
  hasEmailMfaFlow,
  isDisabled,
}: Props) => {
  const { t } = useTranslation()

  const { emailProviderUrl, emailDomainName } =
    getEmailProviderFromMessage(message)

  track(TrackEventName.LINK_CLICKED, {
    location: 'connectorForm',
    linkTo: emailProviderUrl,
    i18nKey: 'connectorForm.mfa-2-step-instructions.action.openEmail',
    text: message,
    domain: emailDomainName,
  })

  return (
    <div className={'Mfa2StepInstructions'}>
      {message}
      {hasEmailMfaFlow && (
        <>
          {/* adding exactly 1 space of distance between message and link */}{' '}
          <Button
            as={'a'}
            href={emailProviderUrl}
            disabled={isDisabled}
            target="_blank"
            rel="noopener noreferrer"
            link
            className={'instruction-button'}
          >
            {t('connectorForm.mfa-2-step-instructions.action.openEmail')}
          </Button>
        </>
      )}
    </div>
  )
}

export default React.memo(Mfa2StepInstructions)
