import i18n from 'i18next'
import { AnyAction } from 'redux'

import { ConnectConfigText, EnvironmentType } from '../../lib/authApiClient'
import {
  buildCustomConfigCss,
  COMPANY_NAME_DEFAULT,
  Customization,
  getBaseCustomization,
  LOGO_DEFAULT,
} from '../../utils/customizations'
import { SupportedLanguage } from '../../utils/i18n'
import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { ConfigState } from './reducer'
import { calculateItemCreateQuotaExceeded } from './utils'

export const getState = (state: RootState): ConfigState =>
  state.config as ConfigState
export const getConfig = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType: (state: LoadingState, type: string) => boolean = (
  state,
  type,
) => state.some((action: AnyAction) => action.type === type)

export const getCustomizationCss = (state: RootState): string => {
  const config = getConfig(state)
  const customization = getCurrentCustomization(state)
  return buildCustomConfigCss(config, customization)
}

export const getCurrentCustomization = (state: RootState): Customization => {
  const connectConfig = getConfig(state)
  const baseCustomization = getBaseCustomization()

  // If connectConfig has any of the Pluggy Demo defaults,
  // we must prioritize the Connect customizations hardcoded value first (if defined),
  // otherwise we use the one from connectConfig. TODO: migrate all to connectConfig values https://pluggy.atlassian.net/browse/DVT-488

  const companyName =
    connectConfig?.companyName === COMPANY_NAME_DEFAULT &&
    baseCustomization.companyName
      ? // connect config value is the default AND is overriding in hardcoded customizations => use the override
        baseCustomization.companyName
      : // connectConfig is not the default value, use it first.
        connectConfig?.companyName || baseCustomization.companyName

  const logo =
    connectConfig?.brand.logo === LOGO_DEFAULT && baseCustomization.brand?.logo
      ? // connect config value is the default AND is overriding in hardcoded customizations => use the override
        baseCustomization.brand.logo
      : // connectConfig is not the default value, use it first.
        connectConfig?.brand.logo ||
        baseCustomization.brand?.logo ||
        LOGO_DEFAULT

  // for the case of logoBackgroundCollapsed we prioritize
  // 1. NON-default connectConfig.brand.logo
  // 2. hardcoded logoBackgroundCollapsed
  // 3. "nothing"
  //
  // NOTE: if none is found we will just be reusing the base logo.
  const logoBackgroundCollapsed =
    connectConfig?.brand.logo === LOGO_DEFAULT
      ? // connect config value is the default AND is overriding in hardcoded customizations => use the override
        baseCustomization.brand?.logoBackgroundCollapsed || logo
      : // connectConfig is not the default value, use it first.
        connectConfig?.brand.logo || undefined // TODO: connectConfig values (ie. logo) shouldn't be nullable anymore? validate this and in this case start enforcing in the type

  // merge connectConfig data with base customization
  const customization: Customization = {
    ...baseCustomization,
    ...{
      companyName,
      cssClassName:
        baseCustomization.cssClassName || connectConfig?.companyName,
      connectorsFilters: {
        ...baseCustomization.connectorsFilters,
        ids:
          connectConfig?.connectorsFilters.ids ||
          baseCustomization.connectorsFilters?.ids,
      },
      brand: {
        ...baseCustomization.brand,
        logo,
        logoBackgroundCollapsed,
      },
    },
  }

  return customization
}

export const getConnectApplicationEnvironment = (
  state: RootState,
): EnvironmentType | null => getConfig(state)?.environment || null

export const isDemoConnectApplication = (state: RootState): boolean => {
  return getConnectApplicationEnvironment(state) === 'DEMO'
}

export const isItemsCreateQuotaExceeded = (state: RootState): boolean => {
  const config = getConfig(state)
  if (!config) {
    return false
  }

  const { totalItems, itemsCreateLimit, isProductionEnabled } = config

  return calculateItemCreateQuotaExceeded(
    totalItems,
    itemsCreateLimit,
    isProductionEnabled,
  )
}

export const getServerDateDelta = (state: RootState): number | null =>
  getConfig(state)?.serverDateDeltaInMs || null

export const getWelcomeStepCustomButtonText = (
  state: RootState,
): string | null => {
  const currentLanguage = i18n.language as SupportedLanguage

  return (
    getConfig(state)?.text.button.welcomeStep.continue[currentLanguage] || null
  )
}

export const getFormStepCustomButtonTexts = (
  state: RootState,
): {
  submit: ConnectConfigText
  submitLoading: ConnectConfigText
} | null => getConfig(state)?.text.button.connectFormStep || null
