import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Alert, Button } from '@pluggyai/ui'

import { TrackEventName } from '../../../../../../../modules/analytics/events'
import { track } from '../../../../../../../modules/analytics/utils'
import { isBBPJConnector } from '../../../../../../../modules/connector/utils'
import { ConnectorHeader } from '../../../../ConnectorHeader'
import { Props } from './DeviceUnauthorizedStatus.types'
import { getHelpLinkByConnector } from './utils'

import './DeviceUnauthorizedStatus.css'

const DeviceUnauthorizedStatus = ({
  connector,
  displayInstitutionLogo,
  deviceNickname,
  onClose,
  onDeviceAuthorizedClick,
  providerMessage,
}: Props) => {
  const { t } = useTranslation()

  const deviceAuthorizedI18nKey =
    providerMessage?.includes('administrador da empresa') &&
    isBBPJConnector(connector)
      ? 'connectStatus.device-unauthorized.bb-pj.action.confirm'
      : 'connectStatus.device-unauthorized.default.action.confirm'
  const deviceAuthorizedI18nText = t(deviceAuthorizedI18nKey)

  const cancelI18nKey =
    'connectStatus.device-unauthorized.default.action.cancel'
  const cancelI18nText = t(cancelI18nKey)

  const handleDeviceAuthorizedClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      text: deviceAuthorizedI18nText,
      i18nKey: deviceAuthorizedI18nKey,
    })
    onDeviceAuthorizedClick()
  }, [
    deviceAuthorizedI18nText,
    onDeviceAuthorizedClick,
    deviceAuthorizedI18nKey,
  ])

  const handleCloseClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      text: deviceAuthorizedI18nText,
      i18nKey: deviceAuthorizedI18nKey,
    })
    onClose()
  }, [deviceAuthorizedI18nText, onClose, deviceAuthorizedI18nKey])

  const { href, i18nKey } = getHelpLinkByConnector(connector) || {}

  const helpLinkText = i18nKey ? t(i18nKey) : null

  const handleLinkClick = useCallback(() => {
    track(TrackEventName.LINK_CLICKED, {
      text: helpLinkText,
      i18nKey,
      linkTo: href,
    })
  }, [helpLinkText, href, i18nKey])

  return (
    <div className={'DeviceUnauthorizedStatus'}>
      <div className={'device-authorization-message-container'}>
        <p className="title">
          {t('connectStatus.device-unauthorized.default.header')}
        </p>
        <ConnectorHeader
          connector={connector}
          withImage={displayInstitutionLogo}
        />
        <Alert
          size={'small'}
          message={t('connectStatus.device-unauthorized.default.alert')}
          type={'warning'}
        />
        <p className={'subtitle'}>
          {t('connectStatus.device-unauthorized.default.message-title')}
        </p>
        <p className={'device-authorization-message'}>
          {providerMessage || (
            <Trans
              i18nKey={'connectStatus.device-unauthorized.caixa.message'}
            />
          )}
        </p>

        <p className={'device-authorization-details'}>
          <p className={'subtitle'}>
            {t('connectStatus.device-unauthorized.default.details.title')}
          </p>
          <p className={'device-authorization-details-device'}>
            <Trans
              i18nKey={
                'connectStatus.device-unauthorized.default.details.device'
              }
              values={{
                deviceNickname,
              }}
            />
          </p>
        </p>
      </div>
      {helpLinkText && (
        <div className={'help-link'}>
          {
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={href}
              onClick={handleLinkClick}
            >
              {helpLinkText}
            </a>
          }
        </div>
      )}
      <div className="action-container">
        <Button primary onClick={handleDeviceAuthorizedClick}>
          {deviceAuthorizedI18nText}
        </Button>
        <Button secondary onClick={handleCloseClick}>
          {cancelI18nText}
        </Button>
      </div>
    </div>
  )
}

export default React.memo(DeviceUnauthorizedStatus)
