import React from 'react'
import { IMaskInput } from 'react-imask'

import { buildIMaskPropsFromValidationRegex } from '../../../../../utils/input-mask'
import { BASE_INPUT_MODE } from '../../../../../utils/input-mask/inputMode'
import {
  InputMaskProps,
  InputModeProps,
} from '../../../../../utils/input-mask/types'
import { Props } from './MaskedInput.types'

export const MaskedInput = ({
  isFocused,
  validation,
  onAccept,
  isPassword,
}: Props): JSX.Element | null => {
  if (!validation) {
    return null
  }

  const maskProps: InputMaskProps | null = buildIMaskPropsFromValidationRegex(
    validation,
    {
      showFormatPlaceholder: isFocused,
      onAccept,
    },
  )

  if (!maskProps) {
    return null
  }

  const { imaskProps, inputModeProps = BASE_INPUT_MODE } = maskProps

  // clone inputModeProps, to clean it up for Input 'password' to ensure type is not overridden
  const inputModePropsFixed: InputModeProps = {
    ...inputModeProps,
  }
  if (isPassword) {
    delete inputModePropsFixed.type
  }

  return <IMaskInput {...imaskProps} {...inputModePropsFixed} />
}
