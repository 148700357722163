import { connect } from 'react-redux'

import { FETCH_CONFIG_REQUEST } from '../../modules/config/actions'
import {
  getLoading,
  isLoadingType,
  getConfig,
  getCustomizationCss,
  getCurrentCustomization,
} from '../../modules/config/selectors'
import { getSelectedConnector } from '../../modules/connector/selectors'
import { RootState } from '../../modules/reducer'
import Theme from './Theme'
import { MapStateProps } from './Theme.types'

const mapState = (state: RootState): MapStateProps => ({
  isLoading: isLoadingType(getLoading(state), FETCH_CONFIG_REQUEST),
  customizationCss: getCustomizationCss(state),
  customizationConfig: getConfig(state),
  selectedConnector: getSelectedConnector(state),
  customization: getCurrentCustomization(state),
})

export default connect(mapState)(Theme)
