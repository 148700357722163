import React, { useCallback } from 'react'
import { Trans } from 'react-i18next'

import { Props } from './TermsConsentText.types'

import './TermsConsentText.css'

const TermsConsentText = ({
  onGoToPrivacyPolicyPage,
  onGoToTermsPage,
  continueButtonText,
}: Props) => {
  const handleTermsButtonKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLSpanElement>) => {
      if (event.key !== 'Enter') {
        return
      }

      onGoToTermsPage()
    },
    [onGoToTermsPage],
  )

  const handlePrivacyPolicyButtonKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLSpanElement>) => {
      if (event.key !== 'Enter') {
        return
      }

      onGoToPrivacyPolicyPage()
    },
    [onGoToPrivacyPolicyPage],
  )

  return (
    <div className={'TermsConsentText'}>
      <p className={'consent-text'}>
        <Trans
          i18nKey={'welcome.terms'}
          values={{
            continueButtonText,
          }}
          components={{
            span1: (
              <span
                className={'link'}
                onClick={onGoToTermsPage}
                onKeyDown={handleTermsButtonKeyDown}
                role={'button'}
                tabIndex={0}
              />
            ),
            span2: (
              <span
                className={'link'}
                onClick={onGoToPrivacyPolicyPage}
                onKeyDown={handlePrivacyPolicyButtonKeyDown}
                role={'button'}
                tabIndex={0}
              />
            ),
          }}
        />
      </p>
    </div>
  )
}

export default React.memo(TermsConsentText)
