import React, { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Alert, Button } from '@pluggyai/ui'
import { captureException, captureMessage, Severity } from '@sentry/react'
import { isAxiosError } from 'axios'
import type { Item } from 'pluggy-js'
import { Form } from 'semantic-ui-react'

import { TrackEventName } from '../../../../../modules/analytics/events'
import { track } from '../../../../../modules/analytics/utils'
import { isSandboxConnector } from '../../../../../modules/connector/utils'
import { getItemParameterOauthUrl } from '../../../../../modules/item/utils'
import { usePrevious } from '../../../../../utils/hooks/usePrevious'
import { ConnectorHeader } from '../../ConnectorHeader'
import { isMobile } from '../../MissingBankPage/MissingBankForm/utils'
import { isUserAccountError, mapExecutionErrorI18nKey } from '../utils'
import { Props } from './OauthConnectForm.types'
import { OFSandboxCredentials } from './OFSandboxCredentials'
import { openSignInWindow, useAutoSubmitOpenFinance } from './utils'

import './OauthConnectForm.css'

const OauthConnectForm = ({
  item,
  isPollingItemAnyAction,
  isAnySubmitLoading,
  error,
  selectedConnector,
  onOauthItemSuccess,
  onOauthItemError,
  displayInstitutionLogo,
  onCreateItem,
  onUpdateItem,
  onShowOpenFinancePermissions,
  className,
}: Props) => {
  const { t } = useTranslation()

  const {
    oauthUrl: connectorOauthUrl,
    health,
    institutionUrl,
    id: connectorId,
    name: connectorName,
    oauth: isOauthConnector,
  } = selectedConnector

  const [isCreateLoading, setCreateLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<JSX.Element | string | null>(
    null,
  )
  const [isOauthSilentPolling, setIsOauthSilentPolling] =
    useState<boolean>(false)

  const handleOauthFormSubmit = useCallback(
    async (oauthUrl_: string, item_?: Item) => {
      const existingItemId = item_?.id

      if (existingItemId) {
        // item already existed, consider oauth flow as success, to start polling it

        onOauthItemSuccess(existingItemId)
        // poll item silently
        setIsOauthSilentPolling(true)
      }

      setCreateLoading(true)
      try {
        let result
        try {
          result = await openSignInWindow(oauthUrl_, selectedConnector)
        } catch (popupError) {
          if (
            popupError.message === 'Failed to open window popup' &&
            isMobile()
          ) {
            window.open(oauthUrl_, '_blank')?.focus()
            return
          }
          throw popupError
        }

        if (!existingItemId) {
          // itemId was not present before
          const resultItemId = result?.itemId

          if (!resultItemId) {
            // itemId was also not retrieved after openSignInWindow
            // Note: this is a type check, should not happen (instead an error should be thrown)
            throw new Error(
              'No item id present after openSignInWindow, ensure item is previously created or correctly tracked from sign in window',
            )
          }

          // itemId just retrieved from openSignInWindow result, start polling it
          onOauthItemSuccess(resultItemId)

          // not necessary to consider the polling as silent anymore
          setIsOauthSilentPolling(false)
        }
      } catch (error_) {
        if (error_.message === 'Failed to open window popup') {
          // popup was blocked
          // handle this case and show an error on the form screen,
          // instead of navigating to the last error status creen for 'unexpected error'.
          setErrorMessage(
            t('connectorForm.error.oauth_popup_blocked', { connectorName }),
          )
          return
        }

        if (
          error_.message ===
            'Login window closed prematurely, please try again.' ||
          error_.message === 'User navigated back from Oauth WebView'
        ) {
          // login window has been closed or reference has been lost (not possible to ensure which one)
          // if we didn't have an existing itemId, display an error message;
          // otherwise ignore it and just keep polling silently
          if (!existingItemId) {
            setErrorMessage(
              t('connectorForm.error.oauth_popup_window_closed', {
                connectorName,
              }),
            )
          }

          return
        }

        console.error('Oauth connect failed', error_)
        onOauthItemError(error_ as Error)
        return
      } finally {
        setCreateLoading(false)
      }
    },
    [onOauthItemSuccess, onOauthItemError, t, connectorName, selectedConnector],
  )

  const previousWasPollingItemAnyAction = usePrevious(isPollingItemAnyAction)
  const pollingStopped =
    previousWasPollingItemAnyAction && !isPollingItemAnyAction

  useEffect(() => {
    const pollingStoppedAfterSubmit = pollingStopped && isCreateLoading

    if (pollingStoppedAfterSubmit) {
      // polling stopped, stop form submit loading
      setIsOauthSilentPolling(false)
      setCreateLoading(false)
    }

    if (
      pollingStoppedAfterSubmit &&
      item &&
      (item.status === 'LOGIN_ERROR' || item.status === 'OUTDATED')
    ) {
      const { error: error_ } = item

      // adding code: null in case that itemError object is null
      const { code: itemErrorCode } = error_ || { code: null }

      if (!isUserAccountError(itemErrorCode)) {
        // this error is unexpected, we are navigating to
        // error page but return early to prevent rendering unexpected errors
        return
      }

      // if item was isCreateLoading AND status+executionStatus is FINISHED with ERROR
      // we update the error message
      const TransMessageElement = (
        <Trans
          i18nKey={mapExecutionErrorI18nKey(itemErrorCode, selectedConnector)}
          components={{
            a: (
              // we don't need content inside the anchor because we are
              // interpolating the element
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                href={institutionUrl}
                className={'link'}
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
          }}
        />
      )
      setErrorMessage(TransMessageElement)
      return
    }

    if (!error) {
      // no error result/response is present, no need to update errorMessage
      return
    }
    // there is an error, update state

    if (typeof error === 'string') {
      // is an oauth result error
      // TODO improve this error info, ie. render the actual `error.message`?
      //  however, it'll need some QA reviewing, since some of them are backend errors and ugly for frontend
      captureException(`Unknown error typeof string ${error}`, {
        level: Severity.Error,
        extra: {
          error,
        },
        fingerprint: [error],
      })

      setErrorMessage(t('connectorForm.error.unknown_not_valid_credentials'))
      return
    }

    const isApiAuthError = isAxiosError(error) && error.response?.status === 403

    if (isApiAuthError) {
      setErrorMessage(t('auth.expired_token'))
      return
    }

    const isMeuPluggyError =
      isAxiosError(error) && error.response?.data?.message.includes('MeuPluggy')

    if (isMeuPluggyError) {
      setErrorMessage(
        <Trans
          i18nKey={'connectorForm.error.meupluggy_error'}
          components={{
            a: (
              // we don't need content inside the anchor because we are
              // interpolating the element
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                href={'https://meu.pluggy.ai'}
                className={'link'}
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
          }}
        />,
      )
      return
    }

    // unexpected error, report to Sentry
    error.message = isAxiosError(error)
      ? `OauthConnectForm: Unexpected or unknown error from pluggy-api (${error.code}): ${error.message}`
      : `OauthConnectForm: Unexpected or unknown error: ${error.message}`

    captureException(error, {
      level: Severity.Critical,
      fingerprint: [error.message],
    })

    // unknown error
    setErrorMessage(t('connectorForm.error.unknown'))
  }, [
    institutionUrl,
    isCreateLoading,
    item,
    error,
    t,
    pollingStopped,
    isPollingItemAnyAction,
    previousWasPollingItemAnyAction,
    selectedConnector,
  ])

  const itemOauthUrl = item && getItemParameterOauthUrl(item)
  const currentOauthUrl = connectorOauthUrl || itemOauthUrl

  // is loading when item has just been created, updated, sent MFA;
  // or when it's polling item status after any of these.
  // except if it's silently polling - in this case leave we the UI is still active
  const isLoading =
    !isOauthSilentPolling &&
    (isCreateLoading || isAnySubmitLoading || isPollingItemAnyAction)

  const handleConnectFormSubmit = useCallback(
    (
      event?:
        | React.FormEvent<HTMLFormElement>
        | React.MouseEvent<HTMLButtonElement>,
    ): void => {
      event?.preventDefault()
      if (isLoading) {
        return
      }
      // clear error message
      setErrorMessage(null)

      if (connectorOauthUrl) {
        // has legacy connector preset oauth URL, submit directly
        // no item update/create here, it should be handled afterwards on the redirects
        handleOauthFormSubmit(connectorOauthUrl).catch((error_) =>
          console.error('Unexpected error on submit oauth:', error_),
        )
        return
      }

      if (!isOauthConnector) {
        // selected connector has no connectorOauthUrl and no oauth: true -> fail
        const errorMessage_ =
          'Unexpected OauthConnectForm submit, on selectedConnector without oauthUrl and no oauth: true'
        console.error(errorMessage_, { selectedConnector, item })
        captureMessage(errorMessage_, {
          extra: {
            selectedConnector,
            event,
            item,
          },
          level: Severity.Error,
        })
        return
      }

      if (!item) {
        // no item yet -> create it
        setCreateLoading(true)
        onCreateItem(selectedConnector, {})
        return
      }

      // item exists
      const oauthUrl = getItemParameterOauthUrl(item)
      if (!oauthUrl) {
        // no oauthUrl parameter yet
        // -> update existing item to generate the oauth URL
        setCreateLoading(true)
        onUpdateItem(item.id)
        return
      }

      // has oauthUrl -> open it and proceed with the login flow using this URL
      handleOauthFormSubmit(oauthUrl, item).catch((error_) =>
        console.error('Unexpected error on submit oauth:', error_),
      )
    },
    [
      isLoading,
      connectorOauthUrl,
      isOauthConnector,
      item,
      handleOauthFormSubmit,
      selectedConnector,
      onCreateItem,
      onUpdateItem,
    ],
  )

  useAutoSubmitOpenFinance({
    selectedConnector,
    item,
    isPollingItemAnyAction,
    isAnySubmitLoading,
    handleConnectFormSubmit,
  })

  const resetPasswordLinkI18nKey = 'connectorForm.reset-password'

  const resetPasswordLinkText = t(resetPasswordLinkI18nKey)

  const trackResetPasswordLinkClick = useCallback(() => {
    track(TrackEventName.LINK_CLICKED, {
      connector: selectedConnector,
      i18nKey: resetPasswordLinkI18nKey,
      text: resetPasswordLinkText,
      linkTo: selectedConnector.institutionUrl,
      location: 'connectForm',
    })
  }, [resetPasswordLinkText, selectedConnector])

  const connectorKeyClassName = [
    connectorId,
    connectorName.replace(/\s/g, ''),
  ].join('-')

  // TODO fix repeated logic
  const isDisableFormInputsExecutionError =
    item?.executionStatus === 'ACCOUNT_NEEDS_ACTION' ||
    item?.executionStatus === 'USER_NOT_SUPPORTED' ||
    item?.executionStatus === 'ACCOUNT_LOCKED'

  return (
    <Form
      className={`OauthConnectForm ${connectorKeyClassName} ${className || ''}`}
      onSubmit={handleConnectFormSubmit}
      noValidate
      error={!!errorMessage}
    >
      <ConnectorHeader
        connector={selectedConnector}
        withImage={displayInstitutionLogo}
      />
      <div className={'form-fields'}>
        {errorMessage ? (
          <Alert type={'error'} message={errorMessage} size="medium" />
        ) : (
          !isLoading &&
          health.status === 'UNSTABLE' && (
            <Alert
              type={'warning'}
              message={t('connectorForm.message.unstable')}
            />
          )
        )}

        {selectedConnector.isOpenFinance ? (
          <div>
            <Alert
              type="info"
              message={
                <>
                  {!selectedConnector.isSandbox &&
                    t('connectorForm.oauth.open-finance.info')}
                  {selectedConnector.isSandbox && <OFSandboxCredentials />}
                </>
              }
            />
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              className={'open-finance-see-permissions-button'}
              onClick={onShowOpenFinancePermissions}
            >
              {t('connectorForm.oauth.open-finance.see-permissions')}
            </div>
          </div>
        ) : (
          <div className={'oauth-message'}>
            <p>{t('connectorForm.oauth.introduction')}</p>
            <p>
              {currentOauthUrl
                ? t('connectorForm.oauth.continue', { connectorName })
                : t('connectorForm.oauth.start')}
            </p>
          </div>
        )}
      </div>
      <div className={'form-footer'}>
        <Button
          primary
          type="submit"
          loading={isLoading}
          disabled={isLoading || isDisableFormInputsExecutionError}
        >
          {currentOauthUrl
            ? t('connectorForm.submit')
            : t('connectorForm.continue')}
        </Button>
        {!isSandboxConnector(selectedConnector) &&
          !selectedConnector.isOpenFinance && (
            <div className={'action-link-container'}>
              <a
                href={institutionUrl}
                className={`link ${
                  isDisableFormInputsExecutionError ? 'disabled' : ''
                }`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={trackResetPasswordLinkClick}
              >
                {t('connectorForm.reset-password')}
              </a>
            </div>
          )}
      </div>
    </Form>
  )
}

export default React.memo(OauthConnectForm)
