import { connect } from 'react-redux'

import {
  getConnectors,
  getConnectorsFilteredByConfig,
} from '../../../../modules/connector/selectors'
import {
  MISSING_BANK_SUBMIT_REQUEST,
  missingBankSubmitRequest,
} from '../../../../modules/missingBank/actions'
import {
  getError,
  getLoading,
  isLoadingType,
} from '../../../../modules/missingBank/selectors'
import { RootState } from '../../../../modules/reducer'
import MissingBankPage from './MissingBankPage'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './MissingBankPage.types'

const mapState = (state: RootState): MapStateProps => ({
  isLoading: isLoadingType(getLoading(state), MISSING_BANK_SUBMIT_REQUEST),
  error: getError(state),
  connectors: getConnectors(state),
  availableConnectors: getConnectorsFilteredByConfig(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onSubmit: (bank: string) => {
    return dispatch(missingBankSubmitRequest(bank))
  },
})

export default connect(mapState, mapDispatch)(MissingBankPage)
