import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert, Button } from '@pluggyai/ui'

import { TrackEventName } from '../../../../../../modules/analytics/events'
import { track } from '../../../../../../modules/analytics/utils'
import { Title } from '../../../../Title'
import { ConnectorHeader } from '../../../ConnectorHeader'
import { Props } from './AilosDeviceConnectionLimitReachedError.types'

import './AilosDeviceConnectionLimitReachedError.css'

const AilosDeviceConnectionLimitReachedError = ({
  connector,
  displayInstitutionLogo,
  onRetry,
  onClose,
}: Props) => {
  const { t } = useTranslation()

  const closeButtonI18nKey =
    'connectStatus.device-connection-limit-reached-error.action.close'
  const closeButtonText = t(closeButtonI18nKey)

  const handleRetry = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      i18nKey: closeButtonI18nKey,
      text: closeButtonText,
      location: 'AilosDeviceConnectionLimitReachedError',
    })
    onRetry()
  }, [closeButtonText, onRetry])

  const retryButtonI18nKey =
    'connectStatus.device-connection-limit-reached-error.action.retry'
  const retryButtonText = retryButtonI18nKey

  const handleClose = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      i18nKey: retryButtonI18nKey,
      text: retryButtonText,
      location: 'AilosDeviceConnectionLimitReachedError',
    })

    onClose()
  }, [onClose, retryButtonText])

  return (
    <div className={'AilosDeviceConnectionLimitReachedError'}>
      <div className={'content'}>
        <Title>
          {t('connectStatus.device-connection-limit-reached-error.title')}
        </Title>
        <ConnectorHeader
          connector={connector}
          withImage={displayInstitutionLogo}
        />
        <Alert
          type="error"
          size={'small'}
          message={t(
            'connectStatus.device-connection-limit-reached-error.error-message',
          )}
        />
        <p>
          {t(
            'connectStatus.device-connection-limit-reached-error.general-message',
          )}
        </p>
        <p>
          {t(
            'connectStatus.device-connection-limit-reached-error.instructions',
          )}
        </p>
      </div>

      <div className={'action-group'}>
        <Button onClick={handleClose}>{closeButtonText}</Button>
        <Button primary onClick={handleRetry}>
          {t(retryButtonText)}
        </Button>
      </div>
    </div>
  )
}

export default memo(AilosDeviceConnectionLimitReachedError)
