import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@pluggyai/ui'

import { Title } from '../../../../Title'
import { ConnectorHeader } from '../../../ConnectorHeader'
import { Props } from './AuthorizedDevicesLimitReached.types'

import './AuthorizedDevicesLimitReached.css'

const AuthorizedDevicesLimitReached = ({
  onRetry,
  selectedConnector,
  withImage,
  onClose,
}: Props) => {
  const { t } = useTranslation()

  return (
    <div className={'AuthorizedDevicesLimitReached'}>
      <Title>{t('connectorForm.header.credentials')}</Title>
      <ConnectorHeader connector={selectedConnector} withImage={withImage} />
      <p className={'message'}>
        {t('connectStatus.authorized-devices-limit-reached.message')}
      </p>
      <div className={'actions'}>
        <Button onClick={onRetry} primary>
          {t('connectStatus.authorized-devices-limit-reached.action.retry')}
        </Button>
        <Button onClick={onClose}>
          {t('connectStatus.authorized-devices-limit-reached.action.close')}
        </Button>
      </div>
    </div>
  )
}

export default memo(AuthorizedDevicesLimitReached)
