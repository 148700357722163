import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert } from '@pluggyai/ui'

import { isPersonalSafraConnector } from '../../../../../../modules/connector/utils'
import { formatMilisecondsToTimer } from '../../../../../../utils/date'
import { getUserActionRemainingTime } from './utils'
import { Props } from './WaitUserAction.types'

import './WaitUserAction.css'

const WaitUserAction = ({ item, serverDateDeltaInMs }: Props) => {
  const { t } = useTranslation()
  const { deviceNickname } = item.userAction?.attributes || {}
  const { expiresAt } = item.userAction || {}
  const [remainingTime, setRemainingTime] = useState<number | null>(null)

  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    const updateRemainingTime = () => {
      const remainingTimeInMs = getUserActionRemainingTime(
        serverDateDeltaInMs,
        expiresAt,
      )

      setRemainingTime(remainingTimeInMs)

      timeoutId = setTimeout(updateRemainingTime, 1000)
    }

    updateRemainingTime()

    return () => clearTimeout(timeoutId)
  }, [serverDateDeltaInMs, expiresAt])

  return (
    <div className={'WaitUserAction'}>
      <div className={'content'}>
        <Alert
          type={'warning'}
          message={'Este dispositivo não está habilitado.'}
        />
        {isPersonalSafraConnector(item.connector) && (
          <>
            <h3 className={'subtitle'}>
              {t(
                'connectorForm.user-action-instructions.wait-user-actions.title',
              )}
            </h3>
            <p className={'instructions-introduction'}>
              {t(
                'connectorForm.user-action-instructions.wait-user-actions.instructions-introduction',
              )}
            </p>
          </>
        )}
        <p className={'instructions'}>
          <p>
            {item.userAction?.instructions
              .split('\n')
              .map((instruction, index) => (
                <span key={index}>
                  {instruction}
                  <br />
                </span>
              ))}
          </p>
        </p>
        {deviceNickname && (
          <div className={'device-section'}>
            <h3 className={'subtitle'}>
              {t(
                'connectorForm.user-action-instructions.wait-user-actions.device.title',
              )}
            </h3>
            <ul className={'device-list'}>
              <li>{deviceNickname}</li>
            </ul>
          </div>
        )}
      </div>
      {remainingTime !== null && (
        <div className={'timer'}>
          {t(
            'connectorForm.user-action-instructions.wait-user-actions.device.message',
          )}{' '}
          <span className={'time'}>
            {formatMilisecondsToTimer(remainingTime)}
          </span>
        </div>
      )}
    </div>
  )
}

export default memo(WaitUserAction)
