import { Provider } from 'react-redux'

import { ConnectedRouter } from 'connected-react-router'
import { History } from 'history'
import { AnyAction, Store } from 'redux'

import { Routes } from './components/Routes'
import './themes'

import './index.css'

export function Application({
  store,
  history,
}: {
  // note: using unknown here because RootState has any type
  store: Store<unknown, AnyAction>
  history: History
}) {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
    </Provider>
  )
}
