export function formatMilisecondsToTimer(ms: number): string {
  // 1- Convert to seconds:
  const seconds = Math.floor(ms / 1000)
  // 3- Extract minutes:
  const minutes = parseInt(Math.floor(seconds / 60).toString())
  // 4- Keep only seconds not extracted to minutes:
  const remainingSeconds = seconds % 60
  const sRemainingSeconds =
    remainingSeconds.toString().length < 2
      ? `0${remainingSeconds}`
      : remainingSeconds

  return `${minutes}:${sRemainingSeconds}`
}
