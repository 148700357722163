import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@pluggyai/ui'
import { captureMessage, Severity } from '@sentry/react'
import { isAxiosError } from 'axios'
import { Grid } from 'semantic-ui-react'

import { Props } from './GeneralErrorStatus.types'

import './GeneralErrorStatus.css'

const GeneralErrorStatus = ({
  currentItemExecutionError,
  errorMessage,
  item,
  itemError,
  onClose,
  onGoToConnectorsList,
  onRetry,
  hasPreselectedConnector,
}: Props) => {
  const { t } = useTranslation()

  useEffect(() => {
    const pluggyApiResponse = isAxiosError(itemError)
      ? itemError.response
      : undefined

    // report empty errors, or errors other than 'SITE_NOT_AVAILABLE' or 'CONNECTION_ERROR'
    const shouldReportItemExecutionError =
      !currentItemExecutionError ||
      (currentItemExecutionError !== 'SITE_NOT_AVAILABLE' &&
        currentItemExecutionError !== 'CONNECTION_ERROR')

    if (!shouldReportItemExecutionError) {
      // not a relevant error, skip report
      return
    }

    // report to Sentry
    captureMessage('GeneralErrorStatus shown', {
      level: Severity.Warning,
      tags: {
        currentItemExecutionError,
      },
      contexts: {
        itemErrorStatus: {
          currentItemExecutionError,
          item,
          itemError,
          pluggyApiResponse,
        },
      },
    })
  }, [currentItemExecutionError, item, itemError])

  return (
    <div className={'GeneralErrorStatus'}>
      <Grid
        className={'error-container'}
        columns={2}
        verticalAlign={'middle'}
        centered={true}
        textAlign={'left'}
      >
        <Grid.Row className="error-image">
          <img
            src={`../${
              currentItemExecutionError === 'SITE_NOT_AVAILABLE'
                ? 'site_not_available.png'
                : 'error.png'
            }`}
            alt="Oops! Connection error"
          />
        </Grid.Row>
        <Grid.Row className="error-message">
          <p className={currentItemExecutionError || 'api-error'}>
            {errorMessage}
          </p>
          <pre className={'error-code'}>ErrorCode: {item?.id}</pre>
        </Grid.Row>
      </Grid>

      <div className="action-container">
        {/* TODO refactor to track event on all button click cases */}
        {currentItemExecutionError === 'SITE_NOT_AVAILABLE' &&
        !hasPreselectedConnector ? (
          <Button primary onClick={onGoToConnectorsList}>
            {t('connectStatus.action.try-another-provider')}
          </Button>
        ) : currentItemExecutionError === 'CONNECTION_ERROR' ? (
          <Button primary onClick={onRetry}>
            {t('connectStatus.action.retry')}
          </Button>
        ) : (
          <Button primary onClick={onClose}>
            {t('connectStatus.action.close')}
          </Button>
        )}
      </div>
    </div>
  )
}

export default React.memo(GeneralErrorStatus)
