import { memo } from 'react'

const DashIcon = ({ className }: { className?: string }) => (
  <svg
    width="14"
    height="2"
    viewBox="0 0 14 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`DashIcon ${className || ''}`}
  >
    <line
      x1="1"
      y1="1"
      x2="13"
      y2="1"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

export default memo(DashIcon)
