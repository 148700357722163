import { ButtonHTMLAttributes, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { FAQIcon } from './FAQIcon'

import './OpenFinanceFAQButton.css'

const OpenFinanceFAQButton = ({
  className,
  ...rest
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
  const { t } = useTranslation()
  return (
    <button
      {...rest}
      type="button"
      className={`OpenFinanceFAQButton ${className}`}
    >
      <FAQIcon /> {t('connectorForm.open-finance-FAQ-Button')}
    </button>
  )
}

export default memo(OpenFinanceFAQButton)
