import type { Connector } from 'pluggy-js'

import {
  isSandbox2StepConnector,
  isSandboxConnector,
} from '../../../../../modules/connector/utils'

const SANDBOX_USERS = ['user-ok', 'user-bad']

const SANDBOX_1_STEP_USERS = [
  ...SANDBOX_USERS,
  'user-logged',
  'user-locked',
  'user-error',
  'user-unavailable',
  'user-account-need-actions',
  'user-account-need-actions-provider-message',
  'user-connection-error',
  'user-ok-account-error',
  'user-account-credentials-reset',
  'user-ok-auth-pending',
  'user-not-supported',
  'user-ok-acquiring-bank',
]

const SANDBOX_2_STEP_MFA_USERS = [
  ...SANDBOX_1_STEP_USERS,
  'user-ok-img',
  'user-ok-select',
  'user-ok-mfa-error',
  'user-ok-mfa-locked',
  'user-ok-mfa-unavailable',
  'user-ok-mfa-connection-error',
  'user-ok-mfa-logged',
  'user-ok-mfa-account-need-actions',
  'user-ok-multi-company',
  'user-ok-phone',
  'user-ok-wait-user-action',
  'user-ok-wait-user-action-timeout',
  'user-ok-wait-user-action-auth-not-granted',
]

const SANDBOX_TOKEN_MFA_VALUES = ['123456', '111111']

const SANDBOX_PASSWORD_VALUES = ['password-ok', 'password-wrong']

export const getInputSandboxCredentials = (
  selectedConnector: Connector,
  inputType: 'text' | 'password',
  inputName: string,
): string[] => {
  if (!isSandboxConnector(selectedConnector)) {
    return []
  }
  if (inputType === 'password') {
    return SANDBOX_PASSWORD_VALUES
  }
  if (inputName === 'token' || inputName === 'sms') {
    return SANDBOX_TOKEN_MFA_VALUES
  }
  if (isSandbox2StepConnector(selectedConnector)) {
    return SANDBOX_2_STEP_MFA_USERS
  }

  return SANDBOX_1_STEP_USERS
}
