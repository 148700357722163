import {
  getZoidXprops,
  isRunningAsZoidComponentInstance,
} from '../../utils/appWrapper'
import { parseConnectToken } from '../auth/utils'
import { ConnectConfig } from '../config/types'
import { TrackEventName } from './events'

const { REACT_APP_SEGMENT_API_KEY: analyticsApiKey = '' } = process.env

let currentConnectConfig: ConnectConfig | undefined = undefined

/**
 * Combine event-specified properties with the current context data
 *
 * @param properties
 */
function combineEventPropertiesWithContext(
  properties?: Record<string, unknown>,
): Record<string, unknown> | undefined {
  const combinedProperties = { ...(properties || {}) }

  // track URL from parent document, useful when loaded inside an iframe
  const {
    referrer,
    location: { ancestorOrigins },
  } = document

  if (referrer) {
    combinedProperties.referrer = referrer
  }
  if (typeof ancestorOrigins !== 'undefined') {
    combinedProperties.ancestorOrigins = ancestorOrigins
  }

  const parentDomain = isRunningAsZoidComponentInstance()
    ? getZoidXprops().getParentDomain()
    : undefined

  if (parentDomain) {
    combinedProperties.parentDomain = parentDomain
  }

  combinedProperties.firstOrigin =
    referrer ||
    (ancestorOrigins as unknown as string[] | undefined)?.[0] ||
    parentDomain ||
    'unknown'

  if (currentConnectConfig) {
    // set companyName
    if (!combinedProperties.companyName) {
      combinedProperties.companyName = currentConnectConfig.companyName
    }

    // set applicationEnvironment
    if (!combinedProperties.applicationEnvironment) {
      combinedProperties.applicationEnvironment =
        currentConnectConfig.environment
    }
  }

  try {
    const { clientId } = parseConnectToken()
    if (clientId) {
      combinedProperties.clientId = clientId
    }
  } catch {
    // invalid or missing connectToken, ignore
  }

  return combinedProperties
}

/**
 * Set analytics
 *
 * @returns - the analytics object that it's inside window
 */
function getAnalytics(): SegmentAnalytics.AnalyticsJS {
  const { analytics } = window
  if (typeof analytics === 'undefined') {
    throw new Error(
      'Analytics not enabled because `window.analytics` is not present',
    )
  }
  if (!analyticsApiKey) {
    throw new Error('Analytics not enabled due to missing API key.')
  }
  return analytics
}

/**
 * Send 'track' call to analytics.
 *
 * @param name - the name of the event to track
 * @param properties - extra pieces of information of the events to track (optional)
 * @param options - options to pass to Segment (optional)
 * @param callback - function to call when is done, only if we want to use it async (optional)
 */
export function track(
  name: TrackEventName,
  properties?: Record<string, unknown>,
  options?: Record<string, unknown>,
  callback?: () => void,
): void {
  try {
    const analytics = getAnalytics()
    if (typeof analytics.track !== 'function') {
      callback?.()
      return
    }
    analytics.track(
      name,
      combineEventPropertiesWithContext(properties),
      options,
      callback,
    )
  } catch (error) {
    // probably, analytics not enabled, or other issue sending the report
    // ignore this error - if not enabled it was already logged once
    callback?.()
  }
}

/**
 * Send 'identify' call to analytics.
 * Used to tie an user and their actions to a recognizable userId and traits
 *
 * @param userId - the id for the user to identify
 * @param traits - user-related data, such as name, email etc.
 * @param options - options to pass to Segment
 *
 */
export function identify(
  userId?: string,
  traits?: Record<string, unknown>,
  options?: SegmentAnalytics.SegmentOpts,
): void {
  let analytics: SegmentAnalytics.AnalyticsJS
  try {
    analytics = getAnalytics()
  } catch (error) {
    // probably, analytics not enabled, or other issue sending the report
    // ignore this error - if not enabled it was already logged once
    return
  }
  if (userId) {
    analytics.identify(userId, traits, options)
    return
  }
  // no userId specified, just register the traits
  analytics.identify(traits, options)
}

/**
 * Initial analytics load() call - should be called as early as possible.
 * in this case we call it in store.ts
 */
export function initializeAnalytics(): void {
  if (!analyticsApiKey) {
    console.warn('Analytics not enabled due to missing API key')
    return
  }
  let analytics: SegmentAnalytics.AnalyticsJS
  try {
    analytics = getAnalytics()
  } catch (error) {
    console.warn((error as Error).message)
    return
  }
  analytics.load(analyticsApiKey)
}

export function addConnectConfigToSegmentContext(
  connectConfig: ConnectConfig,
): void {
  currentConnectConfig = connectConfig
  const { companyName } = connectConfig
  identify(undefined, {
    companyName,
  })
}

/**
 * Send 'group' call to analytics.
 * Used to relate users that belong to a same group.
 *
 * @param {string} groupId
 * @param {Record<string, unknown>} traits
 * @param {SegmentAnalytics.SegmentOpts} options
 */
export function group(
  groupId: string,
  traits?: Record<string, unknown>,
  options?: SegmentAnalytics.SegmentOpts,
): void {
  let analytics: SegmentAnalytics.AnalyticsJS
  try {
    analytics = getAnalytics()
  } catch (error) {
    // probably, analytics not enabled, or other issue sending the report
    // ignore this error - if not enabled it was already logged once
    return
  }

  analytics.group(groupId, traits, options)
}
