import React, { useMemo } from 'react'

import { getAppProps } from '../../utils/appWrapper'
import {
  ConnectFormSkeletonLoader,
  ConnectorsListSkeletonLoader,
  WelcomePageSkeletonLoader,
} from '../Connect/SkeletonLoader'
import { Props } from './Theme.types'
import {
  getCustomAppCss,
  getCustomConnectorCss,
  getDeviceSizeFactorCss,
} from './utils'

const DarkTheme = React.lazy(() => import('./DarkTheme'))

const Theme = ({
  isLoading,
  customizationCss,
  selectedConnector,
  children,
  customization,
  customizationConfig,
}: Props): JSX.Element => {
  const customConnectorCss = useMemo(() => {
    if (!selectedConnector) {
      return ''
    }
    return getCustomConnectorCss(selectedConnector)
  }, [selectedConnector])

  const customAppCss = useMemo(() => {
    if (!customizationConfig) {
      return ''
    }
    return getCustomAppCss(customizationConfig)
  }, [customizationConfig])

  // necessary to scale components in different devices
  const deviceSizeFactorCss = getDeviceSizeFactorCss()

  const currentThemeCss = [
    customizationCss,
    customAppCss,
    customConnectorCss,
  ].join('\n')
  const { theme, selectedConnectorId } = getAppProps()

  const isDark = theme === 'dark'

  const { isWelcomeStepSkipped } = customization

  let skeletonLoader: JSX.Element = <WelcomePageSkeletonLoader />

  if (isWelcomeStepSkipped) {
    if (!selectedConnectorId) {
      skeletonLoader = <ConnectorsListSkeletonLoader />
    } else {
      skeletonLoader = <ConnectFormSkeletonLoader />
    }
  } else {
    skeletonLoader = <WelcomePageSkeletonLoader />
  }

  return (
    <>
      <React.Suspense fallback={() => null}>
        {isDark && <DarkTheme />}
      </React.Suspense>
      <style
        dangerouslySetInnerHTML={{
          __html: currentThemeCss,
        }}
      />
      <style
        dangerouslySetInnerHTML={{
          __html: deviceSizeFactorCss,
        }}
      />
      {isLoading ? skeletonLoader : children}
    </>
  )
}

export default Theme
