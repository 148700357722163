import i18n from 'i18next'
import jwtDecode from 'jwt-decode'
import {
  ErrorResponse,
  getErrorResponse,
  HttpStatusCode,
  isClientError,
  isPluggyServerError,
} from 'pluggy-js'

import { getAppProps } from '../../utils/appWrapper'
import { TrackEventName } from '../analytics/events'
import { track } from '../analytics/utils'
import { ConnectTokenPayload } from './types'

const ITEM_NOT_FOUND_MESSAGE = 'item not found'

/**
 * Helper to parse the connectToken payload.
 * @throws if no connectToken has been provided, or if the provided one is invalid
 */
export function parseConnectToken(): ConnectTokenPayload {
  const { connectToken } = getAppProps()
  if (!connectToken) {
    throw new Error("Missing connectToken prop, can't parse payload")
  }

  try {
    return jwtDecode<ConnectTokenPayload>(connectToken)
  } catch {
    throw new Error(
      'connectToken is invalid or malformed, could not be decoded',
    )
  }
}

/**
 * Helper to analyze error & return an appropriate i18n text message based on it's content
 *
 * @param error
 */
export function parseAuthErrorI18nData(error: Error): {
  i18nKey: string
  i18nText: string
  response?: ErrorResponse
} {
  let i18nText: string
  let i18nKey: string

  if (isClientError(error)) {
    i18nKey = 'auth.no_server_response'
    i18nText = i18n.t(i18nKey)
    return { i18nKey, i18nText }
  }

  if (isPluggyServerError(error)) {
    const response = getErrorResponse(error)
    const { code, message: responseMessage } = response
    if (code === HttpStatusCode.FORBIDDEN) {
      i18nKey = 'auth.unauthorized'
      i18nText = i18n.t(i18nKey)
      return { i18nKey, i18nText, response }
    }

    if (responseMessage === ITEM_NOT_FOUND_MESSAGE) {
      i18nKey = 'auth.item_not_found'
      i18nText = i18n.t(i18nKey)

      return { i18nKey, i18nText, response }
    }

    i18nKey = 'auth.unexpected_status_response'
    i18nText = i18n.t(i18nKey, {
      message: responseMessage,
      status: error.code,
    })
    return { i18nKey, i18nText, response }
  }

  // non-axios error
  i18nKey = 'auth.unexpected_error'
  i18nText = i18n.t(i18nKey, { errorMessage: error.message })
  track(TrackEventName.AUTH_ERROR, {
    i18nText,
    i18nKey,
  })
  return { i18nKey, i18nText }
}

/**
 * Simple Regexp to match an email in a text.
 * Source: https://stackoverflow.com/a/54340560/6279385
 */
const EMAIL_REGEXP = /([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/

/**
 * Find and extract an email in the specified text string.
 *
 * @param {string} text
 * @return {string | undefined}
 */
export function extractEmail(text: string): string | undefined {
  return text.match(EMAIL_REGEXP)?.[0]
}
