import { captureMessage } from '@sentry/react'
import IMask from 'imask'

import { buildBasicValidationMask, isBasicValidationRegex } from './basicMasks'
import { findPresetMask } from './presetMasks'
import { InputMaskProps } from './types'

function mapValidationRegexToImask(
  validationRegex: string,
): InputMaskProps | null {
  // 1. check if validationRegex is one of literalValidations
  const presetMask = findPresetMask(validationRegex)
  if (presetMask) {
    return presetMask
  }

  // 2. check if validationRegex is a dynamic pattern (ie. AGENCY, PASSWORD, etc.)
  if (isBasicValidationRegex(validationRegex)) {
    return buildBasicValidationMask(validationRegex)
  }

  return null
}

export type IMaskRef = IMask.InputMask<IMask.AnyMaskedOptions>

function reportMissingValidationMask(validationRegex: string) {
  // ignore masks that are irrelevant (ie. Sandbox)
  const exceptions = ['^user-.{2,50}$']
  if (exceptions.includes(validationRegex)) {
    return
  }

  captureMessage(
    `No pattern could be matched for validation regex: '${validationRegex}', mask is null`,
    {
      fingerprint: [validationRegex],
    },
  )
}

/**
 * The following validation regexes could not be mapped to any mask,
 * but we don't want to report them to Sentry anymore until we find a proper solution.
 * TODO: review & add a proper mask, or keep them to be ignored with no mask.
 *  https://pluggy.atlassian.net/browse/DVT-695
 *
 * @type {string[]}
 */
const VALIDATION_REGEXES_SKIP_SENTRY_REPORT: string[] = [
  '^\\d{3,11}-?[A-Za-z\\d]$',
  '^(?:[A-Za-z0-9+\\/]{4})*(?:[A-Za-z0-9+\\/]{2}==|[A-Za-z0-9+\\/]{3}=)?$',
  '^(\\d{3}\\.?\\d{3}\\.?\\d{3}-?\\d{2})|(\\d{1,10})$', // TODO: map this regex to a mask
]

/**
 *
 * @param validationRegex - the credential.validation regex string
 * @param options - additional options to apply onto the IMask props result
 */
export function buildIMaskPropsFromValidationRegex(
  validationRegex: string,
  options?: {
    showFormatPlaceholder: boolean
    onAccept?: (value: unknown, mask: IMaskRef) => void
  },
): InputMaskProps | null {
  const defaultOptions = { showFormatPlaceholder: false, onAccept: () => null }
  const { showFormatPlaceholder, onAccept } = options || defaultOptions
  const mask = mapValidationRegexToImask(validationRegex)

  if (!mask) {
    if (!VALIDATION_REGEXES_SKIP_SENTRY_REPORT.includes(validationRegex)) {
      // no mask & not skipped -> report to Sentry
      reportMissingValidationMask(validationRegex)
    }
    // no pattern matched, return no mask
    return null
  }

  const { imaskProps } = mask

  // apply general mask options
  if (imaskProps.hasOwnProperty('lazy')) {
    // mask supports 'lazy' -> update it to hide/show format placeholder
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(imaskProps as { lazy: boolean }).lazy = !showFormatPlaceholder
  }

  if (onAccept) {
    imaskProps.onAccept = onAccept
  }

  return mask
}
