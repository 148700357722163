import { addBreadcrumb, setTags } from '@sentry/react'
import type { Connector } from 'pluggy-js'
import { call, put, takeEvery } from 'redux-saga/effects'

import { getPluggyClient } from '../../lib/pluggyClient'
import { getAppProps } from '../../utils/appWrapper'
import { intersectDefined } from '../../utils/array'
import { getBaseCustomization } from '../../utils/customizations'
import {
  CONNECTOR_SELECT,
  ConnectorSelectAction,
  FETCH_CONNECTORS_REQUEST,
  fetchConnectorsFailure,
  FetchConnectorsRequestAction,
  fetchConnectorsSuccess,
} from './actions'

export async function fetchAppConnectors(): Promise<Connector[]> {
  const { includeSandbox, countries: propCountries } = getAppProps()
  // TODO should instead retrieve and use first the auth-api ConnectConfig to handle proper filters?
  const { connectorsFilters = {} } = getBaseCustomization()
  const { countries: customCountries } = connectorsFilters

  const countriesFilter = intersectDefined(propCountries, customCountries)

  const includeHealth = true
  const { results: connectors } = await getPluggyClient().fetchConnectors(
    {
      sandbox: includeSandbox,
      countries: countriesFilter,
    },
    includeHealth,
  )

  return connectors
}

function* handleFetchConnectorsRequest(_: FetchConnectorsRequestAction) {
  try {
    const connectors: Connector[] = yield call(fetchAppConnectors)
    yield put(fetchConnectorsSuccess(connectors))
  } catch (error) {
    yield put(fetchConnectorsFailure(error.message))
  }
}

function handleConnectorSelect(action: ConnectorSelectAction) {
  const {
    payload: { connector },
  } = action

  const {
    id: connectorId,
    name: connectorName,
    country: connectorCountry,
  } = connector || { id: null, name: null, country: null }

  const is2StepMfa = connector?.hasMFA
  const is1StepMfa = connector?.credentials.some((credential) => credential.mfa)
  const hasMfa =
    is2StepMfa && is1StepMfa
      ? '1-step & 2-step'
      : is2StepMfa
      ? '2-step'
      : is1StepMfa
      ? '1-step'
      : false

  // add Connector selected to Sentry context/tags
  addBreadcrumb({
    message: 'Selected connector',
    data: { connector },
  })

  setTags({ connectorId, connectorName, connectorCountry, hasMfa })
}

export function* connectorSaga() {
  yield takeEvery(FETCH_CONNECTORS_REQUEST, handleFetchConnectorsRequest)
  yield takeEvery(CONNECTOR_SELECT, handleConnectorSelect)
}
